
import React,{forwardRef,useEffect,useState,useRef} from 'react';
import {getContentPath,loadContentFile} from 'system/AssetManager';
import Globals from 'system/Globals'
import mstyles from '../modules.module.css'
import LoaderWheel from 'system/components/LoaderWheel'
import mapStyles from './map.module.css';
import {ReactComponent as CloseButton} from 'assets/icons/close.svg'
// import chevron from 'assets/icons/caret_down.svg'
import {ReactComponent as ChevronButton} from 'assets/icons/caret_down.svg'
// import {ReactComponent as CheckCircle} from 'assets/icons/checkCircle.svg'
import {ReactComponent as Chevron} from 'assets/icons/caret_down.svg'
import {ReactComponent as ChevronThinButton} from 'assets/icons/caret_right.svg'
import {ReactComponent as TrayClose} from 'assets/icons/tray_close.svg'
import gsap,{Power2} from 'gsap'
import axios from 'axios'
import ToolsMenu from 'system/components/ToolsMenu'

const MapModule = forwardRef((props, ref)=>{

    const [loaded,setLoaded] = useState(false);
    const [status,setStatus] = useState('initializing');
    const [files, setFiles] = useState([]);
    const [menuOpen, setMenuOpen] = useState(false);
    const [activeFileHeading, setActiveFileHeading] = useState(null);
    const [activeFile, setActiveFile] = useState(null);
    const [activeOverlayCategories, setActiveOverlayCategories] = useState([]);
    const [activeOverlays, setActiveOverlays] = useState([]);
    const [navOpen, setNavOpen] = useState(true);
    const [selectedOverlays, setSelectedOverlays] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [handleCategoryId, setHandleCategoryId] = useState(null);
    const [radioOn, setRadioOn] = useState(false);

    function convertColors(elemId) {
        var elem = document.getElementById(elemId);
        const style = getComputedStyle(elem)
        const backgroundColor = style.backgroundColor
        var newBackgroundColor = backgroundColor.replace(')', ', 0.85)').replace('rgb', 'rgba');
        elem.style.backgroundColor = newBackgroundColor;
    }

    useEffect(()=>{
        ref.current.style.display='block'
        gsap.from(ref.current,{duration:0.5,opacity:0,top:'80%', ease:Power2.easeInOut,onComplete:()=>{
        }});
        (async ()=>{
            try { 
                setStatus('Loading JSON')
                if (props.model.maps) {
                    setFiles(props.model.maps);
                }
                setStatus('Setting Map')
           
            }catch(err){
          
            }
        })();
        convertColors('rightNav');
        convertColors('rightNavMenu');

    },[])

    useEffect(()=>{ 
        setLoaded(true) 
        if (files.length > 0){
            setActiveFile(files[0].id);
            setActiveFileHeading(files[0].title)
            setActiveOverlays(files[0].overlays)
            setActiveOverlayCategories(files[0].categories)
            console.log('map files', files);
        }
        
    },[files]);

    useEffect(()=>{ 
        if (handleCategoryId) {
            function processCategory(objID) {
                activeOverlayCategories.forEach(category => {
                    var categoryOverlays = activeOverlays.filter(item0 => ((item0.category === objID)));
                    var categoryOverlaysSelected = categoryOverlays.filter(item => (selectedOverlays.includes(item.id)));
                    if (categoryOverlays.length === categoryOverlaysSelected.length) {
                       var newActiveCategories = [ ...activeOverlayCategories ];
                       const categoryIndex = newActiveCategories.map(function(x) {return x.id; }).indexOf(objID);
                       newActiveCategories[categoryIndex]['status'] = 'on';
                       setActiveOverlayCategories(newActiveCategories);

                       var newSelectedCategories = [ ...selectedCategories ];
                        const newSelectedCategoryIndex = newSelectedCategories.indexOf(objID);
                        if (newSelectedCategoryIndex < 0) {
                            newSelectedCategories.push(objID);
                            setSelectedCategories(newSelectedCategories);
                        }
                      
                    
                        // var newSelectedCategories = [ ...selectedCategories ];
                        //     const newSelectedCategoryIndex = newSelectedCategories.indexOf(objID);
                        //     if (newSelectedCategoryIndex < 0) {
                        //         newSelectedCategories.splice(objID, 1);
                        //         setSelectedCategories(newSelectedCategories);
                        //     }

                    } else {
                        var newActiveCategories = [ ...activeOverlayCategories ];
                        const categoryIndex = newActiveCategories.map(function(x) {return x.id; }).indexOf(objID);
                        if (categoryOverlaysSelected.length === 0) {
                            newActiveCategories[categoryIndex]['status'] = 'off';

                         
                            var newSelectedCategories = [ ...selectedCategories ];
                            const newSelectedCategoryIndex = newSelectedCategories.indexOf(objID);
                       
                            newSelectedCategories.splice(newSelectedCategoryIndex, 1);
                            setSelectedCategories(newSelectedCategories);
                            

                        } else {
                            newActiveCategories[categoryIndex]['status'] = 'semi-on';
                        }
                        setActiveOverlayCategories(newActiveCategories);
                        
                        
                       
                    }


                });
            }
            processCategory(handleCategoryId);
            setHandleCategoryId(null);
    
        }
        
    },[handleCategoryId]);

    function handleMenu(){
        if (menuOpen) {
            setMenuOpen(false)
        } else {
            setMenuOpen(true);
        }
    }

    
    function onClickHide() {
        if (navOpen === true) {
            setNavOpen(false)
        } else {
            setNavOpen(true);
        }
    }

    function handleFileClick(objID, heading, categories) {
        if (objID !== activeFile) {
            setMenuOpen(false);
            setTimeout(() => {
                setActiveFile(objID);
                setActiveFileHeading(heading);
                setActiveOverlayCategories(categories);
            }, 250);
        }
    }


    function processOverlay(objID) {
        if (selectedOverlays.includes(objID)) {
            var newSelectedOverlays = [ ...selectedOverlays ];
            const overlayIndex = newSelectedOverlays.indexOf(objID);
            newSelectedOverlays.splice(overlayIndex, 1);
            setSelectedOverlays(newSelectedOverlays);
            
        } else {
            if (radioOn === true) {
                var newSelectedOverlays = [];
                setRadioOn(false);
            } else {
                var newSelectedOverlays = [ ...selectedOverlays ];
            }
            
            newSelectedOverlays.push(objID)
            setSelectedOverlays(newSelectedOverlays);
        }

      
    }

    function handleInlineSVG(path, elem, duration) { 
        var svgElem = document.getElementById(elem);
        // console.log(target);
        fetch(path)
        .then(response => response.text())
        .then(svg => {
            console.log('t', svg);
            svgElem.innerHTML = svg;
            var svgObj = svgElem.getElementsByTagName('svg');
            svgObj[0].setAttribute("preserveAspectRatio", "xMidYMid slice");
            var paths = svgObj[0].querySelectorAll('path');
            console.log('ps', paths)
            // paths[0].setAttribute('style', 'animation: dash 1.5s linear forwards');
            for (var i = 0; i < paths.length; ++i) {
                paths[i].setAttribute('style', 'animation: ' + `${mapStyles.dash}` + ' ' + duration + 's linear forwards;');
            }

            
        });

    }


    function handleCheckCircleClick(objID, isRadio, categoryID, file, duration) {

        if (isRadio === true) {
            if (selectedOverlays.includes(objID)) {
                var newSelectedOverlays = [];
                setSelectedOverlays(newSelectedOverlays);
                setRadioOn(false);
            } else {
                var newSelectedOverlays = [];
                newSelectedOverlays.push(objID)
                setSelectedOverlays(newSelectedOverlays);
                setRadioOn(true);
            }

            var newActiveCategories = [ ...activeOverlayCategories ];
            const categoryIndex = newActiveCategories.forEach((item, i) => {
                newActiveCategories[i]['status'] = 'off';
                if (i === (newActiveCategories - 1)) {
                    setActiveOverlayCategories(newActiveCategories);
                }
            })
            
            setSelectedCategories([]);

        } else {
            processOverlay(objID);
        }
        setHandleCategoryId(categoryID);
        
        if (file) {
            handleInlineSVG(getContentPath(file), "overlay_" + objID, duration)
        }
    }
    

    function handleCategoryClick(objID) {
        if (selectedCategories.includes(objID)) {
            
            var newSelectedCategories = [ ...selectedCategories ];
            const categoryIndex = newSelectedCategories.indexOf(objID);
            newSelectedCategories.splice(categoryIndex, 1);
            setSelectedCategories(newSelectedCategories);

            var newSelectedOverlays = [ ...selectedOverlays ];
            
            var categoryOverlays = activeOverlays.filter(item => ((item.category === objID)));
            categoryOverlays.forEach((item, j) => {
                const overlayIndex = newSelectedOverlays.indexOf(item.id);
                newSelectedOverlays.splice(overlayIndex, 1);
                if (j === (categoryOverlays.length - 1)) {
                    setSelectedOverlays(newSelectedOverlays);
                }
            });

            var newActiveCategories = [ ...activeOverlayCategories ];
            const activeCategoryIndex = newActiveCategories.map(function(x) {return x.id; }).indexOf(objID);
            newActiveCategories[activeCategoryIndex]['status'] = 'off';
            setActiveOverlayCategories(newActiveCategories);

        } else {
            if (radioOn === true) {
                var newSelectedCategories = [];
                var newSelectedOverlays = [];
                setRadioOn(false);
           
            } else {
                var newSelectedOverlays = [ ...selectedOverlays ];
                var newSelectedCategories = [ ...selectedCategories ];
            }
            
            newSelectedCategories.push(objID)
            setSelectedCategories(newSelectedCategories);

            var categoryOverlays = activeOverlays.filter(item => ((item.category === objID)&&(!selectedOverlays.includes(item.id))));
            

            categoryOverlays.forEach((item, j) => {
                newSelectedOverlays.push(item.id)
                if (j === (categoryOverlays.length - 1)) {
                    setSelectedOverlays(newSelectedOverlays);
                }
            });
        }
    }

 
    function onClickClose(evt){props.closeModule()}

    return (
        <div className={`fullscreen fcenter ${mstyles.moduleHolder}` } ref={ref}>
            {(!loaded)?(<div className={`fullscreen fcenter`}>
            <div>
                <LoaderWheel status={status}></LoaderWheel>
            </div>
             </div>):null}

             {/* Map Frame */}

             <div className={mapStyles.frame}>

           
             {files
                .filter((file) => file.id === activeFile)
                .map((file, i) => (
                <div 
                    key={'map_basemap_' + i} 
                    className={`${mapStyles.fullFile} ${(activeFile === file.id)?(mapStyles.fileOn):("")}`}
                >
                <img src={getContentPath(file.base_map)} className={`${mapStyles.baseMap}`} alt="" /> 

                </div>
                ))}

                {activeOverlays
                    // .filter((file) => file.id === activeOverlay)
                    .map((file, i) => (
                    <div 
                        key={'map_overlay_' + i} 
                        id={"overlay_" + file.id}
                        className={`${mapStyles.fullFile} ${(selectedOverlays.includes(file.id))?(mapStyles.fileOn):("")} ${(file.duration > 0)?(mapStyles.svgOverlay):("")}`}
                    >
                    {(file.duration > 0) ? (
                    
                       <React.Fragment></React.Fragment>
                    ) : (
                        <img src={getContentPath(file.file)} className={`${mapStyles.overlay}`} alt="" /> 
                    )}
                    
    
                    </div>
                    ))}
                   
            
                    
            </div>
            
            {/* Map Nav */}

             <div id="rightNav" className={`${mapStyles.rightNavMenu} ${(menuOpen)?(mapStyles.menuOn):(null) } ${(navOpen)?(mapStyles.navOpen):(null) }`} style={Globals.instance().getThemeStyle(['module-drawer-bg'],props.model.theme)}>
                <nav id="rightNavMenu" className={mapStyles.rightNavMenuNav}>
                    <div 
                        className={`${mapStyles.dropdownHeading}`}
                        style={(menuOpen)?(Globals.instance().getThemeStyle(['module-heading-bg-on'],props.model.theme)):(Globals.instance().getThemeStyle(['module-heading-bg'],props.model.theme))}
                        onClick={() => handleMenu()}
                        >
                        <span
                        style={Globals.instance().getThemeStyle(['module-heading'],props.model.theme)}
                        >
                        <Chevron id={mapStyles.dropDownChevron}>
                        </Chevron>
                        
                        {activeFileHeading}
                        </span>
                        </div>
                    <div className={`${mapStyles.dropdownChoices}`} style={Globals.instance().getThemeStyle(['module-text'],props.model.theme)}>
                    {files.map((file, i) => (
                        <div 
                            className={`${mapStyles.dropdownChoice} ${(activeFile === file.id)?(mapStyles.choiceOn):("")}`}
                            style={Globals.instance().getThemeStyle(['module-dropdown'],props.model.theme)}
                            key={'map_file_' + i}
                            onClick={() => handleFileClick(file.id, file.name, file.categories)}
                        >
                        {file.title}
                
                        </div>
                    ))}

                    </div>
                </nav>

                <div className={`${mapStyles.buttons}`} style={Globals.instance().getThemeStyle(['module-text'],props.model.theme)}>
                {activeOverlayCategories
                    .filter((category) => category.radio_overlays === false)
                    .map((category, i) => (
                    <div 
                        key={'map_category_' + i} 
                        className={`${mapStyles.overlayCategory}`}
                    >
                    
                    <div 
                    onClick={() => handleCategoryClick(category.id)}
                    style={Globals.instance().getThemeStyle(['module-map-check'],props.model.theme)}
                    className={`${mapStyles.categoryLabel} ${(category.status === 'semi-on')?(mapStyles.choiceSemiOn):("")} ${(category.status === 'on')?(mapStyles.choiceOn):("")} ${(selectedCategories.includes(category.id))?(mapStyles.choiceOn):("")}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                        <g transform="translate(0 -144)">
                            <circle className={`${mapStyles.outerCircle}`} cx="7.5" cy="7.5" r="7.5" transform="translate(8.5 152.5)" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1"/>
                            <circle className={`${mapStyles.innerCircle}`} cx="3.5" cy="3.5" r="3.5" transform="translate(12.5 156.5)" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1"/>
                        </g>
                    </svg>
                    {category.name}
                    </div>

                    {activeOverlays
                        .filter((overlay) => overlay.category === category.id)
                        // .sort((a, b) => a.ordering - b.ordering)
                        .map((overlay, i) => (
                        <div 
                            onClick={() => handleCheckCircleClick(overlay.id, false, category.id, (overlay.duration > 0)?(overlay.file):(null), overlay.duration)
                            }
                            key={'map_button_' + i} 
                            className={`${mapStyles.checkCircle} ${(selectedOverlays.includes(overlay.id))?(mapStyles.choiceOn):("")}`}
                        >
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                        <g transform="translate(0 -144)">
                            <circle className={`${mapStyles.outerCircle}`} cx="7.5" cy="7.5" r="7.5" transform="translate(8.5 152.5)" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1"/>
                            <circle className={`${mapStyles.innerCircle}`} cx="3.5" cy="3.5" r="3.5" transform="translate(12.5 156.5)" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1"/>
                        </g>
                        </svg>
                            
                        {overlay.name}
                        </div>
                    ))}
            
                    </div>
                ))}

                <div className={`${mapStyles.radioButtons}`}>
                {activeOverlayCategories
                    .filter((category) => category.radio_overlays === true)
                    .map((category, i) => (
                    <div 
                        key={'map_category_' + i} 
                        className={`${mapStyles.overlayCategory}`}
                    >   
                    <div 
                    // onClick={() => handleThumbnailClick(file.id, file.fileType, file.file)}
                    className={`${mapStyles.categoryLabel}`}>
                    <ChevronButton></ChevronButton>
                    {category.name}
                    </div>

                    {activeOverlays
                        .filter((overlay) => overlay.category === category.id)
                        // .sort((a, b) => a.ordering - b.ordering)
                        .map((overlay, i) => (
                        <div 
                            onClick={() => handleCheckCircleClick(overlay.id, true, category.id, (overlay.duration > 0)?(overlay.file):(null), overlay.duration)}
                            key={'map_button_' + i} 
                            className={`${mapStyles.checkCircle} ${(selectedOverlays.includes(overlay.id))?(mapStyles.choiceOn):("")}`}
                        >
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                        <g transform="translate(0 -144)">
                            <circle className={`${mapStyles.outerCircle}`} cx="7.5" cy="7.5" r="7.5" transform="translate(8.5 152.5)" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1"/>
                            <circle className={`${mapStyles.innerCircle}`} cx="3.5" cy="3.5" r="3.5" transform="translate(12.5 156.5)" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1"/>
                        </g>
                        </svg>
                            
                        {overlay.name}
                        </div>
                    ))}
            
                    </div>
                ))}
                </div>
                
                </div>
                
            </div>

            <div className={`${mapStyles.rightNavBtn2} ${(navOpen)?(mapStyles.navOpen):(null)}`}>
                <div className={`round-btn`} onClick={() => onClickHide()} style={Globals.instance().getThemeStyle(['nav-menu-btn'],props.theme)}>
                    <TrayClose></TrayClose>
                </div>
            </div>

            <div className={`${mstyles.closeBtnContainer} `}>
            <ToolsMenu theme={props.theme} 
            setShowDrawControls={props.setShowDrawControls}  setShowMarkup={props.setShowMarkup}
                        model={props.model}></ToolsMenu>
            
                <div className={`round-btn`} onClick={onClickClose} style={Globals.instance().getThemeStyle(['nav-menu-btn'],props.theme)}>
                    <CloseButton></CloseButton>
                </div>
            </div>

        </div>
    )

})

export default MapModule


// HTTPS=true SSL_CRT_FILE=./.cert/cert.pem SSL_KEY_FILE=./.cert/key.pem 