

import * as THREE from 'three'
import CamManager from './CamManager'
import gsap from 'gsap'

export default class HoustonCammanager extends CamManager {


    constructor(parent){
        console.log('Leaden CamManager')
        super(parent)
    }


    update(){
        super.update()

        // var theta = this.p.controls.getAzimuthalAngle()
		var arrSort = []

		// check if we need to fade any objects
		if (this.currentView && this.currentView.interactiveMeshes) {
		  Object.keys(this.currentView.interactiveMeshes).forEach((key) => {
		  // for (const index in this.currentView.fadeMeshes) {
			const obj = this.currentView.interactiveMeshes[key];
			obj.mesh.visible=false
			// console.log(o	bj.mesh)
			// if(obj.mesh.material.opacity>0 && obj.mesh.material.opacity<1){
			//   var camPosition = this.p.camera.position.clone()
			//   obj.distance = (camPosition.sub(obj.mesh.localToWorld(obj.mesh.centroidPnt.clone()))).length()
			//   arrSort.push(obj)
			// }
	
			
	
			//   if (typeof obj.minTheta !== 'undefined' && typeof obj.maxTheta !== 'undefined') {
			// 	if (this.isHiddenInRange(theta, obj.minTheta, obj.maxTheta)) {
			// 	  if (obj.mesh.material && obj.mesh.material.opacity > 0 && (typeof obj.fadeable === 'undefined' || obj.fadeable === true)) {
			// 		this.setAnimationOnObject(obj.mesh, 'fadeOut');
			// 	  }
			// 	} else if (obj.mesh.visible === false || (obj.mesh.material && obj.mesh.material.opacity < 1)) {
			// 	  this.setAnimationOnObject(obj.mesh, 'fadeIn');
			// 	}
			// }
		  });
		
		}
		arrSort.sort((a, b)=>{
			if (a.distance < b.distance) { return -1 }
			if (a.distance > b.distance) { return 1 }
			return 0
		})
		arrSort.forEach(function(mesh, index){
			// console.log(mesh)
			mesh.renderOrder = 3 + index
		})
	}


	updateCameraAnimation(){
		if (this.cameraAnimation.animating) {
			this.removeBoundsOnControls() // turn ooff bounds
			// complete then finish up
			if (this.cameraAnimation.percent > 1) {
			 this.onCompleteAnimView()
			}
			// complete then finish up
			if (this.cameraAnimation.percent > 1) {
			  this.cameraAnimation.percent = 1
			  this.cameraAnimation.animating = false
			  this.p.controls.enabled = true
			  this.animateFrame = false
			  this.setBoundsOnControls()
			  if(this.currentView.auto_rotate===true)
          		this.p.controls.autoRotate=true
			}
	  
			// sine animation
			const per = Math.sin(this.cameraAnimation.percent * Math.PI / 2)
	        this.cameraAnimation.percent = ((new Date()).getTime() - this.cameraAnimation.timeStart) / (this.cameraAnimation.timeEnd - this.cameraAnimation.timeStart)
	  
			const tar = this.cameraAnimation.targetEnd.clone()
			tar.sub(this.cameraAnimation.targetStart)
			tar.multiplyScalar(per)
			tar.add(this.cameraAnimation.targetStart)
			this.p.controls.target.set(tar.x, tar.y, tar.z)
	  
			// calc distance
			const d = this.cameraAnimation.d1 + (per * (this.cameraAnimation.d2 - this.cameraAnimation.d1))
	  
			// angleEnd.normalize()
			let angleDiff = this.cameraAnimation.angleEnd.clone().sub(this.cameraAnimation.angleStart)
			angleDiff.multiplyScalar(per)
			angleDiff.add(this.cameraAnimation.angleStart)
			angleDiff.add(this.cameraAnimation.yNudge.clone().multiplyScalar(Math.sin(per * Math.PI)))
			angleDiff.normalize()
	  
			// console.log('d :' + d, distanceDest)
	  
			let pos = angleDiff.multiplyScalar(d).add(tar)
	  
			this.p.camera.position.set(pos.x, pos.y, pos.z)
			this.p.controls.update()
		  }
	}
	
	animateView(obj){
		
		super.animateView(obj)
		console.log(obj)
		
		if(obj.avail){
			this.p.selectFloor(obj["building-id"],obj.avail.floor_name.replace(' ','-'))
		}

		if (this.p) {
			// var title = obj.title.toLowerCase()
			// console.log("LANDMRKS "+this.p.landmarkBuildings.length)
			this.p.landmarkBuildings.forEach(function(mesh) {
        
				var c = new THREE.Color()
				c.copy(mesh.oColor)

				let colorOffests = []
				// console.log(obj)
				if(obj.name === "Area Attractions")
					colorOffests = [
					"Bldg-landmk02",
					"Bldg-landmk03",
					"Bldg-landmk04",
					"Bldg-trans19-landmk09"
				  ]
				else if(obj.name==="Residential")
					colorOffests=[
						"Bldg-Res08",
						"Bldg-Res10",
						"Bldg-Res23",
						"Bldg-Res25",
						"Bldg-Res04",
						"Bldg-Res05",
						"Bldg-Res09",
						"Bldg-Res15",
						"Bldg-Res13",
						"Bldg-Res14",
						"Bldg-Res16",
						"Bldg-Res21",
						"Bldg-Res22",
						"Bldg-Res24",
						"Bldg-Res26",
						"Bldg-Res27"
					  ]
				else if(obj.name==="Hotels")
					colorOffests=[
						"Bldg-landmk05",
						"Bldg-transp07-landmk07",
						"Bldg-landmk16",
						"Bldg-landmk01",
						"Bldg-transp19-landmk08"
					  ]
				
				if (colorOffests.length && colorOffests.includes(mesh.name)) {
					c.copy(mesh.highColor)
				}

				var animObj = {
					percent: 0,
					fromR: mesh.material.color.r,
					fromG: mesh.material.color.g,
					fromB: mesh.material.color.b,
					toR: c.r,
					toG: c.g,
					toB: c.b,
					m: mesh,
				}

				gsap.to(animObj,  {
					duration:1,
					percent: 1,
					onUpdate:function(oJ) {
						oJ.m.material.color = new THREE.Color(
							oJ.fromR + (oJ.toR - oJ.fromR) * oJ.percent,
							oJ.fromG + (oJ.toG - oJ.fromG) * oJ.percent,
							oJ.fromB + (oJ.toB - oJ.fromB) * oJ.percent
						)
					},
					onUpdateParams: [animObj],

				})
			})
		
			if(this.currentBldgGrp && this.currentView.category===this.currentBldgGrp.view){
				return
			}
			// console.log(this.currentView.category)
			let found =false
			this.p.HOUSTONBLDGAVAIL.forEach( (grp)=>{
				var bldg = this.p.scene.getObjectByName(grp.mesh)
				
				if (bldg) {
					if (grp.view === this.currentView.category ) {
						this.currentBldgGrp=grp
						this.currentBldgGrpMesh = bldg
			  
						bldg.visible = true
						bldg.children.forEach( floor => {
							// console.log(floor);
							floor.visible = false
							floor.material.transparent = true
							floor.material.opacity = 0.0
							// console.log(floor.name)
						})
			  
						
						Object.values(grp.avail).forEach( avail=>{
							if(avail.floor_name){
								let floor = avail.floor_name.replace(' ','-')
								let floorObj = bldg.getObjectByName(grp['building-id']+'-'+floor)
								if(floorObj){
									floorObj.visible=true
									gsap.to(floorObj.material,{duration:0.25,opacity:0.5})
								}
							}
						})
						found=true
						
					} else {
						bldg.children.forEach((floor, i)=>{
							if (i === 0) gsap.to(floor.material, { duration:0.25, opacity: 0, onComplete: function(gm){ gm.visible = false }, onCompleteParams: [bldg] })
							else { gsap.to(floor.material,  {duration:0.25, opacity: 0 }) }
						})
					}
				}else{
					console.warn('no building found '+grp.mesh)
				}
			})
			if(!found)this.currentBldgGrp = undefined //reset current building 
		}
		

	}

	animateTo (pos, targetend) {
		const distance = this.p.camera.position.distanceTo(pos)
	
		this.p.controls.enabled = false
		this.cameraAnimation.animating = true
		this.cameraAnimation.startpos = this.p.camera.position.clone()
		this.cameraAnimation.endpos = pos.clone()
		this.cameraAnimation.targetStart = this.p.controls.target.clone()
		this.cameraAnimation.targetEnd = targetend.clone()
	
		this.cameraAnimation.d1 = this.p.camera.position.distanceTo(this.p.controls.target)
	
		this.cameraAnimation.timeStart = (new Date()).getTime()
		this.cameraAnimation.angleStart = this.cameraAnimation.startpos.clone().sub(this.cameraAnimation.targetStart).normalize()
		let angleEnd = this.cameraAnimation.endpos.clone().sub(this.cameraAnimation.targetEnd)
		this.cameraAnimation.d2 = angleEnd.length()
		this.cameraAnimation.angleEnd = angleEnd.normalize()
	
		const angle = angleEnd.angleTo(this.cameraAnimation.angleStart)// Math.acos((distanceCam*distanceCam+distanceDest*distanceDest-distance*distanceDest)/(2*distanceCam*distanceDest));
		let angleDiff = this.cameraAnimation.angleEnd.clone().sub(this.cameraAnimation.angleStart).normalize()
	
		var quaternion = new THREE.Quaternion() // create one and reuse it
		quaternion.setFromUnitVectors(this.cameraAnimation.angleStart, this.cameraAnimation.angleEnd)
		this.cameraAnimation.yNudge = angleDiff.applyAxisAngle(new THREE.Vector3(0, 1, 0), Math.PI / 2).multiplyScalar(angle / Math.PI * -0.5 * Math.sign(quaternion.y))
	
		// console.log(angle, quaternion)
		this.cameraAnimation.timeEnd = this.cameraAnimation.timeStart + 700 + (angle * 500) + distance * 600
		if (this.cameraAnimation.timeEnd > this.cameraAnimation.timeStart + 10000) { this.cameraAnimation.timeEnd = this.cameraAnimation.timeStart + 10000 }
		this.cameraAnimation.percent = 0
		this.cameraAnimation.angleVel = 0
	  }


      
}





