
import * as THREE from 'three'
import { FresnelShader } from './FresnelShader';
 function getAlphaFresnel (cubeTarget,alphaMap,
	blendAlpha,mRefractionRatio,mRefractionOffset,mFresnelPower,mFresnelBias,mFresnelScale) {

		let uniforms = THREE.UniformsUtils.clone(FresnelShader.uniforms);
        
			uniforms['u_texture']={
				type: "t",
				value: alphaMap
			}
			
			uniforms['mRefractionOffset']={
				value: mRefractionOffset
			}
			uniforms['blendAlpha']={
				value: blendAlpha
			}
			
			uniforms.mFresnelPower.value=mFresnelPower
			uniforms.mFresnelBias.value=mFresnelBias
			uniforms.mFresnelScale.value=mFresnelScale
			uniforms.mRefractionRatio.value=mRefractionRatio
			uniforms.tCube.value = cubeTarget.texture//this.reflectionCube;
	
		
		let vertexShader=/* glsl */`
		uniform float mRefractionRatio;
		uniform float mRefractionOffset;
		uniform float mFresnelBias;
		uniform float mFresnelScale;
		uniform float mFresnelPower;

		varying vec3 vReflect;
		varying vec3 vRefract[3];
		varying float vReflectionFactor;
		varying vec2 vUv;
		
		void main() {
			vUv=uv;
			vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
			vec4 worldPosition = modelMatrix * vec4( position, 1.0 );
			vec3 worldNormal = normalize( mat3( modelMatrix[0].xyz, modelMatrix[1].xyz, modelMatrix[2].xyz ) * normal );
			vec3 I = worldPosition.xyz - cameraPosition;
			vReflect = reflect( I, worldNormal );
			vRefract[0] = refract( normalize( I ), worldNormal, mRefractionRatio );
			vRefract[1] = refract( normalize( I ), worldNormal, mRefractionRatio * (1.0 - mRefractionOffset));
			vRefract[2] = refract( normalize( I ), worldNormal, mRefractionRatio * (1.0 - (2.0 * mRefractionOffset)));
			vReflectionFactor = mFresnelBias + mFresnelScale * pow( 1.0 + dot( normalize( I ), worldNormal ), mFresnelPower );
			gl_Position = projectionMatrix * mvPosition;

		}`
	
	 
	const fragmentShader = 
	`uniform samplerCube tCube;
	uniform sampler2D u_texture;
	varying vec3 vReflect;
	varying vec3 vRefract[3];
	varying float vReflectionFactor;
	varying vec2 vUv;
	uniform float blendAlpha;

	void main() {
		vec4 colorAlpha = texture2D( u_texture, vUv);
		float alpha = colorAlpha.r * blendAlpha ;	
		
		vec4 reflectedColor = textureCube( tCube, vec3( vReflect.x, vReflect.yz ) );
		reflectedColor.r = ( reflectedColor.r * 1.0 ) + 0.0;
		reflectedColor.g = ( reflectedColor.g * 1.0 ) + 0.0;
		reflectedColor.b = ( reflectedColor.b * 1.0 ) + 0.0;
		reflectedColor.a = alpha * blendAlpha;
		vec4 refractedColor = vec4( 1.0 , 1.0 , 1.0 ,  ( alpha * blendAlpha ));
		refractedColor.r = 0.0 + textureCube( tCube, vec3( vRefract[0].x, vRefract[0].yz ) ).r * 1.0;
		refractedColor.g = 0.0 + textureCube( tCube, vec3( vRefract[1].x, vRefract[1].yz ) ).g * 1.0;
		refractedColor.b = 0.0 + textureCube( tCube, vec3( vRefract[2].x, vRefract[2].yz ) ).b * 1.0;
		gl_FragColor = mix( refractedColor, reflectedColor, clamp( vReflectionFactor, 0.0, 1.0 ) );

	}`
	  //gl_FragColor = mix( refractedColor, reflectedColor, clamp( vReflectionFactor, 0.0, 1.0 ) );
		// gl_FragColor = texture2D(u_texture, vUv.xy );
    var   material = new THREE.ShaderMaterial( {
		uniforms,
        vertexShader:vertexShader,
        fragmentShader:fragmentShader
    });
	material.uniformsNeedUpdate=true
	// console.log(material)
    return material
 }
    



 function getAlphaFresnel2 (cubeTarget,alphaMap,
	blendAlpha,mRefractionRatio,mRefractionOffset,mFresnelPower,mFresnelBias,mFresnelScale) {

		let uniforms = THREE.UniformsUtils.clone(FresnelShader.uniforms);
        
			uniforms['u_texture']={
				type: "t",
				value: alphaMap
			}
			
			uniforms['mRefractionOffset']={
				value: mRefractionOffset
			}
			uniforms['blendAlpha']={
				value: blendAlpha
			}
			
			uniforms.mFresnelPower.value=mFresnelPower
			uniforms.mFresnelBias.value=mFresnelBias
			uniforms.mFresnelScale.value=mFresnelScale
			uniforms.mRefractionRatio.value=mRefractionRatio
			uniforms.tCube.value = cubeTarget.texture//this.reflectionCube;
	
		
		let vertexShader=/* glsl */`
		uniform float mRefractionRatio;
		uniform float mRefractionOffset;
		uniform float mFresnelBias;
		uniform float mFresnelScale;
		uniform float mFresnelPower;

		varying vec3 vReflect;
		varying vec3 vRefract[3];
		varying float vReflectionFactor;
		varying vec2 vUv;
		
		void main() {
			vUv=uv;
			vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
			vec4 worldPosition = modelMatrix * vec4( position, 1.0 );
			vec3 worldNormal = normalize( mat3( modelMatrix[0].xyz, modelMatrix[1].xyz, modelMatrix[2].xyz ) * normal );
			vec3 I = worldPosition.xyz - cameraPosition;
			vReflect = reflect( I, worldNormal );
			vRefract[0] = refract( normalize( I ), worldNormal, mRefractionRatio );
			vRefract[1] = refract( normalize( I ), worldNormal, mRefractionRatio * (1.0 - mRefractionOffset));
			vRefract[2] = refract( normalize( I ), worldNormal, mRefractionRatio * (1.0 - (2.0 * mRefractionOffset)));
			vReflectionFactor = mFresnelBias + mFresnelScale * pow( 1.0 + dot( normalize( I ), worldNormal ), mFresnelPower );
			gl_Position = projectionMatrix * mvPosition;

		}`
	
	 
	const fragmentShader = 
	`uniform samplerCube tCube;
	uniform sampler2D u_texture;
	varying vec3 vReflect;
	varying vec3 vRefract[3];
	varying float vReflectionFactor;
	varying vec2 vUv;
	uniform float blendAlpha;

	void main() {
		
		vec4 colorAlpha = texture2D( u_texture, vUv);
		float alpha = colorAlpha.r * blendAlpha * 2.0;	
		vec4 colorTint = vec4(131.0/255.0, 153.0/255.0, 171.0/255.0, alpha); 
		
		vec4 reflectedColor = textureCube( tCube, vec3( vReflect.x, vReflect.yz ) );
		reflectedColor.r = ( reflectedColor.r * 1.0 ) + 0.0;
		reflectedColor.g = ( reflectedColor.g * 1.0 ) + 0.0;
		reflectedColor.b = ( reflectedColor.b * 1.0 ) + 0.0;
		reflectedColor.a = alpha * blendAlpha;
		vec4 refractedColor = vec4( 1.0 , 1.0 , 1.0 ,  ( alpha * blendAlpha ));
		refractedColor.r = 0.0 + textureCube( tCube, vec3( vRefract[0].x, vRefract[0].yz ) ).r * 1.0;
		refractedColor.g = 0.0 + textureCube( tCube, vec3( vRefract[1].x, vRefract[1].yz ) ).g * 1.0;
		refractedColor.b = 0.0 + textureCube( tCube, vec3( vRefract[2].x, vRefract[2].yz ) ).b * 1.0;
		gl_FragColor = mix ( mix( refractedColor, reflectedColor, clamp( vReflectionFactor, 0.0, 1.0 ) ) , colorTint , 0.5 );

	}`
	  //gl_FragColor = mix( refractedColor, reflectedColor, clamp( vReflectionFactor, 0.0, 1.0 ) );
		// gl_FragColor = texture2D(u_texture, vUv.xy );
    var   material = new THREE.ShaderMaterial( {
		uniforms,
        vertexShader:vertexShader,
        fragmentShader:fragmentShader
    });
	material.uniformsNeedUpdate=true
	// console.log(material)
    return material
 }


 function getAlphaFresnel3 (cubeTarget,alphaMap,
	blendAlpha,mRefractionRatio,mRefractionOffset,mFresnelPower,mFresnelBias,mFresnelScale) {

		let uniforms = THREE.UniformsUtils.clone(FresnelShader.uniforms);
        
			uniforms['u_texture']={
				type: "t",
				value: alphaMap
			}
			
			uniforms['mRefractionOffset']={
				value: mRefractionOffset
			}
			uniforms['blendAlpha']={
				value: blendAlpha
			}
			
			uniforms.mFresnelPower.value=mFresnelPower
			uniforms.mFresnelBias.value=mFresnelBias
			uniforms.mFresnelScale.value=mFresnelScale
			uniforms.mRefractionRatio.value=mRefractionRatio
			uniforms.tCube.value = cubeTarget.texture//this.reflectionCube;
	
		
		let vertexShader=/* glsl */`
		uniform float mRefractionRatio;
		uniform float mRefractionOffset;
		uniform float mFresnelBias;
		uniform float mFresnelScale;
		uniform float mFresnelPower;

		varying vec3 vReflect;
		varying vec3 vRefract[3];
		varying float vReflectionFactor;
		varying vec2 vUv;
		
		void main() {
			vUv=uv;
			vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
			vec4 worldPosition = modelMatrix * vec4( position, 1.0 );
			vec3 worldNormal = normalize( mat3( modelMatrix[0].xyz, modelMatrix[1].xyz, modelMatrix[2].xyz ) * normal );
			vec3 I = worldPosition.xyz - cameraPosition;
			vReflect = reflect( I, worldNormal );
			vRefract[0] = refract( normalize( I ), worldNormal, mRefractionRatio );
			vRefract[1] = refract( normalize( I ), worldNormal, mRefractionRatio * (1.0 - mRefractionOffset));
			vRefract[2] = refract( normalize( I ), worldNormal, mRefractionRatio * (1.0 - (2.0 * mRefractionOffset)));
			vReflectionFactor = mFresnelBias + mFresnelScale * pow( 1.0 + dot( normalize( I ), worldNormal ), mFresnelPower );
			gl_Position = projectionMatrix * mvPosition;

		}`
	
	 
	const fragmentShader = 
	`uniform samplerCube tCube;
	uniform sampler2D u_texture;
	varying vec3 vReflect;
	varying vec3 vRefract[3];
	varying float vReflectionFactor;
	varying vec2 vUv;
	uniform float blendAlpha;

	void main() {
		
		vec4 colorAlpha = texture2D( u_texture, vUv);
		float alpha = colorAlpha.r * blendAlpha * 2.0;	
		vec4 colorTint = vec4(0.2, 0.2, 0.2, alpha); 
		
		vec4 reflectedColor = textureCube( tCube, vec3( vReflect.x, vReflect.yz ) );
		reflectedColor.r = ( reflectedColor.r * 1.0 ) + 0.0;
		reflectedColor.g = ( reflectedColor.g * 1.0 ) + 0.0;
		reflectedColor.b = ( reflectedColor.b * 1.0 ) + 0.0;
		reflectedColor.a = alpha * blendAlpha;
		vec4 refractedColor = vec4( 1.0 , 1.0 , 1.0 ,  ( alpha * blendAlpha ));
		refractedColor.r = 0.0 + textureCube( tCube, vec3( vRefract[0].x, vRefract[0].yz ) ).r * 1.0;
		refractedColor.g = 0.0 + textureCube( tCube, vec3( vRefract[1].x, vRefract[1].yz ) ).g * 1.0;
		refractedColor.b = 0.0 + textureCube( tCube, vec3( vRefract[2].x, vRefract[2].yz ) ).b * 1.0;
		gl_FragColor = mix ( mix( refractedColor, reflectedColor, clamp( vReflectionFactor, 0.0, 1.0 ) ) , colorTint , 0.5 );

	}`
	  //gl_FragColor = mix( refractedColor, reflectedColor, clamp( vReflectionFactor, 0.0, 1.0 ) );
		// gl_FragColor = texture2D(u_texture, vUv.xy );
    var   material = new THREE.ShaderMaterial( {
		uniforms,
        vertexShader:vertexShader,
        fragmentShader:fragmentShader
    });
	material.uniformsNeedUpdate=true
	// console.log(material)
    return material
 }
    
export {getAlphaFresnel,getAlphaFresnel2,getAlphaFresnel3} 