import React,{forwardRef,useEffect,useState,useRef} from 'react';
import {getContentPath} from 'system/AssetManager';
import Globals from 'system/Globals';
import mstyles from '../modules.module.css';
import LoaderWheel from 'system/components/LoaderWheel';
import {ReactComponent as CloseButton} from 'assets/icons/close.svg'
import {ReactComponent as ChevronButton} from 'assets/icons/caret_down.svg'
import {ReactComponent as IconHamburger} from 'assets/icons/burger.svg';
import mapStyles from './mapbox.module.css';
import './mapbox.css'
import ToolsMenu from 'system/components/ToolsMenu'
import gsap,{Power2} from 'gsap'

// eslint-disable-next-line import/no-webpack-loader-syntax

const MapBoxMenu = forwardRef((props, ref)=>{

    // const [menuOpen, setMenuOpen] = useState(true);
    // const [navOpen, setNavOpen] = useState(true);
    const [radioOn, setRadioOn] = useState(false);
    const [showButtonRule, setShowButtonRule] = useState(true);
    const refMapLayerButtons = useRef();
    const refMenuOpen = useRef();

    useEffect(() => {
        console.log("Map Menu", props)
    }, [])

    useEffect(()=>{ 
        if ((props.handleCategoryId)&&(props.activeOverlayCategories.length > 0)) {
            console.log("props.handleCategoryId", props.handleCategoryId, props.activeOverlayCategories.length)
            processCategory(props.handleCategoryId);
            props.setHandleCategoryId(null);
            props.setParamsProcessed(true);
           
        }
        
    },[props.handleCategoryId]);

    useEffect(() => {
        
        if (props.selectedCategories && (props.selectedCategories.length > 0)) {
            console.log('props.selectedCategories', props.selectedCategories)
            let objArray = [];
            for (let i = 0; i < props.selectedCategories.length; i++) {
                if (!objArray.includes(parseInt(props.selectedCategories[i]))) {
                    processCategory(parseInt(props.selectedCategories[i]))
                    objArray.push(parseInt(props.selectedCategories[i]))
                    console.log('props.selectedCategories to be processed', parseInt(props.selectedCategories[i]))
                }
                
            }
        }
    }, [props.selectedCategories])

    useEffect(() => {
        if (props.radioObj) {
            console.log('props.radioObj', props.radioObj)
            if (props.radioObj['format'] === 'pngGroup') {
                var semiOns = document.getElementsByClassName(`${mapStyles.choiceSemiOn}`);
                var semiOnArray = [].slice.call(semiOns);
                for (let i = 0; i < semiOnArray.length; i++) {
                    semiOnArray[i].classList.remove(`${mapStyles.choiceSemiOn}`);
                }
            }
           
         }
     }, [props.radioObj])

     useEffect(() => {
        if (props.menuOpen === true) {
                gsap.to(refMapLayerButtons.current, {opacity: 0, duration: 0.3})
                refMenuOpen.current = true;
            } else {
                gsap.to(refMapLayerButtons.current, {opacity: 1, duration: 0.3})
                refMenuOpen.current = false;
        }
     }, [props.menuOpen])
     

    useEffect(() => {
        if (props.pngGroups && props.activeOverlayCategories) {
            let state1 = props.pngGroups.filter((overlay) => (overlay.radio_btn === false)).length === 0;
            let state2 = props.activeOverlayCategories.filter((category) => ((category.radio_overlays === false) &&(category.interactive === true))).length === 0;
            if (state1 && state2) {
                setShowButtonRule(false);
            } else {
                setShowButtonRule(true);
            }
            // console.log('state1', state1, state2)
        }
    }, [props.pngGroups, props.activeOverlayCategories])


    function updateParamUrl(name, objIDs){
        if (props.history) {
            let params = new URLSearchParams(props.history.location.search);
            params.set(name, objIDs)
            props.history.replace({ pathname:props.history.location.pathname, search:decodeURIComponent(params.toString())})
        }
        
    }

    function removeParamUrl(name){
        if (props.history) {
            let params = new URLSearchParams(props.history.location.search);
        params.delete(name)
        props.history.replace({ pathname:props.history.location.pathname, search:decodeURIComponent(params.toString())})
        }
        
    }

    function handleMenu(){
        if ( refMenuOpen.current === true) {
           props.setMenuOpen(false);
        } else {
            props.setMenuOpen(true);
        }
    }

    function processTurnOffRadioLayers() {
        if (props.radioObj) {

			if (props.radioObj['format'] === "pngGroup") {
                let turnOffArray = [];
                if (props.selectedPngGroups) {
                    var newSelectedPngGroups = [ ...props.selectedPngGroups ];
                } else {
                    var newSelectedPngGroups = [];
                }
				
				const overlayIndex = newSelectedPngGroups.indexOf(props.radioObj['id']);
				newSelectedPngGroups.splice(overlayIndex, 1);
				props.setSelectedPngGroups(newSelectedPngGroups);

                turnOffArray.push("pngGroup-" + props.radioObj['id']);

                // var targets = document.getElementsByClassName("pngGroup-" + props.radioObj['id']);
				// var targetArray = [].slice.call(targets);
				// for (let i = 0; i < targetArray.length; i++) {
				// 	targetArray[i].classList.remove('markerOn');
				// }
                
				props.setRadioObj(null);
                if (turnOffArray.length > 0) {
                    console.log('turnOffArray', turnOffArray);
                    props.setTurnOffLayerArray([turnOffArray, "class"]);
                }
			}
			if (props.radioObj['format'] === "svgOverlay") {
                let turnOffArray = [];
				// processOverlay(radioObj['id'], null, false);
				var newSelectedOverlays = [ ...props.selectedOverlays ];
				const overlayIndex = newSelectedOverlays.indexOf(props.radioObj['id']);
				newSelectedOverlays.splice(overlayIndex, 1);
				props.setSelectedOverlays(newSelectedOverlays);
				
                turnOffArray.push("mapboxOverlay_" + props.radioObj['id']);
                // var target = document.getElementById("mapboxOverlay_" + props.radioObj['id']);
				// target.classList.remove(`${mapStyles.markerOn}`);

				props.setRadioObj(null);
                if (turnOffArray.length > 0) {
                    console.log('turnOffArray', turnOffArray);
                    props.setTurnOffLayerArray([turnOffArray, "id"]);
                }

			}
            
		}
    }

    function processCategory(objID) {
        console.log('processCategory received', objID)
        var categoryOverlays = props.activeOverlays.filter(item0 => ((item0.category === objID)));
        var categoryOverlaysOnlySelected = categoryOverlays.filter(item => (
            props.selectedOverlays.includes(item.id) && (item.svg_id === null)
            
            ));
        var categoryOverlayGroupsSelected = categoryOverlays.filter(item => (props.selectedOverlayGroups.includes(item.svg_id)));
        var categoryOverlaysSelected = categoryOverlaysOnlySelected.concat(categoryOverlayGroupsSelected);

        console.log("categoryOverlaysSelected", categoryOverlaysSelected);

        if ((categoryOverlays.length === categoryOverlaysSelected.length)&&(categoryOverlays.length > 0)) {
           var newActiveCategories = [ ...props.activeOverlayCategories ];
           const categoryIndex = newActiveCategories.map(function(x) {
               return x.id; 
            }).indexOf(objID);
           
           newActiveCategories[categoryIndex]['status'] = 'on';
           props.setActiveOverlayCategories(newActiveCategories);
           
           var newSelectedCategories = [ ...props.selectedCategories ];
            const newSelectedCategoryIndex = newSelectedCategories.indexOf(objID);
            if (newSelectedCategoryIndex < 0) {
                newSelectedCategories.push(objID);
                props.setSelectedCategories(newSelectedCategories);
            }

        } else {

            var newActiveCategories = [ ...props.activeOverlayCategories ];

            console.log("newActiveCategories", newActiveCategories);

            const categoryIndex = newActiveCategories.map(function(x) {return x.id; }).indexOf(objID);
            if (categoryOverlaysSelected.length === 0) {
                if (newActiveCategories[categoryIndex]) {
                    newActiveCategories[categoryIndex]['status'] = 'off';
                }
                var newSelectedCategories = [ ...props.selectedCategories ];
                const newSelectedCategoryIndex = newSelectedCategories.indexOf(objID);
                newSelectedCategories.splice(newSelectedCategoryIndex, 1);
                props.setSelectedCategories(newSelectedCategories);
                
            } else {
                newActiveCategories[categoryIndex]['status'] = 'semi-on';
            }
            props.setActiveOverlayCategories(newActiveCategories);
            
        }
    }

    function handleFileClick(objID, heading, categories, pngGroups) {
        console.log('handleFileClick', objID, heading, categories, pngGroups, props.activeFile);
        if (objID !== props.activeFile) {
            props.setActiveOverlays([]);
            // setMenuOpen(false);
            props.setMenuOpen(false);
            props.setActiveFile(objID);
            removeParamUrl('links');
            removeParamUrl('group');
            // categoryStatusOff();
            var newActiveCategories = [ ...categories ];
            newActiveCategories.forEach((item, i) => {
                newActiveCategories[i]['status'] = 'off';
                if (i === (newActiveCategories - 1)) {
                    props.setActiveOverlayCategories(newActiveCategories);
                }
            })
            
            
            if (pngGroups && pngGroups.length > 0) {
                props.setPngGroups(pngGroups);
            } else {
                props.setPngGroups([]);
            }
            gsap.to(refMapLayerButtons.current, {opacity: 1, duration: 0.3})
        }
       
      
    }

    function processPngGroupOverlay(objID, isOnlyOn) {
        
        console.log('processPngGroupOverlay',objID, isOnlyOn)
        var newSelectedPngGroups = [];
        if (props.selectedPngGroups && props.selectedPngGroups.includes(objID)) {
           
            var newSelectedPngGroups = [ ...props.selectedPngGroups ];
            const overlayIndex = newSelectedPngGroups.indexOf(objID);
            newSelectedPngGroups.splice(overlayIndex, 1);
            props.setSelectedPngGroups(newSelectedPngGroups);
            
            props.setTargetPngGroupLayerIds([objID, "off"]);

         
            
        } else {
            
            processTurnOffRadioLayers();
            
            if (isOnlyOn) {

                props.setRadioObj({"id": objID, "format": "pngGroup"});
                props.setTurnOffAllLayers(true);
                setTimeout(() => {
                    props.setTargetPngGroupLayerIds([objID, "on"]);
                    props.setSelectedPngGroups([objID]);
                }, 300);

            } else { 
                if (props.selectedPngGroups) {
                    var newSelectedPngGroups = [ ...props.selectedPngGroups ];  
                } else {
                    let newSelectedPngGroups = [];  
                }
                      
                if (props.radioObj && props.radioObj['format'] === "pngGroup") {
  
                    const overlayIndex = newSelectedPngGroups.indexOf(props.radioObj['id']);
                    newSelectedPngGroups.splice(overlayIndex, 1);
                    newSelectedPngGroups.push(objID);
                    props.setSelectedPngGroups(newSelectedPngGroups);
                    props.setTargetPngGroupLayerIds([objID, "on"]);
                 
                    
                } else {
                    
                    newSelectedPngGroups.push(objID);
                    props.setSelectedPngGroups(newSelectedPngGroups);
                    props.setTargetPngGroupLayerIds([objID, "on"]);

                }
              
            }


        }
        if (newSelectedPngGroups.length === 0) {
            removeParamUrl('pngs');
        } else {
            if (newSelectedPngGroups.length === 1) {
                updateParamUrl("pngs", newSelectedPngGroups);
            } else {
                updateParamUrl("pngs", newSelectedPngGroups.join());
            }
        }
    }

    function handleCheckCircleClick(objID, isRadio, categoryID, svgLayerID, isOnlyOn) {
        console.log('handleCheckCircleClick', objID, isRadio, categoryID, isOnlyOn)
        // setFlyToAnim(false);
        if (isRadio === true) {

            if (props.selectedOverlays && props.selectedOverlays.includes(objID)) {
                var newSelectedOverlays = [];
                props.setSelectedOverlays(newSelectedOverlays);
                props.setSelectedOverlayGroups([]);
                setRadioOn(false);
           
            } else {  
                var newSelectedOverlays = [];
                newSelectedOverlays.push(objID)
                props.selectedOverlays(newSelectedOverlays);
                setRadioOn(true);
            
            }
            if (props.activeOverlayCategories) {
                var newActiveCategories = [ ...props.activeOverlayCategories ];
            } else {
                var newActiveCategories = [];
            }
            
            newActiveCategories.forEach((item, i) => {
                newActiveCategories[i]['status'] = 'off';
                if (i === (newActiveCategories - 1)) {
                    props.setActiveOverlayCategories(newActiveCategories);
                }
            });
            
            props.setSelectedCategories([]);

        } else {

            if (isOnlyOn) {

                if (props.activeOverlayCategories) {
                    var newActiveCategories = [ ...props.activeOverlayCategories ];
                } else {
                    var newActiveCategories = [];
                }

                newActiveCategories.forEach((item, i) => {
                    newActiveCategories[i]['status'] = 'off';
                    if (i === (newActiveCategories - 1)) {
                        props.setActiveOverlayCategories(newActiveCategories);
                    }
                });


            }
            processOverlay(objID, svgLayerID, isOnlyOn);
        }
        setTimeout(() => {
            props.setHandleCategoryId(categoryID);
        }, 300);
        
        if (isOnlyOn) {
            var currSelectedCategories = [];
   
            
        } else {
            if (props.selectedCategories) {
                var currSelectedCategories = [ ...props.selectedCategories ];
            } else {
                var currSelectedCategories = [];
            }
            
            
        }
        // if (currSelectedCategories.includes(categoryID)) {
        //     props.setSelectedCategories(currSelectedCategories);
        // } else {
        //     currSelectedCategories.push(categoryID);
        //     props.setSelectedCategories(currSelectedCategories);
        // }

    }
    
    function handlePngGroupClick(objID, isOnlyOn) {
        processPngGroupOverlay(objID, isOnlyOn);
    }

    function handleCategoryClick(objID) {

        if (props.selectedCategories && props.selectedCategories.includes(objID)) {  
            var newSelectedCategories = [ ...props.selectedCategories ];
            const categoryIndex = newSelectedCategories.indexOf(objID);
            
            newSelectedCategories.splice(categoryIndex, 1);
            props.setSelectedCategories(newSelectedCategories);

            if (props.selectedOverlays) {
                var newSelectedOverlays = [ ...props.selectedOverlays ];
            } else {
                var newSelectedOverlays = [];
            }

            if (props.selectedOverlayGroups) {
                var newSelectedOverlayGroups = [ ...props.selectedOverlayGroups ];
            } else {
                var newSelectedOverlayGroups = [];
            }
            
            var categoryOverlays = props.activeOverlays.filter(item => ((item.category === objID)));
            categoryOverlays.forEach((item, j) => {
                const overlayIndex = newSelectedOverlays.indexOf(item.id);
                newSelectedOverlays.splice(overlayIndex, 1);
                const overlayGroupIndex = newSelectedOverlayGroups.indexOf(item.svg_id);
                if (overlayGroupIndex) {
                    newSelectedOverlayGroups.splice(overlayGroupIndex, 1);
             
                }

                if (j === (categoryOverlays.length - 1)) {
                    props.setSelectedOverlays(newSelectedOverlays);
                    props.setSelectedOverlayGroups(newSelectedOverlayGroups);
                }
               
            });

            var turnOffArray = [];

            if (props.activeOverlayCategories) {
                var newActiveCategories = [ ...props.activeOverlayCategories ];
            } else {
                var newActiveCategories = [];
            }
            
            const activeCategoryIndex = newActiveCategories.map(function(x) {return x.id; }).indexOf(objID);
            newActiveCategories[activeCategoryIndex]['status'] = 'off';
            props.setActiveOverlayCategories(newActiveCategories);

            props.selectedOverlays && props.selectedOverlays.forEach((selectedID, i) => {
                var selectedFile = props.activeOverlays.filter(item => ((item.id === selectedID && item.category === objID)));
               
                if (selectedFile.length > 0) {
                    if (selectedFile[0].svg_id) {
                        const overlayIndex = newSelectedOverlayGroups.indexOf(selectedFile[0].svg_id);
                        newSelectedOverlayGroups.splice(overlayIndex, 1);
                        turnOffArray.push(selectedFile[0].svg_id)
                    }  else {
                        turnOffArray.push("mapboxOverlay_" + selectedID)
                    }
                }
            });

            props.selectedOverlayGroups.forEach((objID, i) => {
                var selectedFile = props.activeOverlays.filter(item => ((item.svg_id === objID)));
                if (selectedFile.length > 0) {
                    if (selectedFile[0].svg_id) {
                        const overlayIndex = newSelectedOverlayGroups.indexOf(selectedFile[0].svg_id);
                        newSelectedOverlayGroups.splice(overlayIndex, 1);
                        turnOffArray.push(selectedFile[0].svg_id)
                    }
                }
                
                if (i === props.selectedOverlayGroups.length - 1) {
                    props.setSelectedOverlayGroups(newSelectedOverlayGroups);
                    updateParamUrl('links', newSelectedOverlayGroups.join());
                }
            });
            props.setTurnOffLayerArray([turnOffArray, 'id']);
            console.log('handleCategoryClick, turn off', turnOffArray);
        } else {

            if (radioOn === true) {
                setRadioOn(false);
            } else {
                if (props.selectedOverlays) {
                    var newSelectedOverlays = [ ...props.selectedOverlays ]; 
                } else {
                    var newSelectedOverlays = [];
                }
                if (props.selectedOverlayGroups) {
                    var newSelectedOverlayGroups = [ ...props.selectedOverlayGroups ]; 
                } else {
                    var newSelectedOverlayGroups = [];
                }
                if (props.selectedCategories) {
                    var newSelectedCategories = [ ...props.selectedCategories ]; 
                } else {
                    var newSelectedCategories = [];
                }
            }
            
            newSelectedCategories.push(objID)
            props.setSelectedCategories(newSelectedCategories);

            if (props.activeOverlays) {
                var categoryOverlays = props.activeOverlays.filter(item => ((item.category === objID)&&(props.selectedOverlays && !props.selectedOverlays.includes(item.id))));
            } else {
                var categoryOverlays = [];
            }
            
            categoryOverlays.forEach((item, j) => {
                newSelectedOverlays.push(item.id)
                if (item.svg_id) {
                    newSelectedOverlayGroups.push(item.svg_id);
                }
    
                if (j === (categoryOverlays.length - 1)) {
                    props.setSelectedOverlays(newSelectedOverlays);
                    props.setSelectedOverlayGroups(newSelectedOverlayGroups);
                }
            });

            // console.log('handleCategoryClick, turn on', props);
        }
    }

    function processOverlay(objID, svgLayerID, isOnlyOn) {
       
        console.log("processOverlay", objID, svgLayerID, isOnlyOn)
        if (svgLayerID) {
            // var targetGroup = document.getElementById(svgLayerID);                
            if (props.selectedOverlayGroups && props.selectedOverlayGroups.includes(svgLayerID)) {
                var newSelectedOverlayGroups = [ ...props.selectedOverlayGroups ];
                const overlayIndex = newSelectedOverlayGroups.indexOf(svgLayerID);
                newSelectedOverlayGroups.splice(overlayIndex, 1);
                props.setSelectedOverlayGroups(newSelectedOverlayGroups);
                if (newSelectedOverlayGroups.length === 1) {
                    updateParamUrl("links", newSelectedOverlayGroups);
                } else {
                    updateParamUrl("links", newSelectedOverlayGroups.join());
                }
                props.setTargetLayerId(["mapboxOverlay_" + objID, svgLayerID, false]);
                
            } else {
                let newSelectedOverlayGroups;
                if (isOnlyOn) {
                    props.setTurnOffAllLayers(true);
                    newSelectedOverlayGroups = [];
                } else {
                    if (props.selectedOverlayGroups) {
                        newSelectedOverlayGroups = [ ...props.selectedOverlayGroups ];
                    } else {
                        newSelectedOverlayGroups = [];
                    }
                }
                newSelectedOverlayGroups.push(svgLayerID);
                props.setSelectedOverlayGroups(newSelectedOverlayGroups);
                props.setTargetLayerId(["mapboxOverlay_" + objID, svgLayerID, true]);  
            }


        }
        if (props.selectedOverlays && props.selectedOverlays.includes(objID)) {
            var newSelectedOverlays = [ ...props.selectedOverlays ];
            const overlayIndex = newSelectedOverlays.indexOf(objID);
            newSelectedOverlays.splice(overlayIndex, 1);
            props.setSelectedOverlays(newSelectedOverlays);
            props.setTargetLayerId(["mapboxOverlay_" + objID, svgLayerID, false]);

        } else {
            processTurnOffRadioLayers();
            var newSelectedOverlays = [];
            if (radioOn === true) {
                var newSelectedOverlays = [];
                setRadioOn(false);
            } else {
                if (isOnlyOn) {
                    props.setTurnOffAllLayers(true); 
                    var newSelectedOverlays = [];
                    props.setSelectedPngGroups([]);
                    props.setRadioObj({"id": objID, "format": "svgOverlay"});
                    

                } else {
                    if (props.selectedOverlays) {
                        var newSelectedOverlays = [ ...props.selectedOverlays ];
                    } else {
                        var newSelectedOverlays = [];
                    }
                    
                }          
            }
            
            setTimeout(() => {
                newSelectedOverlays.push(objID)
                props.setSelectedOverlays(newSelectedOverlays);
                props.setTargetLayerId(["mapboxOverlay_" + objID, svgLayerID, true]);
            }, 300);   
        }
    }


    return (
            <div id="rightNav" className={`${mapStyles.rightNavMenu} ${(props.menuOpen)?(mapStyles.menuOn):("")} ${(props.navOpen)?(mapStyles.navOpen):("")} ${Globals.instance().controllerApp?mapStyles.controllerNav:""}`}>
            <nav id="rightNavMenu" className={mapStyles.rightNavMenuNav}>

                 
            {(!props.menuOpen ? <div 
                className={`${mapStyles.navHeading}`}
                style={Globals.instance().getThemeStyle(['module-heading-bg'],props.model.theme)}
                onClick={() => (props.files.length > 1)?handleMenu():console.log("no options")}
                >
                <span
                className={`${mapStyles.moduleHeading}`}
                style={Globals.instance().getThemeStyle(['module-heading'],props.model.theme)}
                >
            
                {props.activeFileHeading}

                    {(props.files.length > 1) && <div className={`round-btn ${mapStyles.navBurger}`}>
                        <IconHamburger></IconHamburger>
                    </div>}
                    
                </span>
                </div> : <div>{props.files && props.files.map((file, i) => (
                <div 
                    className={`${mapStyles.dropdownChoice} ${(props.activeFile === file.id)?(mapStyles.choiceOn):("")}`}
                    key={'map_file_' + i}
                    onClick={() => handleFileClick(file.id, file.title, file.categories, file.pnggroups)}
                >
                {file.short_title}
            
                </div>
            ))}</div>)}

            </nav>
            <span ref={refMapLayerButtons}>
                <div className={`${mapStyles.buttons}`}>
                    {props.activeOverlayCategories && props.activeOverlayCategories
                    .filter((category) => ((category.radio_overlays === false)&&(category.interactive === true)))
                    .map((category, i) => (
                    <div 
                        key={'map_category_' + i} 
                        className={`${mapStyles.overlayCategory}`}
                    >
                    
                    <div 
                    style={Globals.instance().getThemeStyle(['module-map-check'],props.model.theme)}
                    onClick={() => handleCategoryClick(category.id)}
                    id={"categoryCircle_" + category.id}
                    className={`${mapStyles.categoryLabel} ${(category.status === 'semi-on')?(mapStyles.choiceSemiOn):("")} ${props.selectedCategories && props.selectedCategories.includes(category.id)?(mapStyles.choiceOn):("")}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                    <g transform="translate(0 -144)">
                        <circle className={`${mapStyles.outerCircle}`} cx="7.5" cy="7.5" r="7.5" transform="translate(8.5 152.5)" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1"/>
                        <circle className={`${mapStyles.innerCircle}`} cx="3.5" cy="3.5" r="3.5" transform="translate(12.5 156.5)" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1"/>
                    </g>
                    </svg>
                    {category.name}
                    </div>

                    {props.activeOverlays && props.activeOverlays
                        .filter((overlay) => ((overlay.category === category.id)&&(overlay.interactive === true)))
                        // .sort((a, b) => a.ordering - b.ordering)
                        .map((overlay, i) => (
                        
                        <div 
                            style={Globals.instance().getThemeStyle(['module-map-check'],props.model.theme)}
                            onClick={() => handleCheckCircleClick(overlay.id, false, category.id, overlay.svg_id, overlay.radio_btn)}
                            key={'map_button_' + i} 
                            className={`${mapStyles.checkCircle} ${(overlay.svg_id)?((props.selectedOverlayGroups && props.selectedOverlayGroups.includes(overlay.svg_id))?(mapStyles.choiceOn):("")):(((props.selectedOverlays && props.selectedOverlays.includes(overlay.id))?(mapStyles.choiceOn):("")))}`}
                        >
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                        <g transform="translate(0 -144)">
                            <circle className={`${mapStyles.outerCircle}`} cx="7.5" cy="7.5" r="7.5" transform="translate(8.5 152.5)" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1"/>
                            <circle className={`${mapStyles.innerCircle}`} cx="3.5" cy="3.5" r="3.5" transform="translate(12.5 156.5)" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1"/>
                        </g>
                        </svg>
                            <div>{overlay.name}</div> 
                        </div>
                    ))}

                    
            
                    </div>
                ))}

                {props.activeOverlays && props.activeOverlays
                    .filter((overlay) => ((overlay.category === null)&&(overlay.radio_btn === false)&&(overlay.interactive === true)))
                    .map((overlay, i) => (
                    <div 
                        key={'map_category_' + i} 
                        className={`${mapStyles.overlayCategory}`}
                    >
                    <div 
                        style={Globals.instance().getThemeStyle(['module-map-check'],props.model.theme)}
                        onClick={() => handleCheckCircleClick(overlay.id, false, null, overlay.svg_id, overlay.radio_btn)}
                        key={'map_single_button_' + i} 
                        className={`${mapStyles.categoryLabel} ${(props.selectedOverlays && props.selectedOverlays.includes(overlay.id))?(mapStyles.choiceOn):("")}`}
                    >
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                    <g transform="translate(0 -144)">
                        <circle className={`${mapStyles.outerCircle}`} cx="7.5" cy="7.5" r="7.5" transform="translate(8.5 152.5)" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1"/>
                        <circle className={`${mapStyles.innerCircle}`} cx="3.5" cy="3.5" r="3.5" transform="translate(12.5 156.5)" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1"/>
                    </g>
                    </svg>
                    <div>{overlay.name}</div> 
                    </div>
                    </div>
                ))}
        
                <div className={`${mapStyles.radioButtons}`}>
                {props.activeOverlayCategories && props.activeOverlayCategories
                    .filter((category) => ((category.radio_overlays === true)&&(category.interactive === true)))
                    .map((category, i) => (
                    <div 
                        key={'map_category_' + i} 
                        className={`${mapStyles.overlayCategory}`}
                    >   
                    <div 
                    // onClick={() => handleThumbnailClick(file.id, file.fileType, file.file)}
                    className={`${mapStyles.categoryLabel}`}>
                    <ChevronButton></ChevronButton>
                    {category.name}
                    </div>
                    {props.activeOverlays && props.activeOverlays
                        .filter((overlay) => ((overlay.category === category.id)&&(overlay.interactive === true)))
                        // .sort((a, b) => a.ordering - b.ordering)
                        .map((overlay, i) => (
                        <div 
                        style={Globals.instance().getThemeStyle(['module-map-check'],props.model.theme)}
                            onClick={() => handleCheckCircleClick(overlay.id, true, category.id)}
                            key={'map_button_' + i} 
                            className={`${mapStyles.checkCircle} ${(props.selectedOverlays && props.selectedOverlays.includes(overlay.id))?(mapStyles.choiceOn):("")} ${(props.selectedOverlayGroups && props.selectedOverlayGroups.includes(overlay.svg_id))?(mapStyles.choiceOn):("")}`}
                        >
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                        <g transform="translate(0 -144)">
                            <circle className={`${mapStyles.outerCircle}`} cx="7.5" cy="7.5" r="7.5" transform="translate(8.5 152.5)" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1"/>
                            <circle className={`${mapStyles.innerCircle}`} cx="3.5" cy="3.5" r="3.5" transform="translate(12.5 156.5)" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1"/>
                        </g>
                        </svg>
                            
                        <div>{overlay.name}</div> 
                        </div>
                    ))}
            
                    </div>
                ))}
                </div>
        
                <div className={`${mapStyles.pngGroupButtons}`}>
                
                {props.pngGroups && props.pngGroups
                    .filter((overlay) => (overlay.radio_btn === false))
                    .map((overlay, i) => (
                    <div 
                        key={'map_category_' + i} 
                        className={`${mapStyles.overlayCategory}`}
                    >
                    <div 
                        style={Globals.instance().getThemeStyle(['module-map-check'],props.model.theme)}
                        onClick={() => handlePngGroupClick(overlay.id, overlay.radio_btn)}
                        key={'map_single_button_' + i} 
                        className={`${mapStyles.categoryLabel} ${(props.selectedPngGroups && props.selectedPngGroups.includes(overlay.id))?(mapStyles.choiceOn):("")}`}
                    >
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                    <g transform="translate(0 -144)">
                        <circle className={`${mapStyles.outerCircle}`} cx="7.5" cy="7.5" r="7.5" transform="translate(8.5 152.5)" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1"/>
                        <circle className={`${mapStyles.innerCircle}`} cx="3.5" cy="3.5" r="3.5" transform="translate(12.5 156.5)" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1"/>
                    </g>
                    </svg>
                    <div>{overlay.name}</div> 
                    </div>
                    </div>
                ))}
                </div>
                
                {
                    (showButtonRule && ((props.activeOverlays && props.activeOverlays.some(function(el) { return el.radio_btn === true;}))|| (props.pngGroups && props.pngGroups.some(function(el) { return el.radio_btn === true;}))))
                    ?(<div className={`${mapStyles.buttonRule}`}></div>):""
                }
                

                {props.activeOverlays && props.activeOverlays
                    .filter((overlay) => ((overlay.category === null)&&(overlay.radio_btn === true)))
                    .map((overlay, i) => (
                    <div 
                        key={'map_category_' + i} 
                        className={`${mapStyles.overlayCategory}`}
                    >
                    <div 
                        style={Globals.instance().getThemeStyle(['module-map-check'],props.model.theme)}
                        onClick={() => handleCheckCircleClick(overlay.id, false, null, overlay.svg_id, overlay.radio_btn)}
                        key={'map_single_button_' + i} 
                        className={`${mapStyles.categoryLabel} ${(props.selectedOverlays && props.selectedOverlays.includes(overlay.id))?(mapStyles.choiceOn):("")}`}
                    >
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                    <g transform="translate(0 -144)">
                        <circle className={`${mapStyles.outerCircle}`} cx="7.5" cy="7.5" r="7.5" transform="translate(8.5 152.5)" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1"/>
                        <circle className={`${mapStyles.innerCircle}`} cx="3.5" cy="3.5" r="3.5" transform="translate(12.5 156.5)" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1"/>
                    </g>
                    </svg>
                        <div>{overlay.name}</div> 
                    </div>
                    </div>
                ))}

                <div className={`${mapStyles.pngGroupButtons} ${(props.menuOpen)?mapStyles.off:''}`}>
                
                {props.pngGroups && props.pngGroups
                    .filter((overlay) => (overlay.radio_btn === true))
                    .map((overlay, i) => (
                    <div 
                        key={'map_category_' + i} 
                        className={`${mapStyles.overlayCategory}`}
                    >
                    <div 
                        style={Globals.instance().getThemeStyle(['module-map-check'],props.model.theme)}
                        onClick={() => handlePngGroupClick(overlay.id, overlay.radio_btn)}
                        key={'map_single_button_' + i} 
                        className={`${mapStyles.categoryLabel} ${(props.selectedPngGroups && props.selectedPngGroups.includes(overlay.id))?(mapStyles.choiceOn):("")}`}
                    >
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                    <g transform="translate(0 -144)">
                        <circle className={`${mapStyles.outerCircle}`} cx="7.5" cy="7.5" r="7.5" transform="translate(8.5 152.5)" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1"/>
                        <circle className={`${mapStyles.innerCircle}`} cx="3.5" cy="3.5" r="3.5" transform="translate(12.5 156.5)" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1"/>
                    </g>
                    </svg>
                    <div>{overlay.name}</div> 
                    </div>
                    </div>
                ))}
                </div>
        
                
                </div>
            </span>
        </div>
    )

})

export default MapBoxMenu