
import * as THREE from 'three';
import defaultMarker from 'assets/webgl/default-marker.png'
import gsap,{Back} from 'gsap';
import {getContentPath} from 'system/AssetManager'
import Utils from '../scene/Utils';

export default class CamManager {
  constructor(parent) {
    // this.iconURL = '/textures/left-building-tag.png';
    this.p = parent;
    // this.p.scene = parent.scene
    
    this.icons = [];
    this.colorReverseObjects=[]
    this.cameraAnimation = {
      animating: false,
    };
    this.time = 0;
    this.PI2= 2 * Math.PI;
    this.animatingObjects = [];
  }

  update() {
    const t = (new Date()).getTime();
    if (this.time === 0) { this.time = t; }
    const tstep = t - this.time;
    
    this.updateCameraAnimation()

    // ICONS
    this.updateIcons()

    this.animateObjects(tstep)

    this.time = t;
  }
  onCompleteAnimView(){
    this.cameraAnimation.percent = 1;
    this.cameraAnimation.animating = false;
    this.p.controls.enabled = true;
    this.animateFrame = false;
    this.setBoundsOnControls();
    if(this.currentView.auto_rotate===true)
      this.p.controls.autoRotate=true
    
    this.icons=[]
    this.currentView.icons.forEach((obj) => {
      this.icons.push(this.genIconFromData(obj));
    });
  }
  updateCameraAnimation(){
    if (this.cameraAnimation.animating) {
      this.removeBoundsOnControls(); // turn ooff bounds
      // complete then finish up
      if (this.cameraAnimation.percent > 1) {
       this.onCompleteAnimView()
      }

      // sine animation
      const per = Math.sin(this.cameraAnimation.percent * Math.PI / 2);

      this.cameraAnimation.percent = ((new Date()).getTime() - this.cameraAnimation.timeStart) / (this.cameraAnimation.timeEnd - this.cameraAnimation.timeStart);

      const tar = this.cameraAnimation.targetEnd.clone();
      tar.sub(this.cameraAnimation.targetStart);
      tar.multiplyScalar(per);
      tar.add(this.cameraAnimation.targetStart);
      this.p.controls.target.set(tar.x, tar.y, tar.z);


      const angle = per * this.cameraAnimation.angleEnd;
      let pos = this.cameraAnimation.startpos.clone().sub(this.p.controls.target);
      pos.add(this.cameraAnimation.diffpos.clone().multiplyScalar(per));
      pos.normalize();
      pos.multiplyScalar(this.cameraAnimation.d1 + (this.cameraAnimation.d2 - this.cameraAnimation.d1) * per);
      pos.add(this.p.controls.target);

      const sp = this.cameraAnimation.startpos.clone();
      const ep = this.cameraAnimation.endpos.clone();
      const perp = new THREE.Vector3();
      perp.crossVectors(sp, ep);
      perp.normalize();


      // pop it out a little
      if (Math.abs(this.cameraAnimation.angleEnd) > Math.PI / 1.5 && Math.abs(perp.y) < 0.3) {
        perp.z += Math.sign(perp.z) * Math.sin(-per * Math.PI) / 2;

        perp.normalize();
      }

      const rotationMatrix = new THREE.Matrix4();
      rotationMatrix.makeRotationAxis(perp, angle);

      pos = this.cameraAnimation.startpos.clone().applyMatrix4(rotationMatrix);
      pos.normalize();
      pos.multiplyScalar(this.cameraAnimation.startpos.length() + (this.cameraAnimation.endpos.length() - this.cameraAnimation.startpos.length()) * per);

      this.p.camera.position.set(pos.x, pos.y, pos.z);
      // this.p.controls.update();
    }
  }
  updateIcons(){

    const theta = this.p.controls.getAzimuthalAngle()+ Math.PI;

    // ICONS
    let size = new THREE.Vector2()
    this.p.renderer.getSize(size)

    this.icons.forEach((icon) => {
      const hidden =!this.isVisibleInRange(theta, icon.minTheta, icon.maxTheta)
      if(icon.sprite){
        if (icon.sprite.visible && icon.sprite.material.opacity > 0 && hidden) {
          // this.setAnimationOnObject(icon.sprite, 'fadeOut');
          this.tweenFadeOut(icon.sprite,0.5)
        } else if (!hidden && (!icon.sprite.visible || icon.sprite.material.opacity < 1)) {
          // this.setAnimationOnObject(icon.sprite, 'fadeIn');
          this.tweenFadeIn(icon.sprite,0.5)
        }

        if (icon.sprite.visible) {
          this.setOrthoPosition(icon,size);
        }
      }
    });
  }
  tweenFadeOut(obj,duration){
    if(obj.fadingOut ===true)return 
    obj.material.transparency = true;
    obj.material.depthWrite = false;
    obj.castShadow = false;
    obj.fadingOut=true
    gsap.killTweensOf(obj.material)
    gsap.to(obj.material,{duration:duration,opacity:0,
      onUpdate:(o)=>{
        if(o && o.material && o.material.opacity>0.35) o.castShadow=true
        if(o.userData.scale){
          // let s= o.material.opacity * o.userData.scale
          o.scale.set(o.material.opacity * o.userData.scale.x,o.material.opacity * o.userData.scale.y)
        }
      },
      onUpdateParams:[obj],
      ease:Back.easeIn,
      onComplete:(o)=>{
        if(o)
          o.visible=false 
          o.fadingOut=false
          o.fadingIn=false
      },
    onCompleteParams:[obj]
    })
    // if(obj.userData.scale && (obj.scale.x !== 0)){
    //   let animO={scale:obj.scale.x}
    //   gsap.to(animO,{scale:0, ease:Back.easeIn, duration:duration, onUpdate:(o,a)=>{
    //     console.log(a.scale)
    //     o.scale.set(a.scale,a.scale, a.scale)},
    //     onUpdateParams:[obj,animO]})
    // }
  }
  tweenFadeIn(obj,duration){
    
    if(obj.fadingIn === true) return
    obj.visible = true;
    obj.material.depthWrite = true;
    obj.fadingIn=true
    console.log('fading in ')
    gsap.killTweensOf(obj.material)
    gsap.to(obj.material,{duration:duration,opacity:1,
      onUpdate:(o)=>{
        if(o.userData.scale){
          // let s= o.material.opacity * o.userData.scale
          o.scale.set(o.material.opacity * o.userData.scale.x,o.material.opacity * o.userData.scale.y)
          
        }
      },
      onUpdateParams:[obj],
      onComplete:(o)=>{
          if(o)
          o.castShadow=true
          o.fadingOut=false
          o.fadingIn=false
          
    },
    ease:Back.easeOut,
    onCompleteParams:[obj]
    })

    // if(obj.userData.scale && (obj.scale.x !== obj.userData.scale)){
    //   let animO={scale:obj.scale.x}
    //   gsap.to(animO,{scale:obj.userData.scale,duration:duration, ease:Back.easeIn,
    //     onUpdate:(o,a)=>{o.scale.set(a.scale,a.scale,a.scale)},onUpdateParams:[obj,animO]})
    // }
  }

  animateObjects(tstep){
    this.animatingObjects.forEach((a) => {
      if (a.animation === 'fadeIn') {
        this.animateFadeIn(a.mesh, a.start, tstep);
      } else if (a.animation === 'fadeOut') {
        this.animateFadeOut(a.mesh, a.start, tstep);
      }
    });
  }

  getIconById(uid) {
    const fIconIndex = this.icons.findIndex(obj => obj.id === uid);
    if (fIconIndex > -1) { return this.icons[fIconIndex]; }
    return null;
  }

  setAnimationOnObject(obj, anim) {
    // chekc if there one
   let id =obj.id?obj.id:obj.name
    const findIndex = this.animatingObjects.findIndex(aobj => id === aobj.id);
    if (findIndex > -1) {
      if (this.animatingObjects[findIndex].animation !== anim) {
        console.log("Setting",anim)
        this.animatingObjects[findIndex].animation = anim;
        this.animatingObjects[findIndex].start = (new Date()).getTime();
      }
    } else {
     
      this.animatingObjects.push({ animation: anim, mesh: obj, start: (new Date()).getTime() ,id:id});
    }
  }

  animateFadeIn(obj, start, step) {
    obj.visible = true;
    obj.material.opacity = obj.material.opacity + (1 - obj.material.opacity) / 10 + 0.001;
    if (obj.material.opacity > 0.5) { obj.castShadow = true; }

    obj.material.depthWrite = true;
    if (obj.material.opacity >= 1) {
      obj.material.opacity = 1;

      // obj.renderOrder = 0;
      const findIndex = this.animatingObjects.findIndex(aobj => obj.name === aobj.mesh.name);
      if (findIndex > -1) this.animatingObjects.splice(findIndex, 1);
    }
  }

  animateFadeOut(obj, start, step) {
    // obj.renderOrder = 1;
    obj.material.transparency = true;
    obj.material.depthWrite = false;
    obj.castShadow = false;

    obj.material.opacity = obj.material.opacity - (obj.material.opacity / 6) - 0.001;
    if (obj.material.opacity <= 0.0) {
      obj.material.opacity = 0.0;
      obj.visible = false;
      const findIndex = this.animatingObjects.findIndex(aobj => obj.name === aobj.mesh.name);
      if (findIndex > -1) this.animatingObjects.splice(findIndex, 1);
    }
  }
  /* eslint-disable */
  fadeInObject(obj) {
    obj.visible = true;
  }

  
  rangePI2(v){
    let val=v
    if(val<0)val+=this.PI2
    else if(val>this.PI2)val-=this.PI2
    return val
  }
  isHiddenInRange(angle, min, max) {
    let small = min;
    let big = max;
    let ang = angle;
    if (small < 0) small += 2 * Math.PI;
    if (big < 0) big += 2 * Math.PI;
    if (ang < 0) ang += 2 * Math.PI;

    // if(min==max==angle || min==max==0){return true}
    if (small < big) { return ang > small && ang < big; }
    return ang > small || (ang < big);
  }
  isVisibleInRange(angle, min, max) {
    let small = this.rangePI2(min);
    let big = this.rangePI2(max);
    let ang = this.rangePI2(angle);
    // console.log(angle,min,max)
    if (small <= big) { return ang >= small && ang <= big; }
    return ang > small || (ang < big);
  }
  /* eslint-enable */

  animateView(obj) {
    

    this.disposeIcons();

    this.p.controls.autoRotate=false
    
    // this.icons.forEach(i=>{
    //   i.animateingout=true
    //   gsap.to(i.sprite.material,{opacity:0,duration:0.35,onComplete:()=>{}})
    // })

    this.animateTo(
      new THREE.Vector3(obj.position[0], obj.position[1], obj.position[2]),
      new THREE.Vector3(obj.lookat[0], obj.lookat[1], obj.lookat[2]),
    );

    this.currentView = obj;
    this.animateColorOffsets()
  }


  animateTo(pos, targetend) {
    const distance = this.p.camera.position.distanceTo(pos);
    const distanceCam = this.p.camera.position.distanceTo(this.p.controls.target);
    const distanceDest = pos.distanceTo(this.p.controls.target);
    const angle = this.p.camera.position.angleTo(pos);// Math.acos((distanceCam*distanceCam+distanceDest*distanceDest-distance*distanceDest)/(2*distanceCam*distanceDest));
    const diffPos = pos.clone();

    diffPos.sub(this.p.camera.position);

    this.p.controls.enabled = false;
    this.cameraAnimation.animating = true;
    this.cameraAnimation.startpos = this.p.camera.position.clone();
    this.cameraAnimation.endpos = pos.clone();
    this.cameraAnimation.diffpos = diffPos;
    this.cameraAnimation.d1 = distanceCam;
    this.cameraAnimation.d2 = distanceDest;
    this.cameraAnimation.timeStart = (new Date()).getTime();
    this.cameraAnimation.angleStart = 0;
    this.cameraAnimation.angleEnd = angle;
    this.cameraAnimation.timeEnd = this.cameraAnimation.timeStart + 500 + Math.abs(angle) * 500 + distance * 500;
    if (this.cameraAnimation.timeEnd > this.cameraAnimation.timeStart + 10000) { this.cameraAnimation.timeEnd = this.cameraAnimation.timeStart + 10000; }
    this.cameraAnimation.percent = 0;
    this.cameraAnimation.targetStart = this.p.controls.target.clone();
    this.cameraAnimation.targetEnd = targetend.clone();

  }

  genIconFromData(obj) {
    let url = defaultMarker
    if(obj.url) url = getContentPath(obj.url)
    const I = this.get2DSprite(url, new THREE.Vector3(obj.position[0], obj.position[1], obj.position[2]),obj.scale);
    I.sprite.name = obj.name;
    I.name = obj.name;
    I.minTheta = obj.minTheta ? obj.minTheta : 0;
    I.maxTheta = obj.maxTheta ? obj.maxTheta : 0;
    I.sprite.scale.set(0.0001,0.0001)
    I.sprite.material.opacity=0
    I.key=obj.key
    // console.log(I)
    return I;
  }

  onRemoveIcon(name) {
    const index = this.icons.findIndex(obj => obj.name === name);
    if (index > -1) {
      const obj = this.icons.splice(index, 1)[0];
      Utils.disposeHierarchy(obj.sprite)
      // this.p.sceneOrtho.remove(obj.sprite);
      // obj.sprite.material.dispose();
    }
  }

  get2DSprite(icon, pos, s) {
    const spr = new THREE.Sprite();
    const textureLoader = new THREE.TextureLoader();

    textureLoader.load(icon, (text) => {
      text.generateMipMaps = true;
      const spriteMaterial = new THREE.SpriteMaterial({ map: text, color: 0xffffff ,transparent:true,opacity:0});
      const h = s?s:50;
      let aspect= text.image.naturalWidth / text.image.naturalHeight
      spr.scale.set(aspect * h, h);
      spr.userData.scale= spr.scale.clone()

      // spr.scale.set(h, h/aspect);
      spr.userData.naturalHeight=text.image.naturalHeight
      spr.userData.naturalWidth=text.image.naturalWidth
      // spriteMaterial.map.magFilter = THREE.NearestFilter
			// spriteMaterial.map.minFilter = THREE.LinearMipMapLinearFilter
      spr.material = spriteMaterial;
      // spr.material.map.needsUpdate = true;
      
    });

    this.p.sceneOrtho.add(spr);
    
    spr.visible = true;

    return { sprite: spr, position: pos, uuid: spr.uuid };
  }


  setOrthoPosition(obj,size) {
    
    obj.sprite.updateMatrixWorld();
    const vector = new THREE.Vector3(obj.position.x, obj.position.y, obj.position.z);// dup
    
    vector.project(this.p.controls.object); // project camera
    
    vector.x = (vector.x + 1) * 0.5 * size.width -  (0.5 * size.width);
    vector.y = (vector.y + 1) * 0.5 * size.height - (0.5 *size.height);
   
    vector.z = 0.1;
    
    obj.sprite.position.x = vector.x//(vector.x - (0.5 * size.width)) ;
    obj.sprite.position.y = vector.y//(vector.y - (0.5 * size.height));
    obj.sprite.position.z = 0.1
    
    return vector;
  }

  disposeIcons() {
    if (this.icons) {
      this.icons.forEach((obj) => {
        this.p.sceneOrtho.remove(obj.sprite);
        // obj.sprite.material.dispose();
        Utils.disposeHierarchy(obj.sprite)
      });
    }
  }

  animateColorOffsets(){
    

      if(this.colorReverseObjects.length){
        this.colorReverseObjects.forEach(mesh=>{ this.animateMeshColor(mesh,mesh.oColor)})
      }
      this.colorReverseObjects=[]
    let mesh = this.p.colorOffsetViews[this.currentView.id]
    if(mesh){
      if (this.p.colorOffsetViews[this.currentView.id]) {
        console.log(this.p.colorOffsetViews[this.currentView.id]);
        this.p.colorOffsetViews[this.currentView.id].forEach( mesh=>{
          this.animateMeshColor(mesh,mesh.highColor)
          this.colorReverseObjects.push(mesh)
        })
      }

    }
  }

  animateMeshColor(mesh,c){
    gsap.killTweensOf(mesh)

    let animObj = {
      percent: 0,
      fromR: mesh.material.color.r,
      fromG: mesh.material.color.g,
      fromB: mesh.material.color.b,
      toR: c.r,
      toG: c.g,
      toB: c.b,
      m: mesh
    }
    gsap.to(animObj, {
      duration:1, 
      percent: 1,
      onUpdate: function (obj) {
        obj.m.material.color = new THREE.Color(
          obj.fromR + (obj.toR - obj.fromR) * obj.percent,
          obj.fromG + (obj.toG - obj.fromG) * obj.percent,
          obj.fromB + (obj.toB - obj.fromB) * obj.percent )
      },
      onUpdateParams: [animObj]
    })
  }

  setBoundsOnControls() {
    this.p.controls.minPolarAngle = this.currentView['min-polar'];
    this.p.controls.maxPolarAngle = this.currentView['max-polar'];
    this.p.controls.maxDistance = this.currentView.max;
    this.p.controls.minDistance = this.currentView.min;
  }

  removeBoundsOnControls() {
    this.p.controls.minDistance = 0;
    this.p.controls.maxDistance = Infinity;
    this.p.controls.minPolarAngle = 0;
    this.p.controls.maxPolarAngle = Infinity;
  }


  destroy() {
    this.cameraAnimation=null;
    this.currentView = null;
    this.animatingObjects = [];
    this.colorReverseObjects=[];
    this.disposeIcons();
    this.icons = undefined;
    this.currentView = undefined;
    this.p = undefined;
  }
}
