
import React,{useState,useRef,memo,useCallback,useEffect} from 'react'
// import CanvasComponent from './CanvaseBase'
import {animateParallax,getAnimationModel,animateIntersect,setInitialAnimIntersectState} from './CanvasAnimator'
import Globals from 'system/Globals'
// import ScaleableText from 'system/components/ScaleText'

function HTMLComponent(props){
    
    
    const [inline,setInline] = useState({})
    // const [inlineText,setTextStyle] = useState({margin:0})
    const [html,setHTML] = useState(0)
    // const [render,setRenderNum] = useState(0)
    
    let bLoaded=false
    const refEle = useRef()
    const refFormat = useRef()
    const refOrigOffset = useRef(0)
    const refAnimationModel = useRef({})
    

    useEffect(()=>{ 
        refOrigOffset.current=refEle.current.offsetLeft
        if(refFormat.current && refFormat.current.animation){
            refAnimationModel.current = getAnimationModel(refEle.current,refFormat.current.animation) 
        }
    },[inline,refFormat.current]) // eslint-disable-line react-hooks/exhaustive-deps
    
    const onScroll = useCallback((scrollLeft,scale,pageWidth)=>{
        if(refAnimationModel.current && refAnimationModel.current)
            animateParallax(refEle.current,refAnimationModel.current,scrollLeft,pageWidth)
    },[refFormat.current,refEle])// eslint-disable-line react-hooks/exhaustive-deps

    const onIntersect=  useCallback((evt)=>{
        
        if(evt.detail.isIntersecting )
        {
            if(evt.detail.intersectionRatio >=0.5)
                animateIntersect(refEle.current,refAnimationModel.current)  
        }
        else if(evt.detail.intersectionRatio <=0)
            setInitialAnimIntersectState(refEle.current,refAnimationModel.current)
    },[refEle])

    useEffect(()=>{
        
        console.log("HTML component")
        
        refFormat.current = Globals.instance().getCanvasFormat(props.model.formats,props.format)
        let style ={position:'absolute',transform: 'translateZ(0)',willChange:'transform'}
        style.position='absolute'
        if(refFormat.current.style)
        {
            style = Object.assign(style,refFormat.current.style)
        }
        
        setInline(style)

        setHTML(refFormat.current.html)
        onLoad()
        //set up animation Type
        if(refFormat.current.animation){
            if(refFormat.current.animation.typeName==='parallax'){
                props.setScrollCallback(onScroll,props.index)
            } 
            else if(refFormat.current.typeName==='page'){
                props.onIntersect(onIntersect,props.index)
            }
        }
        return ()=>{
            
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    

    async function onLoad(){
        if(!bLoaded){
            props.onLoad(props.index,refEle.current,props.model)
            bLoaded=true
        } 
    }
    
    return ( 

        <div style={inline} ref={refEle} className={'force3d'}  dangerouslySetInnerHTML={{__html:html}} >

        </div>
        
    )

}

const HTML = memo( HTMLComponent ,(prev,next)=>{ 
    return false
})

export  {HTML}
