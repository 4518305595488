
import React,{forwardRef,useEffect,useRef,useState} from 'react'
import mstyles from '../modules.module.css'
import {getContentPath} from  'system/AssetManager'

import LoaderWheel from 'system/components/LoaderWheel'
import {ReactComponent as CloseButton} from 'assets/icons/close.svg'
import gsap,{Power2} from 'gsap'
import Globals  from 'system/Globals'

const ImageModule = forwardRef((props, ref)=>{

    const refFormat = useRef()
    const refImage = useRef()
    const [imgSrc,setImgSrc] = useState(0)
    const [bLoaded,setBLoaded] = useState(false)

    useEffect(()=>{
        refFormat.current = Globals.instance().getCanvasFormat(props.model.formats,props.format)
        ref.current.style.display='hidden'
        setImgSrc(getContentPath(refFormat.current.file))
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    function onLoaded(){
        setBLoaded(true)
        ref.current.style.visibility='visible'
        gsap.from(ref.current,{duration:0.5,opacity:0,scale:0.5, ease:Power2.easeInOut,onComplete:()=>{
        }})
    }

    function onClickClose(evt){props.closeModule()}

    return (
        <div className={`fullscreen fcenter` } ref={ref}>
        <div className={`fullscreen fcenter`}>
            
            {!bLoaded && (
                <LoaderWheel></LoaderWheel>
            )
            }

            <img className={`fullscreen`} src={imgSrc} alt="" onLoad={onLoaded} ref={refImage}></img>

        </div>
            <div className={`${mstyles.closeBtnContainer}`}>
              
                <div className={`${mstyles.btn}`} onClick={onClickClose}>
                    <CloseButton></CloseButton>
                </div>
            </div>

        </div>
    )

})

export default ImageModule
