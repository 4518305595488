
import React,{useCallback, useEffect,useRef,useState} from 'react'
import {getContentPath} from  'system/AssetManager'
import gsap,{Power2} from 'gsap'
import Globals  from 'system/Globals'
import MapBoxMenu from '../narrative/modules/mapbox/mapbox.menu';
import ControllerJoystick from './ControllerJoystick';
import ControllerServerComs from 'system/ControllerServerComs'
import styles from './controller-narrative.module.css'
import {ReactComponent as Pin} from 'assets/icons/map_center.svg'
import {ReactComponent as ZoomOut} from 'assets/icons/minus.svg'
import {ReactComponent as ZoomIn} from 'assets/icons/plus.svg'

const ControllerMap = (props)=>{
   
    const [activeFileHeading, setActiveFileHeading] = useState(null);
    const [prevActiveFile, setPrevActiveFile] = useState(null);
    const [activeFile, setActiveFile] = useState(null);
    const [activeMap, setActiveMap] = useState(null);
    const [activeOverlayCategories, setActiveOverlayCategories] = useState([]);
    const [activeOverlays, setActiveOverlays] = useState([]);
    const [activeOverlayGroups, setActiveOverlayGroups] = useState([]);
    const [pngGroups, setPngGroups] = useState([]);
    const [popUps, setPopUps] = useState([]);
    const [handleCategoryId, setHandleCategoryId] = useState(null);
    const [paramsProcessed, setParamsProcessed] = useState(false);
    const [paramString, setParamString] = useState(null);
    const [selectedOverlays, setSelectedOverlays] = useState([]);
    const [selectedOverlayGroups, setSelectedOverlayGroups] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedPngGroups, setSelectedPngGroups] = useState([]);

    const [targetLayerId, setTargetLayerId] = useState();
    const [targetPngGroupLayerIds, setTargetPngGroupLayerIds] = useState();
    const [runTurnOffAllLayers, setTurnOffAllLayers] = useState(false);
    const [runTurnOffRadioLayers, setTurnOffRadioLayers] = useState(false);
    const [runTurnOffOverlayParamLayers, setTurnOffOverlayParamLayers] = useState(null);
    const [runTurnOffLayerArray, setTurnOffLayerArray] = useState(null);
    const [radioObj, setRadioObj] = useState(null);

    const [activePage, setActivePage] = useState(null);

    const [menuOpen, setMenuOpen] = useState(false);
    const [navOpen, setNavOpen] = useState(false);
    const [showLanding, setShowLanding] = useState(null);
	const [mapState, setMapState] = useState(null);

    const refServerAppListener =  useRef();
    const activeFileId = useRef();
	const refMap = useRef();
	const refMapState = useRef();

    function processParams(){
        let params = new URLSearchParams(props.params);
        let file0 = params.get('file');
        let landing = params.get('landing');
        if (file0 && landing){
            let fileId = parseInt(file0);
            setActiveFile(fileId);
            setShowLanding(false);
            setMenuOpen(false);
        } 
      
        props.setCurrentParams(null);

    }

    function updateParamUrl(name, objIDs){
        if (props.history) {
            let params = new URLSearchParams(props.history.location.search);
            params.set(name, objIDs)
            props.history.replace({ pathname:props.history.location.pathname, search:decodeURIComponent(params.toString())})
        }
        
    }

    function removeParamUrl(name){
        if (props.history) {
            let params = new URLSearchParams(props.history.location.search);
            params.delete(name)
            props.history.replace({ pathname:props.history.location.pathname, search:decodeURIComponent(params.toString())})
        }
    }


    useEffect(()=>{
        // eslint-disable-next-line react-hooks/exhaustive-deps
        console.log('props.module', props)
  
        setShowLanding(true);
        setMenuOpen(true);

        return () => {
            ControllerServerComs.instance().sendUDPFromControllerToServer('module-mapbox-management', 'closemodule');
        }
    },[])


    useEffect(() => {
        if (props.module.menuParams) {
            setParamString(props.module.menuParams);
        }
    }, [props.module.menuParams])

    useEffect(() => {

        if (props.module.mapboxes) {

            if (props.module.mapboxes.length > 1) {
                
            } else {
               
                setTimeout(() => {
                     setShowLanding(false);
                     setMenuOpen(false);
                }, 300);

                setActivePage(props.activeNarrativePageIndex);
                // props.setActiveNarrativePageIndex(null);

                var file = props.module.mapboxes[0];
                if (prevActiveFile === null) {
                    setPrevActiveFile(file.id);
                }   
                setActiveFile(file.id);
                setActiveFileHeading(file.short_title);
                setActiveOverlayCategories(file.categories);
                setActiveOverlayGroups(file.overlaygroups);
                setPngGroups(file.pnggroups);
                activeFileId.current = file.id;
                var overlays = file.overlays;
                if (file.base_overlay) {
                    overlays.push({
                        "id": "override",
                        "file": file.base_overlay,
                        "file_type": "svg",
                        "interactive": false
                    });
                }
                setActiveOverlays(overlays);

               
                if (file.default_on) {
                    var selectedOverlayIds = [];
                    overlays.forEach((item) => {
                        selectedOverlayIds.push(item.id);
                    });
    
                    setSelectedCategories(file.categories);
                    var activeOverlayGroupSvgIds = [];
                    file.overlaygroups.forEach((file, i) => {
                        var svg_ids = file.svg_ids.split(",");
                        for (var j = 0; j < svg_ids.length; j++) {
                            var svgId = svg_ids[j];
                            activeOverlayGroupSvgIds.push(svgId);
                        }
                    });
                    setSelectedOverlayGroups(activeOverlayGroupSvgIds);
                    setSelectedOverlays(selectedOverlayIds);
                }
                
            }
        }
    }, [props.module.mapboxes])

    function turnOffSemiOn(){
        var labels = document.getElementsByClassName('choiceSemiOn');
        // console.log('turnOffSemiOn', labels);
        for (var i = 0; i < labels.length; i++) {
            labels[i]['status'] = "off";
            }
        
        setSelectedOverlayGroups([]);

        if (selectedOverlayGroups.length === 0) {
            removeParamUrl("links");
        }
    }

    useEffect(() => {
        // console.log('activeFile from map controller0', activeFile, activeFileId.current);
        if (activeFile && (activeFileId.current !== activeFile)){
            setShowLanding(null);
        
           
            var file = props.module.mapboxes.filter(item => ((item.id === activeFile)))[0];
            ControllerServerComs.instance().sendUDPFromControllerToServer('module-mapbox-management', 'turnoffalllayers');
            ControllerServerComs.instance().sendUDPFromControllerToServer('module-mapbox-select-map', file.id);
                console.log('module-mapbox-select-map sent', file.id);
                
            setTimeout(() => {
                console.log('activeFile from map controller', activeFile, activeFileId.current);
                setActiveFileHeading(file.short_title);
                setActiveOverlayCategories(file.categories);
                setShowLanding(false);
                setMenuOpen(false);
   
                setPngGroups(file.pnggroups);
                setSelectedCategories(file.categories);
         
                turnOffSemiOn();
                // updateParamUrl("file", activeFile);
                removeParamUrl('group');
                removeParamUrl('layer');
                removeParamUrl('pngs');
                var overlays = file.overlays;
               
                if (file.base_overlay) {
                    overlays.push({
                        "id": "override",
                        "file": file.base_overlay,
                        "file_type": "svg",
                        "interactive": false
                    });
                }
                setActiveOverlays(overlays);
                setActiveOverlayGroups(file.overlaygroups);
    
                activeFileId.current = file.id;
                var initOverlayIds = [];
                var initPngGroupIds = [];
                overlays.forEach((item) => {
                    if (item.interactive) {
                        initOverlayIds.push(item.id)
                    }
                    
                });
                file.pnggroups.forEach((item) => {
                    initPngGroupIds.push(item.id)
                });

                if (file.default_on) {
                    setSelectedOverlays(initOverlayIds);
                    setSelectedPngGroups(initPngGroupIds);
                    
                } else {
                    setSelectedOverlays([initOverlayIds[0]]);
                    // // setSelectedPngGroups(initPngGroupIds[0]);
                    // console.log('default off', initOverlayIds[0], initPngGroupIds[0])
                }

                
            }, 250);

            

        }
    }, [activeFile]);


    useEffect(() => {
        if (props.params) {
            let params = new URLSearchParams(props.params);
            console.log('paramstring', params)
            let fileId = params.get('file');
            if (fileId) {
                var file0 = props.module.mapboxes.filter(item => ((item.id === parseInt(fileId))))[0];
               
                handleThumbnailClick(file0)

            }
            let layer = params.get('layer');
            if (layer) {
                setSelectedOverlays([parseInt(layer)])
            }
        }
    }, [props.params])

    useEffect(() => {
        console.log('mapbox: files', props.module.mapboxes)
    }, [props.module.mapboxes])

    useEffect(() => {
        console.log('mapbox: activeFileHeading', activeFileHeading)

    }, [activeFileHeading])

    const flattenArray = useCallback((array) => {
        var flatArray = '';
        var objArray = [];
        array.forEach((obj, i) => {
            if (!objArray.includes(obj)) {
                objArray.push(obj);
                if (i === array.length - 1) {
                    flatArray+=obj
                } else {
                    flatArray+=obj + ','
                }  
            }
            
        });
        return flatArray
    }, [])

    useEffect(() => {
        if (selectedOverlays && selectedOverlays.length > 0) {
            let result = flattenArray(selectedOverlays);
            ControllerServerComs.instance().sendUDPFromControllerToServer('module-mapbox-select-overlays', result);
            console.log('module-mapbox-select-overlays sent', result);
        }
        
    }, [selectedOverlays, flattenArray])

    useEffect(() => {
        if (selectedOverlayGroups && selectedOverlayGroups.length > 0) {
            let result = flattenArray(selectedOverlayGroups);
            // ControllerServerComs.instance().sendUDPFromControllerToServer('module-mapbox-select-overlaygroups', result);
            // console.log('module-mapbox-select-overlaygroups sent', result);
        }
        
    }, [selectedOverlayGroups, flattenArray])


    useEffect(() => {

        if (selectedCategories && selectedCategories.length > 0) {
            
            let result = flattenArray(selectedCategories);
            ControllerServerComs.instance().sendUDPFromControllerToServer('module-mapbox-select-categories', result);
            console.log('module-mapbox-select-categories sent', result);

        }
    }, [selectedCategories, flattenArray])

    useEffect(() => {
        if (selectedPngGroups && selectedPngGroups.length > 0) {
            let result = flattenArray(selectedPngGroups);
            ControllerServerComs.instance().sendUDPFromControllerToServer('module-mapbox-select-pnggroups', result);
            console.log('module-mapbox-select-pnggroups sent', result);
        }
    }, [selectedPngGroups, flattenArray])

    useEffect(() => {
        if (targetLayerId && targetLayerId.length > 0) {
            let result = flattenArray(targetLayerId);
            ControllerServerComs.instance().sendUDPFromControllerToServer('module-mapbox-select-targetlayerid', result);
            console.log('module-mapbox-select-targetlayerid sent', result);
        }
        
    }, [targetLayerId, flattenArray])

    useEffect(() => {
        if (targetPngGroupLayerIds && targetPngGroupLayerIds.length > 0) {
            let result = flattenArray(targetPngGroupLayerIds);
            ControllerServerComs.instance().sendUDPFromControllerToServer('module-mapbox-select-targetpnggroupid', result);
            console.log('module-mapbox-select-targetpnggroupid sent', result);
        }
        
    }, [targetPngGroupLayerIds, flattenArray])

    useEffect(() => {
        if (runTurnOffAllLayers) {
            ControllerServerComs.instance().sendUDPFromControllerToServer('module-mapbox-management', 'turnoffalllayers');
            console.log('module-mapbox-select-management sent', 'turnoffalllayers');
            setTurnOffAllLayers(false);
        }
        
    }, [runTurnOffAllLayers])

    useEffect(() => {
        if (runTurnOffRadioLayers) {
            ControllerServerComs.instance().sendUDPFromControllerToServer('module-mapbox-management', 'turnoffradiolayers');
            console.log('module-mapbox-select-management sent', 'turnoffradiolayers');
        }
        
    }, [runTurnOffRadioLayers])

    useEffect(() => {
        if (runTurnOffOverlayParamLayers) {
            ControllerServerComs.instance().sendUDPFromControllerToServer('module-mapbox-management', 'turnoffoverlayparamLayers,' + runTurnOffOverlayParamLayers);
            console.log('module-mapbox-select-management sent', 'turnoffoverlayparamLayers,' + runTurnOffOverlayParamLayers);
            setTurnOffOverlayParamLayers(null);
        }
        
    }, [runTurnOffOverlayParamLayers])


    useEffect(() => {
        if (runTurnOffLayerArray) {
            let result = flattenArray(runTurnOffLayerArray);
            ControllerServerComs.instance().sendUDPFromControllerToServer('module-mapbox-select-turnoffarray', result);
            console.log('module-mapbox-select-turnoffarray sent', result);
        }
        
    }, [runTurnOffLayerArray, flattenArray])

    function handleThumbnailClick(file){
		gsap.to(refMap.current, {opacity: 0, duration: 0})
		processFileChange(file);
		setShowLanding(false);
        setMenuOpen(false);
		gsap.to(refMap.current, {opacity: 1, duration: 0.3})
	}

    function processFileChange(file){
		console.log("processFileChange", file);
		if (prevActiveFile === null) {
			setPrevActiveFile(file.id);
		}
		setActiveFile(file.id);
		// setActiveFileHeading(file.short_title);
		// setActiveOverlayCategories(file.categories);
		// setActiveOverlayGroups(file.overlaygroups);
		// setPngGroups(file.pnggroups);
		

		updateParamUrl("file", file.id);
		// var overlays = file.overlays;
		// if (file.base_overlay) {
		// 	overlays.push({
		// 		"id": "override",
		// 		"file": file.base_overlay,
		// 		"file_type": "svg",
		// 		"interactive": false
		// 	});
		// }
		// setActiveOverlays(overlays);

        
     
	}


    const onZoomOut = () => {
        ControllerServerComs.instance().sendUDPFromControllerToServer('module-mapbox-control', 'zoom-out');
    }

    const onZoomIn = () => {
        ControllerServerComs.instance().sendUDPFromControllerToServer('module-mapbox-control', 'zoom-in');
     }

     const onPin = () => {
        ControllerServerComs.instance().sendUDPFromControllerToServer('module-mapbox-control', 'reset');
     }

     const onClickBack = () => {
        console.log('onClickBack') 
        props.setCurrentModule({typeName: 'controller-narrative'});
        // props.setActiveNarrativePageIndex(activePage);
        props.setAnimInCurrentModule(false);
        ControllerServerComs.instance().sendUDPFromControllerToServer('narrative-close-module');
     }


    return (
        <React.Fragment>
           <div className={`${styles.linksContainer} hide-scroller`} >
                <MapBoxMenu 
                model={props.module} 
                files={props.module.mapboxes}
                history={props.history}
                
                menuOpen={menuOpen}
                setMenuOpen={setMenuOpen}
                navOpen={navOpen}
                activeFileHeading={activeFileHeading}
                activeFile={activeFile}
                activeOverlayCategories={activeOverlayCategories}
                activeOverlays={activeOverlays}
                pngGroups={pngGroups}
                paramsProcessed={paramsProcessed}
                radioObj={radioObj}

                selectedOverlays={selectedOverlays}
                selectedOverlayGroups={selectedOverlayGroups}
                selectedCategories={selectedCategories}
                selectedPngGroups={selectedPngGroups}
                handleCategoryId={handleCategoryId}
                
                setRadioObj = {setRadioObj}
                setActiveOverlayCategories = {setActiveOverlayCategories}
                setActiveOverlays = {setActiveOverlays}
                setActiveFileHeading = {setActiveFileHeading}
                setActiveFile = {setActiveFile}
                setPngGroups = {setPngGroups}
                setHandleCategoryId = {setHandleCategoryId}

                setSelectedOverlays={setSelectedOverlays}
				setSelectedOverlayGroups={setSelectedOverlayGroups}
				setSelectedCategories={setSelectedCategories}
				setSelectedPngGroups={setSelectedPngGroups}
				setParamsProcessed={setParamsProcessed}

				setTargetLayerId={setTargetLayerId}
                setTargetPngGroupLayerIds={setTargetPngGroupLayerIds}
                setTurnOffAllLayers={setTurnOffAllLayers}
                setTurnOffRadioLayers={setTurnOffRadioLayers}
                setTurnOffOverlayParamLayers={setTurnOffOverlayParamLayers}
                setTurnOffLayerArray={setTurnOffLayerArray}

                 />

            </div>

            { 
                    
            <section className={`${styles.moduleHolder} fcenter `}>
                {props.showBackBtn && <div className={`${styles.backBtnRow}`}>
                    <div className={`${styles.pillBtn}`} onClick={() => onClickBack()}>Back to Narrative</div>
                </div>}

                {(showLanding && props.module.mapboxes.length > 1) ? 
                    (<div className={`${styles.landingContainer}`}>
						{( props.model.background_image && <img src={getContentPath(props.model.background_image)} alt="" className={`${styles.backgroundImage}`}/>)}
           
                        <div className={`${styles.heading}`}>
                            <span style={(Globals.instance().getThemeStyle(['module-landing-primary'],props.module.theme))}>{props.model.heading} Maps</span>
                        </div>
                        <div className={`${styles.itemsContainer}`}>
                            {props.module.mapboxes.map((category, i) => (
                            <div 
                                className={`${styles.itemContainer}`}
                                key={'gallery_thumb_' + i}
                                onClick={()=> handleThumbnailClick(category)}
                            >
                                <img src={getContentPath(category.thumbnail?category.thumbnail:category.basemap_img)} alt=""></img>
                                <div className={`${styles.caption}`}>
                                <span style={(Globals.instance().getThemeStyle(['module-landing-primary'],props.module.theme))}>{category.title}</span></div>
                            </div>
                            ))}
                        </div>
							
              
			
                    </div>)
                    : <ControllerJoystick 
                    sensitivity={0.25}
                    twoFingerDrag={false}
                /> }


                
                <div className={`${styles.buttons}`}>
                    <div className={`${styles.mapControls}`}>
                        <div onClick={() => onZoomOut()} className={`${styles.zoomOutBtn} ${(mapState === 'moving')?(styles.disabled):''}`}>
                            <ZoomOut></ZoomOut>
                        </div>
                        <div onClick={() => onPin()} className={`${styles.pinBtn} ${(mapState === 'moving')?(styles.disabled):''}`}>
                            <Pin></Pin>
                        </div>
                        <div onClick={() => onZoomIn()} className={`${styles.zoomInBtn} ${(mapState === 'moving')?(styles.disabled):''}`}>
                            <ZoomIn></ZoomIn>
                        </div>
                    </div>
                </div>
            </section>   

                }
        </React.Fragment>
    )

}

export default ControllerMap
