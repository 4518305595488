
import React,{useRef,useState,useEffect,memo,useCallback} from 'react'
import {connect} from 'react-redux'

import {fetchCMSJSON,fetchCMSJSONs,getContentPath} from 'system/AssetManager'
import Page from './Page'
import styles from './narrative.module.scss'
import gsap,{Power3,Power2,Power1} from 'gsap'
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import NarrativeMenu  from "./NarrativeMenu"
import ModuleContainer from './modules/ModuleContainer'
import Globals from 'system/Globals'
import LoaderWheel from 'system/components/LoaderWheel'
import NarrativeControls from './NarrativeControls'
import DrawControls  from './DrawControls'
import Markup  from  './Markup'
import ControllerServerComs from 'system/ControllerServerComs'
import postEvent from 'system/components/UserEvent';
import StartMeeting from 'system/components/StartMeeting'
import getModuleIcon from 'system/components/ModuleIcons';
// import { matchPath } from 'react-router-dom/cjs/react-router-dom.min'


gsap.registerPlugin(ScrollToPlugin);

const Narrative = function(props){

	const [status,setStatus] = useState('')
	const refLoadPercent = useRef({percent:0})
	const [loaded,setLoaded] = useState(false)
	// const [scrolling,setScrolling] = useState(false)
	const [NarrativeModel ,setNarrativeModel] = useState({})
	const [PageModels,setPageModels] = useState(0)
	const pageIndexLookup = useRef({})
	const refOpenMenu = useRef();

	// const [Interaction,setInteraction] = useState()

	const [theme,setTheme]=useState(null)
	const [modules, setModules]=useState([])
	const [currentModule, setCurrentModule]=useState(null)
	const [syncComplete,setSyncComplete]=useState(false)
	const [nid,setNId] = useState()
	const [showNarrativeControls, setShowNarrativeControls] = useState(false)
	const [showDrawControls, setShowDrawControls] = useState(false)
	const [showMarkup, setShowMarkup] = useState(false)
	const [menuItems,setMenuItems] = useState([])
	const [flatMenuItems,setFlatMenuItems] = useState(null)
	const [currentPage, setCurrentPage] = useState(0)
	const [firstLoad, setFirstLoad] = useState(true);
	const [comsReady, setComsReady] = useState();
	const [activePage, setActivePage] = useState(null);
	const [openMenu, setOpenMenu] = useState('init');
	const [openMenuFull, setOpenMenuFull] = useState(false);
	const [onLanding, setOnLanding] = useState(true);
	const [icons,setIcons] = useState([])

	const refEle =useRef()
	const refMenu =useRef()
	const refNarrativeControls =useRef()
	const refInteraction =useRef()

	const refTopBar =  useRef()
	const refChildrenPages =useRef()
	const refCurrentPage =useRef(0)
	const refScrollTable =useRef({})

	const refListeners= useRef([])
	const refResizeListener= useRef()
	const refScroller =useRef()
	const refScrollerContainer =useRef()
	const refScrollVel= useRef(0)
	const refDown= useRef(false)
	const timerScroller =useRef()
	const timerSearchParams =useRef()
	const pageTableLeft =useRef([])
	const pageTableWidth =useRef([])
	const PageLoadCount =useRef(0)
	const refScrollLeft = useRef(0)
	const refScrollSpeed = useRef(0)
	const refTotalWidth=useRef(0)
	const refInitialSlide=useRef(0)
	const refInitialModule=useRef(0)
	const refDimensionCache = useRef([])
	const refLoaderContainer=useRef()
	const refWindowListener =  useRef()
	const refServerAppListener =  useRef()
	const refKeyListenerUp =  useRef()
	const refKeyListenerDown =  useRef()
	const refScrolltween = useRef()
	const refScrollPos = useRef()


	let firstObservered = false
	// let useiPad=useRef(false)


	useEffect( ()=>{

		console.log('narrative props', props, Globals.instance().downloaded)
		
		setStatus('Loading')

		let params = new URLSearchParams(new URL(window.location.href).search);


		if(!loaded){
			gsap.to(refLoaderContainer.current,{duration:0.35,ease:Power3.easeInOut,opacity:1})
			gsap.to(refLoaderContainer.current.querySelector('.loader-container svg'),{duration:0.5,scale:1,ease:Power3.easeInOut,opacity:1})
		}


		if(Globals.instance().useHashRouter){
			let arrurl=window.location.href.split("?")
			if(arrurl.length)
			params = new URLSearchParams(arrurl[1])
		}

		refInitialSlide.current=params.get("slide")
		refInitialModule.current=params.get("module")


		// console.log('params.get("slide")', params.get("slide"))

		let observer = new IntersectionObserver((entries)=>{
			entries.forEach((e)=>{
				let index = e.target.getAttribute("index")

				// console.log('observer index', index);
				
				refChildrenPages.current[index].dispatchEvent(
					new CustomEvent('intersection',{detail:e})
					)
					// console.log('customevent', e)
			})
		},{threshold:[0.001,0.35,0.6]})
		
		refInteraction.current=observer
		// setInteraction(observer)
		let eleScroller = refScroller.current;

		let nid= (props.history.location.pathname.indexOf('/narrative')===0 )?props.history.location.pathname.split("/")[2]:props.nid
		setNId(nid?nid:props.nid )
		Globals.instance().nid=props.nid

		let hideString = localStorage.getItem('expo.downloadAlert')
        // let hideString = Globals.instance().downloadAlerts;
        let hideList = JSON.parse(hideString)
        console.log('hideList', nid, hideList)
        if (!(hideList.indexOf(nid) > -1)) {
            hideList.push(nid)

            let newList = JSON.stringify(hideList)
            console.log('hideList', hideList, newList)
            localStorage.setItem('expo.downloadAlert', newList)
            // ControllerServerComs.instance().postMessageFromServerToElectron("narrative-hide-alerts",newList)
        }

		let listen =false
		let strSync=localStorage.getItem('manualSync')


		if(Globals.instance().ios && strSync!=='false' && !Globals.instance().controllerApp){
			// window.webkit.messageHandlers.notification.postMessage({ Object: 'sync-narrative', narrative:nid })
			// listen=true
			setCurrentModule(null)
		}
		if(Globals.instance().electron && strSync!=='false'){
			// listen=true
			// window.api.send("toApp", {"event":"sync-narrative","narrative":nid});
		 }

		if(listen){

			if(Globals.instance().serverApp)
			ControllerServerComs.instance().postMessageFromServerToElectron( "narrative-load-state",{"narrative":nid,"state":"loading"})


			setStatus('Loading')
			refWindowListener.current=(evt)=>{

				let detail = evt.detail
				if(!detail)return
				if(detail.narrative===nid){
					setStatus(detail.message)
				}
				if(detail.message==="Complete"){
					setSyncComplete(true)
					
					window.removeEventListener('window-event',refWindowListener.current)
					if(Globals.instance().serverApp)
						ControllerServerComs.instance().postMessageFromServerToElectron("narrative-load-state",{"narrative":nid,"state":"complete"})
					}



				if(detail.event==='sync-error'){
					console.log("Received Error",detail.message)

					if(Globals.instance().homeLink){
						props.history.push(Globals.instance().homeLink)
					}else{
						props.history.push(Globals.instance().getRoute('/home'))
					}
					Globals.instance().showAlert('Connection Error',detail.message)
					if(Globals.instance().serverApp)
					ControllerServerComs.instance().postMessageFromServerToElectron("narrative-load-state",{"narrative":nid,"state":"error"})
				}
			}
			window.addEventListener('window-event',refWindowListener.current)


		} else{

			setSyncComplete(true)
			
			if(Globals.instance().serverApp)
			ControllerServerComs.instance().postMessageFromServerToElectron("narrative-load-state",{"narrative":nid,"state":"complete"})
		}


	



		refKeyListenerDown.current=(evt)=>{
			if (evt.code === "ArrowLeft" || evt.code === "ArrowRight"){
				evt.preventDefault()
			}
		}
	
		console.log("w", window.innerWidth, "h", window.innerHeight, "d", window.devicePixelRatio)



		return ()=>{
			//cleanup
			console.log("CLEANUP")
			if(eleScroller && refListeners.current){
				refListeners.current.forEach(l=>{ eleScroller.removeEventListener(l.e,l.f)})
			}
			if(refInteraction.current)refInteraction.current.disconnect()

			refListeners.current=undefined
			onScrollEvent=undefined

			window.removeEventListener('resize',refResizeListener.current)
			window.removeEventListener('window-event',refWindowListener.current)
			window.removeEventListener('server-event',refServerAppListener.current)
			document.removeEventListener('keyup',refKeyListenerUp.current)
			document.removeEventListener('keydown',refKeyListenerDown.current)

			observer.disconnect();

			cancelAnimationFrame(timerScroller.current)
			clearTimeout(timerSearchParams.current)

			refScrollTable.current=undefined
			refListeners.current=undefined
			refScrolltween.current=undefined
			refResizeListener.current=null
			refChildrenPages.current=undefined

			// POST USER EVENT
			// postEvent(props.user.username, {"event": "page-exit", "page": "narrative", "page_id": props.nid, "page_title": NarrativeModel.heading}, props.user.market, props.user.category);
		}


	  // eslint-disable-next-line react-hooks/exhaustive-deps
	},[])

	useEffect(() => {
		console.log('currentPage', currentPage)

		if (currentPage && flatMenuItems) {
			setActivePage(flatMenuItems[currentPage].page)
			console.log('activePage id in currentPage', flatMenuItems[currentPage].page)
		}

	}, [currentPage, flatMenuItems])


	useEffect(() => {
		// console.log('openMenuFull', openMenuFull)
		if (openMenuFull) {
			setOnLanding(true);
			gsap.to(refNarrativeControls.current, {opacity: 0})
		} else {
			if (openMenu !== 'init') {
				setOnLanding(false)
			}
			
			gsap.to(refNarrativeControls.current, {opacity: 1})
		}

	}, [openMenuFull])

	useEffect(() => {
		if (props.setShowWelcome) {
			let params = new URLSearchParams(new URL(window.location.href).search);
			const room = params.get('join');
			console.log('narrative join', room)
			if (room) {

				

				props.setShowWelcome(true);
			} 
		}
	}, [props.setShowWelcome])

	useEffect(() => {
		if ((Globals.instance().electron || Globals.instance().ios) && props.nid) {
			if (Globals.instance().downloaded.indexOf(props.nid) !== -1 || (Globals.instance().controllerApp)) {
				Globals.instance().cmspath='./api/'
				Globals.instance().contentpath = './media/'
				Globals.instance().useHashRouter = true
				Globals.instance().usecms=false
				
			} else {
				Globals.instance().cmspath = Globals.instance().adminAPI
				Globals.instance().contentpath = Globals.instance().loadedContentpath
				Globals.instance().useHashRouter = false
				Globals.instance().usecms=true
			}
			console.log("Globals updated", Globals.instance().cmspath, Globals.instance().contentpath, Globals.instance().useHashRouter, Globals.instance().usecms)
		}
	}, [props.nid])

	useEffect(() => {
		console.log('props.socketPrefs etc', props.socketPrefs)
		if (props.socket && props.socketPrefs && props.socketPrefs.mode === 'isReceive' && comsReady && props.nid) {
		
			props.socket.emit('join-handshake', [props.socketPrefs.room, props.socket.id, 'nav#path=/narrative/' + props.nid]);
			console.log('join-handshake sent on nid', props.socket.id, 'nav#path=/narrative/' + props.nid)
			// setupServerComs(modules);
			// setTimeout(() => {
			//     props.socket.emit('handshake', [props.socketPrefs.room, props.socket.id, 'nav#path=/narrative/' + props.nid]);
			// }, 300);

		}
	}, [props.socketPrefs, props.socket, comsReady, props.nid])

	// const scrollBy = useCallback((p,dur,easeeq)=>{
	//     console.log("scrollby"+p)
	//     if(p<0)p=0
	//     if(p>refScroller.current.scrollWidth-refScroller.current.clientWidth){
	//         p=refScroller.current.scrollWidth-refScroller.current.clientWidth
	//         console.log("over")
	//     }
	//     // console.log(p)
	//     if(refScrolltween.current) refScrolltween.current.kill()
	//     let objtween={left:parseFloat(refScrollPos.current.style.left?refScrollPos.current.style.left:0)}
	//     refScrolltween.current = gsap.to(objtween,
	//         {duration:dur?dur:2.5, left:p,
	//             onUpdate:(objtw)=>{
	//                 refScrollPos.current.style.left=objtw.left+'px'
	//                 // onScrollEvent()
	//             },
	//             onUpdateParams:[objtween],
	//             ease:easeeq?easeeq:Power3.easeInOut,
	//             onComplete:(eleScroll)=>{
	//             eleScroll.style.pointerEvents='all'
	//             },onCompleteParams:[refScroller.current]
	//     })
	// },[refScrolltween])

	function pageTransition(p,animFade,dur,easeeq) {
		setCurrentPage(p)
		console.log('pageTransition', p, animFade,dur,easeeq)
		refScroller.current.style.overflow='hidden'
		let target = refScrollerContainer.current.querySelector(".Page[index=\""+p+"\"]")
		let page = target.getAttribute('slideid')

		if(refScrolltween.current)refScrolltween.current.kill()

		if(animFade === 'fade'){
			refScrolltween.current = gsap.timeline();
			refScrolltween.current.to(refScroller.current, {opacity: 0, duration: 0.5})
			refScrolltween.current.to(refScroller.current, {opacity: 1, duration: 0.5})
			refScrolltween.current.to(refScroller.current, {scrollTo: {y: target}, duration: 0}, "<")

		}  else{
			
			refScrolltween.current = gsap.to(refScroller.current,{duration:dur?dur:1, scrollTo:{y:target},ease:easeeq?easeeq:Power3.easeInOut,onComplete:(eleScroll)=>{
				eleScroll.style.pointerEvents='all'
			},onCompleteParams:[refScroller.current],
			onUpdate:()=>{
				// onScrollEvent()
			}})

		}
		// console.log('scroll to broadcast', menuItems)
		if (props.socketPrefs && props.socketPrefs.mode === "isBroadcast" && loaded) {

			props.socket.emit('cast', [props.socketPrefs.room, 'narrative-menu-link?page=' + page + '&origin=/narrative/' + props.nid]);

			// console.log('narrative-menu-link?page=', page + '&origin=/narrative/' + props.nid)
		}

		if (props.socketPrefs && props.socketPrefs.mode && props.socketPrefs.mode === "isReceive") {

			props.socket.emit('handshake', [props.socketPrefs.room, props.socket.id, 'narrative-menu-link?page=' + page + '&origin=/narrative/' + props.nid]);
			console.log('page handsake sent', 'narrative-menu-link?page=' + page)
		}

	}

	const scrollToPage = useCallback((p,animFade,dur,easeeq)=>{
		setCurrentPage(p)
		// console.log('scrollToPage', p,animFade,dur,easeeq)
		refScroller.current.style.overflow='hidden'
		let target = refScrollerContainer.current.querySelector(".Page[index=\""+p+"\"]")
		let page = target.getAttribute('slideid')

		if(refScrolltween.current)refScrolltween.current.kill()

		if(animFade === 'fade'){
			
			let tl = gsap.timeline();
			tl.to(refScroller.current, {opacity: 0, duration: 0.5})
			tl.to(refScroller.current, {opacity: 1, duration: 0.5})
			tl.to(refScroller.current, {scrollTo: {y: target}, duration: 0}, "<")

		}  else{

				refScrolltween.current = gsap.to(refScroller.current,{duration:dur?dur:1, scrollTo:{y:target},ease:easeeq?easeeq:Power3.easeInOut,onComplete:(eleScroll)=>{
					eleScroll.style.pointerEvents='all'
				},onCompleteParams:[refScroller.current],
			onUpdate:()=>{
				// onScrollEvent()
			}})

		}
		// console.log('scroll to broadcast', menuItems)
		if (props.socketPrefs && props.socketPrefs.mode === "isBroadcast") {

			props.socket.emit('cast', [props.socketPrefs.room, 'narrative-menu-link?page=' + page + '&origin=/narrative/' + props.nid]);

			console.log('narrative-menu-link?page=', page + '&origin=/narrative/' + props.nid)
		}

		if (props.socketPrefs && props.socketPrefs.mode && props.socketPrefs.mode === "isReceive") {

			props.socket.emit('handshake', [props.socketPrefs.room, props.socket.id, 'narrative-menu-link?page=' + page + '&origin=/narrative/' + props.nid]);
			console.log('page handsake sent', 'narrative-menu-link?page=' + page)
		}

		

},[])

	// useEffect(() => {
	// 	if (scrollToObj) {
	// 		console.log('scrollToObj', scrollToObj)
	// 		setTimeout(() => {
				
	// 		}, 300);
			
	// 	}
	// }, [scrollToObj, scrollToPage])

	const scrollOffset = useCallback((offset)=>{
		cancelAnimationFrame(timerScroller.current)
		let p =-1
		   Array.from(refDimensionCache.current).forEach((ele,index)=>{


				if(refDimensionCache.current[index].left < refScroller.current.scrollLeft + 5){
				   p=index
			   }
		   })
		//    console.log(p)
		   p=p+offset
		   if(p<0)return
		   if(p>=refDimensionCache.current.length)return
		scrollToPage(p, NarrativeModel.transition)
   },[refScroller,refDimensionCache,scrollToPage])


   useEffect(()=>{

	if( props.modules){ 
		 let ps=[]
		 ps.push(getModuleIcon({'icon': "", 'title': props.model.heading, 'typeName': 'narrative'}, props.theme, 10))
		props.modules.forEach((val,index)=>{
			 ps.push(getModuleIcon(val,props.theme,index))
		 })
		
		 Promise.all(ps).then(vals=>{ 
			 setIcons(vals)
		 })
	 }
 },[props.modules,  props.theme])



   async function getPage(pageIndex, id){
	let pages = [ ...PageModels ]
	let page1 = null;
	
	console.log('getPage', pageIndex, id)

	setOnLanding(false);

	if (flatMenuItems && flatMenuItems.length > 0) {
		if (flatMenuItems[pageIndex]) {
			let path = Globals.instance().adminAPI + '/api/narrative/' + props.nid + '/' + id + "?format=json"
			console.log('getPage', path)
			page1 = await fetchCMSJSON(path,null,null,null);
			pages[pageIndex] = page1
			pages[pageIndex]['loaded'] = "true"
		}
		// console.log('props.flatMenuItems', props.flatMenuItems)
		
				//// platform filter
		pages.count > 0 && pages.forEach(p=>{
			if (p.components.length > 0) {
				let filteredComponents = p.components.filter(c=>{
					if(c.platform){
						// console.log(c.platform, Globals.instance().ipad)
						if (c.platform==="ipad" ) return  Globals.instance().ipad
						else if (c.platform==="desktop" ) return  !Globals.instance().ipad
						else return true
					}
					else return true
				})
				p.components=filteredComponents
			} 
			

		})
		console.log('newpages', pages)
		setPageModels(pages);
		
		setTimeout(() => {
			observeElements()
			
		}, 500);
	}
	
	
}

	function getPageAndTransition(pageIndex, id) {
		
		console.log('getPageAndTransition', pageIndex, id)
		if(refInteraction.current)refInteraction.current.disconnect()
		firstObservered = false;

		return getPage(pageIndex, parseInt(id))
	}


   useEffect(() => {
	// console.log('currentPage', currentPage)
	if (currentPage && PageModels !== 0) {
		// console.log('currentPage', currentPage)
		refCurrentPage.current = currentPage
		// scrollToPage(currentPage, 'fade')

		
		// if (currentPage > 0) {
		// 	// getPage(currentPage, PageModels[currentPage].id)
		
			
		// 	// console.log('onPageChange 2')
		// }
		onPageChange(PageModels[currentPage].id)
		
		// console.log('activePage id in onPageChange', PageModels[currentPage].id, PageModels[currentPage])
		
	}
}, [currentPage, loaded, PageModels])



	useEffect(()=>{

		if(syncComplete){

			let m;
			m = props.narratives.find( val=>{ return String(val.id)===nid})



			console.log('m', m)

			if(m===undefined){
				console.warn('could not find narrative '+nid)
				Globals.instance().showAlert('Error','Could not find narrative ('+nid+')')
			}
			else{
				(async ()=>{

					let res = null
					let path = ""
					try {
						// setStatus('Loading JSON')
						path = m.content? m.content:'narrative/'+m.id+'/'

						console.log('syncComplete path', path, m.content)

						let downloadedArray = [];
						let downloadCookie = localStorage.getItem('expo.downloaded')
						if (downloadCookie) {
							downloadedArray = JSON.parse(downloadCookie)
						}
					
						if ((Globals.instance().electron || Globals.instance().ios) && downloadedArray.length > 0 && downloadedArray.findIndex(a =>  parseInt(a.id) === m.id) !== -1) {
							Globals.instance().useLocal = true;
						} else {
							Globals.instance().useLocal = false;
						}

						console.log('Globals.instance().useLocal', Globals.instance().useLocal, downloadedArray, m.id)
						
						res= await fetchCMSJSON(path,null,null,null)
						// console.log(res)
					} catch(err){
						console.log('syncComplete err', path, m.content)
						console.error(err)
						let strSync=localStorage.getItem('manualSync')
						if(strSync==='false'){
							Globals.instance().showAlert('The Narrative you requested is not available','Please check your that  manual sync is off in your settings to allow the narrative to download <br> <em>'+path+'</em>' )
						}else{
							Globals.instance().showAlert('Load Error','Narrative Content <br> <em>'+path+'</em>' )
						}
						//abort narrative go head back
						let params = new URLSearchParams()
						console.log("going "+props.history.location.pathname)
						props.history.push({pathname:props.history.location.pathname,search:params.toString()})
						return
					}

					try {
						// setStatus('Setting Theme',props.themes[res.theme?'t'+res.theme:'t1'])
						if (res.css) {
						   console.log('res css', res.css)
						   setTheme(res.css)
						} else {
							setTheme(props.themes[res.theme?'t'+res.theme:'t1'])
						}


					}catch(err){
						console.error(err)

						Globals.instance().showAlert('Error','Could not load narrative theme')
						//abort narrative go head back
						let params = new URLSearchParams()
						props.history.push({pathname:props.history.location.pathname,search:params.toString()})
						return

					}
					console.log('res', res)
					setNarrativeModel(res);
					refChildrenPages.current=(new Array(res.pages.length))

				
			
					// Fetch Page JSON
					let local = false;
					if (Globals.instance().downloaded.indexOf(props.nid) !== -1) {
						local = true
					}
					let pageResults = await fetchCMSJSONs(res.pages,null,local)
				
					pageResults.forEach((p,i)=>{ pageIndexLookup.current[String(p.id)]=i})
					
					// let pages = [];
					// // let pages = [ ...PageModels ]
					let page1 = null;

					// console.log('pageResults', pageResults)
					

					if (res.menu_links && res.menu_links.length > 0) {
						let path = Globals.instance().adminAPI + '/api/narrative/' + props.nid + '/' + pageResults[0].id + "?format=json"

						let local = false;
						if (Globals.instance().downloaded.indexOf(props.nid) !== -1) {
							local = true
						}
						page1=await fetchCMSJSON(path,null,null,null,local)
						page1['loaded'] = "true"
		
						// pages[pageUpdateIndex] = page1
						pageResults[0] = page1
						// console.log("refInitialSlide.current", refInitialSlide.current)
						if (!refInitialSlide.current) {
							setActivePage(pageResults[0].id)
							// console.log('setActivePage 1')
						} 
						
				
						// console.log('path', path, page1)
					}



					//// platform filter
					pageResults.forEach(p=>{
						if (p.components.length > 0) {
							let filteredComponents = p.components.filter(c=>{
								if(c.platform){
									// console.log(c.platform, Globals.instance().ipad)
									if (c.platform==="ipad" ) return  Globals.instance().ipad
									else if (c.platform==="desktop" ) return  !Globals.instance().ipad
									else return true
								}
								else return true
							})
							p.components=filteredComponents
						} 
						

					})
					setPageModels(pageResults);

					// console.log('firstpage load', pageResults)

					// console.log('res', res, NarrativeModel, pages)
					try{
					}catch(err){
						console.error(err)
						Globals.instance().showAlert('Error','Could not load narrative slides')
						//abort narrative go head back
						let params = new URLSearchParams()
						props.history.push({pathname:props.history.location.pathname,search:params.toString()})
					}
				})()
			}


			refMenu.current.style.visibility='hidden'
			refScroller.current.style.visibility='hidden'

			refResizeListener.current=()=>{
				if(refScroller.current){
					let percent = window.innerHeight/Math.floor(refScroller.current.clientHeight)
				
					refScroller.current.style.transform='scale('+percent+')'
					refScroller.current.style.width=window.innerWidth/percent+'px'
					refScroller.current.style.transformOrigin='top left'
					refDimensionCache.current.forEach( (cache,index)=>{
						let ele =refScrollerContainer.current.childNodes.item(index)
						cache.left=ele.offsetLeft
						cache.right=ele.clientWidth
					})
					try {
						refChildrenPages.current.forEach((page,i)=>{
							pageTableLeft.current[i]=page.offsetLeft
							pageTableWidth.current[i]=page.clientWidth
						  })
	
					} catch (error) {
						console.log('no children pages')
					}
					
				}
			}

		
			window.addEventListener('resize',refResizeListener.current)

			refScroller.current.style.height=window.innerHeight+'px'

		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[ syncComplete])

	//create a menuItems tree for both nar menu and controls
	useEffect(()=>{
		if(NarrativeModel){
			let flatMItems = [];
			// let pageBlanks = [];
			let flatIndex = 0;
			let mitems=[]
				if(NarrativeModel.menu_links){
				
					NarrativeModel.menu_links.forEach((val,index)=>{

					let menuItem = {id:val.id, parent_id:val.group_id, label:val.label, index:index,ordering:val.ordering, page: val.page, subs:[], thumbnail: val.thumbnail }

					if(val.group_id){
						let grp = mitems.find(mi=>{return mi.id===val.group_id})
						console.log('grp', grp)
						if (grp){
							grp.subs.push(menuItem)
						
						}
							
						}else{
							mitems.push(menuItem)
						}
					
					})
					console.log("SET ",mitems)
				 	setMenuItems(mitems.sort((a,b) => a.ordering - b.ordering))

					mitems.forEach((obj0, index) => {

						// let pageBlank = null;
						let flatMenuItem = {id: obj0.id, parent_id:obj0.parent_id, label:obj0.label, index:index,ordering:flatIndex, page: obj0.page, thumbnail: obj0.thumbnail}
						flatMItems.push(flatMenuItem)

						if (obj0.subs && obj0.subs.length > 0) {
							obj0.subs.forEach((sub, index2) => {
								let flatMenuItem = {id: sub.id, parent_id:sub.parent_id,label:sub.label,index:index2,ordering:flatIndex,page: sub.page, thumbnail: sub.thumbnail }

								// pageBlank = {'id': sub.page, 'ordering': flatIndex, 'components': [], 'controller_links': [], 'controller_states': [], 'formats': [], 'hd_size': [], 'menupage_id': null, 'nav': null, 'screenshot': null, 'scroll_offset': null, 'theme': 'default'}
								// pageBlanks.push(pageBlank)

								flatMItems.push(flatMenuItem)
								flatIndex+=1
								
							});
						} 
						// else {
							

						// 	// pageBlank = {'id': obj0.page, 'ordering': flatIndex, 'components': [], 'controller_links': [], 'controller_states': [], 'formats': [], 'hd_size': [], 'menupage_id': null, 'nav': null, 'screenshot': null, 'scroll_offset': null, 'theme': 'default'}
						// 	// pageBlanks.push(pageBlank)

							
						// 	// flatIndex+=1
						// }
						
					});
					setFlatMenuItems(flatMItems.sort((a,b) => a.ordering - b.ordering))
					//  setPageModels(pageBlanks)

				 
				}
			// POST USER EVENT
			// NarrativeModel.heading && postEvent(props.user.username, {"event": "page-enter", "page": "narrative", "page_id": props.nid, "page_title": NarrativeModel.heading}, props.user.market, props.user.category);

		
		}
	},[NarrativeModel])

	// useEffect(() => {
	// 	if (menuItems && menuItems.length > 0 && PageModels !== 0 && firstLoad && NarrativeModel && loaded) {

	// 		let pageBlanks = [ ...PageModels ];
	// 		let flatIndex = 0;
		
	// 		menuItems.forEach((obj0, index) => {

	// 			let pageBlank = null;

	// 			if (obj0.subs && obj0.subs.length > 0) {
	// 				obj0.subs.forEach((sub, index2) => {

	// 					pageBlank = {'id': sub.page, 'ordering': flatIndex, 'components': [], 'controller_links': [], 'controller_states': [], 'formats': [], 'hd_size': [], 'menupage_id': null, 'nav': null, 'screenshot': null, 'scroll_offset': null, 'theme': 'default'}
	// 					if (flatIndex > 0) {
							
	// 						pageBlanks.push(pageBlank)
						
	// 					}
						
	// 					flatIndex+=1
						
	// 				});
	// 			} else {

	// 				pageBlank = {'id': obj0.page, 'ordering': flatIndex, 'components': [], 'controller_links': [], 'controller_states': [], 'formats': [], 'hd_size': [], 'menupage_id': null, 'nav': null, 'screenshot': null, 'scroll_offset': null, 'theme': 'default'}
	// 				if (flatIndex > 0) {
	// 					pageBlanks.push(pageBlank)
	// 				}
	// 				flatIndex+=1
	// 			}
				
	// 		 });
	// 		//  setPageModels(pageBlanks)
	// 		 setFirstLoad(false)

	// 		//  console.log('menuItems', menuItems)
			 
	// 	}
	// }, [menuItems, PageModels, NarrativeModel, loaded])
	

	useEffect(()=>{

		if(theme){
			// console.log('THEME',theme)
		 for (const key of Object.keys(theme.css)) {

			if (key === 'font-family') {
				refEle.current.parentElement.style.setProperty(key,theme.css[key])
			} else {
				refEle.current.parentElement.style.setProperty('--'+key,theme.css[key])
			}
		  }
		}
	},[theme])

	useEffect(()=>{
		if(modules && modules.length && refInitialModule.current)
		{
			let module = modules.find(m=>{return String(m.id)===String(refInitialModule.current)})
			if(module){
				let m = {...module}
				m.direct=true
				// console.log(m)
				setCurrentModule(m)
			}
			refInitialModule.current=null
		}
	},[modules])

	useEffect(()=>{
		let eleVideo = refMenu.current.querySelector('video')
		if(eleVideo){
			if(currentModule)
				eleVideo.pause()
			else{
				let p=  eleVideo.play()
				if(p!==undefined){
					p.then(function() {
					// Automatic playback started!
					}).catch(function(error) {
					console.warn('MENU Video '+error)
					});
				}
			}

		}
	},[currentModule])


	function setScrollTable(cb,index){
		// console.log('setScrollTable', refScrollTable.current[index])
		refScrollTable.current[index]=cb
	}

	// function checkScrolling(){
	//     if(timerScrolling){
	//     clearTimeout(timerScrolling)
	//         timerScrolling=0;
	//         // setScrolling(false)
	//     }
	// }

	function onClickTopBar(evt){
		// let index = evt.currentTarget.getAttribute("index")
		// let left =pageTableLeft.current[index]
		// refMenu.current.dispatchEvent(new CustomEvent('close-menu'))
		// gsap.to(refScroller.current,{duration:1, scrollTo:{x:left},ease:Power3.easeInOut})
	}

	const onScrollToEvent=(pageId, transition)=>{
		
		console.log('onScrollToEvent called', pageId, transition);

		let pEle = refScrollerContainer.current.querySelector(".Page[slideid=\""+pageId+"\"]")
		if(!pEle)return
		let index =pEle.getAttribute("index")
		let transitionDefault = NarrativeModel.transition
		if (transition) {
			transitionDefault = transition
		}
	
		if (PageModels[index]['loaded'] === "true") {
			// scrollToPage(index, transitionDefault)
			pageTransition(index, transitionDefault)
		} else {
			console.log('getPageAndTransition',index, pageId)
			
			getPageAndTransition(index, pageId).then(() => {

				
				pageTransition(index, transitionDefault)
				
			});
	
		}
	

		
		
		
	}
	const selectModule=(evt)=>{
		if(evt.detail.module)
		{
			//set narrative url
			// console.log(evt.detail.module,props.history.location.search)
			let strP;

			if (evt.detail.params) {
				strP=evt.detail.params
			} else {
				strP=props.history.location.search
				if(evt.detail.module.menuParams)
					strP=strP+evt.detail.module.menuParams

			}
			let params = new URLSearchParams(strP)
			params.set("module",evt.detail.module.id)

			console.log("selectModule", props.history.location.pathname, params.toString(), evt.detail)

			props.history.push({ pathname:props.history.location.pathname, search:params.toString()})
			setCurrentModule(evt.detail.module)

		}
	}
	let onScrollEvent =(page)=>{
		console.log("scroll", page)

		let pos = refScroller.current.scrollLeft
		refScrollSpeed.current=refScrollLeft.current - pos
		refScrollLeft.current=pos

		let fIndex = -1
		let posX = pos ;

		
		Array.from(refScrollerContainer.current.childNodes).forEach((ele,index)=>{

			if(Math.abs(refScrollSpeed.current) < 100 &&( (
				refDimensionCache.current[index].left + refDimensionCache.current[index].width > posX &&
				refDimensionCache.current[index].left <= posX + refEle.current.clientWidth
				) )
			){
				if(refScrollTable.current[index]){ refScrollTable.current[index].call(this,pos,refScroller.current.clientWidth)}

				// console.log("in "+index)
				 //top menu bar
				 if( refDimensionCache.current[index].left + refDimensionCache.current[index].width > posX+ refEle.current.clientWidth/2 &&
					refDimensionCache.current[index].left <= posX + refEle.current.clientWidth/2){

				if(fIndex===-1 && currentPage!==index){
					let eleSelected = refTopBar.current.querySelector("[index=\""+refCurrentPage.current+"\"]")
					if(eleSelected) eleSelected.setAttribute('active','false')
					// currentPage= index
					setCurrentPage(index)
					eleSelected = refTopBar.current.querySelector("[index=\""+refCurrentPage.current+"\"]")
					if(eleSelected)eleSelected.setAttribute('active','true')

				}
				fIndex = index
				}

			}
		})


	}

	async function loadModules(arr){

		if(!arr || arr.length===0) return[]
		let proms= []
		let local = false;
		if (Globals.instance().downloaded.indexOf(props.nid) !== -1) {
			local = true
		}
		arr.forEach(async obj =>{ proms.push(fetchCMSJSON(obj,null,null,null,local))})

		return new Promise( (resolve,reject) => {

			Promise.all(proms).then((values)=>{ resolve(values) }).catch((err)=>{
				reject(err)
			})

		})
	}


	useEffect(() => {
		if (flatMenuItems && NarrativeModel && PageModels) {
			// console.log('Arrow Key Listener Started')
			refKeyListenerUp.current=(evt)=>{
				if (evt.code === "ArrowLeft"){
					let index = parseInt(currentPage)-1;
					let pageObj = flatMenuItems[index];
					if (pageObj) {
						onScrollToEvent(pageObj.page, NarrativeModel.transition)
						setCurrentPage(index)
						// getPageAndTransition(index, pageObj.page).then(() => {
						// 	pageTransition(index, "fade")
						// });
						console.log('ArrowLeft', pageObj.page, index)
					}
				}
				else if (evt.code === "ArrowRight") {
					let index = parseInt(currentPage)+1;
					let pageObj = flatMenuItems[index];
					if (pageObj) {
						onScrollToEvent(pageObj.page, NarrativeModel.transition)
						setCurrentPage(index)
						// getPageAndTransition(index, pageObj.page).then(() => {
						// 	pageTransition(index, "fade")
						// });
						console.log('ArrowRight', pageObj.page, index)
					}
				}
			}
	
			document.addEventListener('keydown',refKeyListenerDown.current)
			document.addEventListener('keyup',refKeyListenerUp.current)
		}
		return () => {
			// console.log('Arrow Key Listener Removed')
			document.removeEventListener('keyup',refKeyListenerUp.current)
			document.removeEventListener('keydown',refKeyListenerDown.current)
		}
	}, [flatMenuItems, NarrativeModel, PageModels, currentPage])

	//on load highlight top bar
	useEffect(()=>{
		let eleSelected = refTopBar.current.querySelector("[index=\""+refCurrentPage.current+"\"]")
		if(eleSelected) eleSelected.setAttribute('active','true')
	},[loaded])

   async function onPageLoad(index,ele){
	

		!firstLoad && console.log('onPageLoad', index, ele)

		refChildrenPages.current[index]=ele
		// Interaction.observe(ele)
		PageLoadCount.current = PageLoadCount.current +1
		let per = PageLoadCount.current/PageModels.length*100

		if (per > 100){
			per = 100
		}

		gsap.to(refLoadPercent.current,{ease:Power2.easeOut,duration:0.5,percent:per,onUpdate:()=>{
			setStatus(`${Math.floor(refLoadPercent.current.percent)}%`)
		}})


		if(PageLoadCount.current === PageModels.length){

			// console.log('onPage listener setups')

		  //load modules
		  gsap.killTweensOf(refLoadPercent.current)
		  setStatus('Starting')
		  let mods=[]
		  try{
			 mods = await loadModules(NarrativeModel.modules)
			 setModules(mods)
		  }catch(err){
			console.error(err)
			Globals.instance().showAlert("Error","Could not load narrative module")
		  }

		  pageTableLeft.current=  new Array(PageModels.length)


		let listenerPopup = (evt=>{
			if(evt.detail.typeName==="module"){
				let m = Object.assign({},evt.detail)
				let mode = mods.find(o=>{ return o.id===evt.detail.module})
				 m = Object.assign(m,mode)
				setCurrentModule(m)
			}
			else if(evt.detail.typeName==="image"){
				setCurrentModule(evt.detail)
			}else if(evt.detail.typeName==="video"){
				setCurrentModule(evt.detail)
			}
		})

		try {
			refChildrenPages.current.forEach((page,i)=>{
				pageTableLeft.current[i]=page.offsetLeft
				pageTableWidth.current[i]=page.clientWidth
			  })
		} catch (error) {
			console.log(error)
		}
		  

		//   refListeners.current.push({e:'scroll',f:onScrollEvent})
		//   refListeners.current.push({e:'wheel',f:onWheel})
		if (refListeners.current) {
			if(navigator.maxTouchPoints > 0)
		  refListeners.current.push({e:'touchstart',f:onDown})
		  else
		  refListeners.current.push({e:'mousedown',f:onDown})
		  refListeners.current.push({e:'narrative-pop-up',f:listenerPopup})

		  refListeners.current.forEach((l)=>{
			refScroller.current.addEventListener(l.e,l.f,{passive:false})
		  })
		}
		  


		  refTotalWidth.current = refScrollerContainer.current.clientWidth

		  Array.from(refScrollerContainer.current.childNodes).forEach((ele)=>{

			 refDimensionCache.current.push({left:ele.offsetLeft,width:ele.clientWidth})
		  })

		//   let initScrollPos =0
		  if(refInitialSlide.current)
		  {
	
			let pEle = refScrollerContainer.current.querySelector(".Page[slideid=\""+refInitialSlide.current+"\"]")
			let pageId = parseInt(refInitialSlide.current);
			let index = pEle.getAttribute("index");
			if (pEle) {
				refInitialSlide.current = null
		
				// TURNED OFF BC IT CAUSED THE FIRST PAGE NOT TO ANIMATE IN RECEIVE MODE
				if (props.socketPrefs && props.socketPrefs.room) {
					setActivePage(pageId)
					setCurrentPage(index)
				} else {
					getPageAndTransition(index, pageId).then(() => {
						pageTransition(index, "fade")
					});
				}
			

			
			

			}


		  }else{
			Array.from(refScrollerContainer.current.childNodes).forEach((ele,index)=>{
				if(refScrollTable.current[index]){ refScrollTable.current[index].call(this,0,refScroller.current.clientWidth)}
				})
		  }

			
		  onScrollEvent()


		  {
			let eleT =document.getElementById('narrative-transition')
			  if(eleT){  eleT.remove() }
		  }

		  /* Removed because of module loading on deep linking
		  ? check if any issues
		  if(refEle.current)refEle.current.style.display='none'

		  */
		  if(refEle.current)
				refEle.current.style.visibility='visible'

		try {
			var p = new Promise(resolve=>{

				gsap.to(refLoaderContainer.current.querySelector('.loader-container svg'),{duration:0.25,scale:0.75,ease:Power3.easeInOut,opacity:0})
				gsap.to(refLoaderContainer.current,{duration:0.5,ease:Power3.easeInOut,opacity:0,onComplete:(r)=>{
				  r()
				},onCompleteParams:[resolve]})

			  })
			  await p ;	
		} catch (error) {
			// console.log('pass loader')
		}

		  
		setLoaded(true)

		 

		  if(refMenu.current ){
			refMenu.current.style.visibility='visible'
			let eleVid=refMenu.current.querySelector('video')
			if(eleVid){
							eleVid.currentTime=0
							eleVid.pause()
						}
			}

		  if(refScroller.current) refScroller.current.style.visibility='visible'

		  if(props.history.location.pathname.indexOf('/narrative')===0){
			if(refEle.current){
				refEle.current.style.display='block'
				refEle.current.style['background-color']='var(--primary-background)'
			}

			gsap.from(refEle.current,{opacity:0,delay:0, duration:1.25, ease:Power3.easeInOut,onComplete:()=>{
				if( new URLSearchParams(props.history.location.search).get('nav')==='closed')observeElements()

				if(refMenu.current)
				{
					let eleVid=refMenu.current.querySelector('video')
					if(eleVid){
						setTimeout(()=>{
							eleVid.currentTime=0
							try{
								let p=  eleVid.play()
								if(p!==undefined){
									p.then(function() {
										// Automatic playback started!
									  }).catch(function(error) {
										console.warn('MENU Video '+error)
									  });
								}
							}catch(e){
								console.error(e);
							}


					},3000)
					}
				}
				window.dispatchEvent(new CustomEvent('hide-initial-bg'))
			}})
		  }
		  else{

			//create bands for animation
			 let divAnimBand=document.createElement('div')
				divAnimBand.style.marginRight=getComputedStyle(divAnimBand).getPropertyValue('margin-right')
				if(refEle.current)refEle.current.style.display='block'
				if(refScroller.current)refScroller.current.style.display='none'

				gsap.to(divAnimBand,{css:{'left':'-120%'}, duration:1.2*1.25, ease:Power1.easeInOut,onComplete:()=>{
					if(props.nid){
						props.history.push('/narrative/'+props.nid)
					}
					if(divAnimBand)divAnimBand.remove()
					if(refScroller.current)refScroller.current.style.display='block'
					setTimeout(()=>{
					if(refMenu.current ){
						let eleVid=refMenu.current.querySelector('video')
						if(eleVid){
							eleVid.currentTime=0
							let p= eleVid.play()
							p.then(function() {
								// Automatic playback started!
							  }).catch(function(error) {
								console.warn('MENU Video '+error)
							  });
						}
					}
					},300)
				}})

				gsap.from(refEle.current,{css:{'left':'130%'}, duration:0.92*1.25, delay:0.05*1.25, ease:Power2.easeInOut,onComplete:(rel)=>{
					rel.style['background-color']='var(--primary-background)'
				},onCompleteParams:[refEle.current]})
				
		  }

		  setupServerComs(mods);

		  Globals.instance().trackPage(NarrativeModel.heading+'-'+nid)


	   }//if all pages loaded

   } //end on Page Load

//    useEffect(()=>{console.log('mod',currentModule)},[currentModule])


   /*  Listening in Server  Mode Only */

   function setupServerComs(mods){

	if(Globals.instance().serverApp || (props.socketPrefs && props.socketPrefs.mode === "isReceive")){

		setComsReady(true);

		console.log("setupServerComs", mods, props.socketPrefs)

		window.removeEventListener('server-event',refServerAppListener.current)
		refServerAppListener.current=(evt)=>{
			let detail = evt.detail
			if(!detail)return
			console.log(detail.event,detail.command)
			if(detail.event==='narrative-module'){
				console.log('narrative-module received', detail.command, mods)
				setCurrentModule(null)
				let params = new URLSearchParams(props.history.location.search)
				params.delete("slide")
				params.delete("category")
				params.delete("file")

				console.log('params at event', params.toString())

				if(detail.command!=="") selectModule({detail:{module:mods[Number(detail.command)], params: params}})
				// }
			}
			if(detail.event==='narrative-module-id'){
				console.log('narrative-module-id received', detail.command, mods)
				setCurrentModule(null)
				let linkPath = detail.command.split('#link')
				let params = new URLSearchParams(props.history.location.search)
				let moduleId = params.get('module');
				let file = params.get('file');
				let category = params.get('category');
				let newParams = "&file=" + file + "&category=" + category
				
				let moduleIndex = mods.findIndex(md=>{return md.id === moduleId})
				// params.delete("slide")
				// params.delete("category")
				// params.delete("file")

				console.log('narrative-module-id finished', params.toString(), moduleId, moduleIndex, mods[Number(moduleIndex)])

				if(detail.command!=="") selectModule({detail:{module:mods[Number(moduleIndex)], params: params}})
				// }
			}
			if(detail.event==='narrative-module-cta'){
				console.log('narrative-module received', detail.command, mods)
				setCurrentModule(null)
				let params = new URLSearchParams(props.history.location.search)
				params.delete("slide")
				console.log('params at event', params.toString())
				if(detail.command!=="") selectModule({detail:{module:mods[Number(detail.command)], params: params}})
				// }
			}
			else if(detail.event==='narrative-menu-position'){
				observeElements()
				gsap.to(refMenu.current,{duration:1,ease:Power3.easeInOut,opacity:0,onComplete:(ele)=>{ele.style.display='none'},onCompleteParams:[refMenu.current]})
			}
			else if(detail.event==='narrative-menu-open'){

				console.log('setOpenMenu', detail.command)

				if (detail.command === "true") {

					setOpenMenu(true)
				} else {
					setOpenMenu(false)
				}


				// setOpenMenu(openMenu => !openMenu);

			}
			else if(detail.event==='narrative-menu-open-full'){
				setOpenMenuFull(true);
			
				console.log('narrative-menu-open-full, received2')
				refMenu.current.style.removeProperty('display');
				gsap.to(refMenu.current,{duration:1,ease:Power3.easeInOut,opacity:1, delay:0.2, onCompleteParams:[refMenu.current]})

				if (props.socket && props.socketPrefs && props.socketPrefs.mode === 'isReceive') {

					props.socket.emit('handshake', [props.socketPrefs.room, props.socket.id, 'narrative-menu-open?open=full']);
					console.log('page handshake sent', 'narrative-menu-open?=open=full')

				}
			}
			else if(detail.event==='narrative-menu-link'){
				console.log('narrative-menu-link recd')
				setOpenMenu(false)
			
				// console.log(currentModule,refModule.current)
				let parts=detail.command.split(",")
				let pageid=parts[0]
				// console.log(parts)
				// let type = parts.length>1?parts[1]:'fade'
				// let type = 'fade'
				// if(refModule.current){ refModule.current.closeModule()}
				// let pEle = refScrollerContainer.current.querySelector(".Page[slideid=\""+pageid+"\"]")

				console.log('currentModule', currentModule)
				if(pageid) {
					setCurrentModule(null);
					// let params = new URLSearchParams(props.history.location.search)
					// let moduleId = params.get('module');

					// if (moduleId) {
					// 	let newParams = "?slide=" + pageid
					// 	selectModule({detail:{module:mods[-1], params: newParams}})
					// }

					onScrollToEvent(pageid, null)
			
				
					
				}
					// let index =pEle.getAttribute("index")
					// scrollToPage(index,type)
					


			}
			else if(detail.event==='narrative-close-module'){
				// if(refModule.current){ refModule.current.closeModule()}
				console.log('narrative-close-module received')
				let params = new URLSearchParams(props.history.location.search)
				params.delete("module")
				params.delete("file")
				params.delete("group")
				params.delete("layer")
				params.delete("category")
				params.delete("links")
				params.delete("cta")
				props.history.push({pathname:props.history.location.pathname,search:params.toString()})
				setCurrentModule(null)

				if (props.socket && props.socketPrefs && props.socketPrefs.mode) {

					props.socket.emit('handshake', [props.socketPrefs.room, props.socket.id, 'narrative-close-module']);
					console.log('page handshake sent', 'narrative-close-module')

				}


			}
			else if(detail.event==='narrative-scroll'){
				//let parts=detail.command.split(",")
				// console.log(detail.command)
				// console.log(dir,refScroller.current.scrollLeft)
				let pindex = Number(currentPage)
				let ele = refScrollerContainer.current.querySelector(".Page[index=\""+pindex+"\"]")
				let left = refScroller.current.scrollLeft
				// let rEle = ele.getBoundingClientRect()
				let leftSide =ele.offsetLeft
				let rightSide= ele.offsetLeft+ele.clientWidth

				const screenWidth= refScroller.current.clientWidth

				if(detail.command==='left'){
					if(leftSide < left){

						refScrolltween.current = gsap.to(refScroller.current,{duration:1.5, scrollTo:{x:leftSide},ease:Power3.easeInOut,onComplete:(fscroll)=>{
							fscroll()

						},onCompleteParams:[onScrollEvent]})
					}
				}else{
					if(left + screenWidth < rightSide){

						refScrolltween.current = gsap.to(refScroller.current,{duration:1.5, scrollTo:{x:rightSide-screenWidth},ease:Power3.easeInOut,onComplete:(fscroll)=>{
							fscroll()

						},onCompleteParams:[onScrollEvent]})
					}
				}

			}

		}

		window.addEventListener('server-event', refServerAppListener.current)
	}
   }

   //initiate interaction observers
   function observeElements(){

		// console.log('%cobserveElements start', "color:green")

	   if(firstObservered) return
	   if(refChildrenPages.current)
		refChildrenPages.current.forEach(ele=>{
			refInteraction.current.observe(ele)
			firstObservered=true
		})

		// console.log('%cobserveElements stop', "color:red")
   }

   function onPageChange(pageId){
	console.log('onPageChange', pageId)

	let params = new URLSearchParams(props.history.location.search)
	params.set('slide', pageId)
	setActivePage(pageId)
	// console.log('setActivePage 3')
	// console.log(history.location.pathname,props.history)
	props.history.push({
		pathname:props.history.location.pathname,
		search:params.toString()
	})
		
   }

   function onIntersect(pageId){
		// console.log('onIntersect PageId', pageId, refInitialSlide.current)
		
			clearTimeout(timerSearchParams.current)
				timerSearchParams.current = setTimeout(()=>{
					
						// onPageChange(pageId)
						console.log('onPageChange 1')
				
					
				},500)
	
		
		
	
   }

   function onFirstClose(){

		let prefNavBox = Globals.instance().prefNavBox;
		// console.log('prefNavBox', prefNavBox)
		if (prefNavBox === false) {
			setShowNarrativeControls(false);
		} else {
			if(!Globals.instance().ipad)
			setShowNarrativeControls(true);
		}

	   if(loaded) observeElements()
   }
   const onWheel=(evt)=>{
		// refScroller.current.scrollLeft += evt.deltaY/5;

		// onScrollEvent()

   }
//    let timeTarget=0;
   let timeMouse=0;
   let xVel=0
   let overlapPnt = 0
   let underlapPnt = 0
   let initPage = 0
   let pEle = null
   let count = 0


	const onDown=(evt)=>{

		if(refScrolltween.current) refScrolltween.current.kill()
		// console.log(evt)

		let posX=evt.touches?evt.touches[0].clientX:evt.clientX
		const screenWidth=refScroller.current.clientWidth
		refDown.current=true
		cancelAnimationFrame(timerScroller.current)
		refScrollVel.current=0
		// evt.preventDefault()
		if(refScrolltween.current)refScrolltween.current.kill()


		initPage = Number(currentPage)
		// console.log('initpage'+initPage)
		pEle = refScrollerContainer.current.querySelector(".Page[index=\""+initPage+"\"]")
		let rect =pEle.getBoundingClientRect()
		overlapPnt = pEle.offsetLeft + rect.width
		underlapPnt = pEle.offsetLeft

		timeMouse = Date.now()
		xVel=0
		count=0


		if(!refScrollPos.current.style.left)refScrollPos.current.style.left='0px'

	}

	useEffect(() => {
		// console.log('activePage', activePage)
	}, [activePage])

	useEffect(()=>{

        if( modules){ 
             let ps=[]
             ps.push(getModuleIcon({'icon': "", 'title': NarrativeModel.heading, 'typeName': 'narrative'}, theme, 10))
            modules.forEach((val,index)=>{
                 ps.push(getModuleIcon(val,theme,index))
             })
            
             Promise.all(ps).then(vals=>{ 
                 setIcons(vals)
             })
         }
     },[modules, theme])

	function getActivePage() {
		return activePage
	}

	function onClickModule(index, fromModule, slideId){
        // let index = evt.currentTarget.getAttribute("index")
        console.log("onClickModule", index, fromModule,slideId)
        if (index > -1) {
            selectModule(new CustomEvent('select-module',{detail:{index:index, module:modules[index]}}))
            console.log('REMOTE: select module', props.socketPrefs.room, modules[index].id, props.socketPrefs && props.socketPrefs.mode === "isBroadcast")
    
            if (props.socketPrefs && props.socketPrefs.mode === "isBroadcast") {
                props.socket.emit('cast', [props.socketPrefs.room, 'select-module?module=' + index]);
            }

        } else {
			if (fromModule) {
				setCurrentModule(null)
				
				let index = 0
				if (slideId) {
			
					let pageIndex = flatMenuItems.findIndex(o => o.page === parseInt(slideId))
					let page = slideId;
					// const page = NarrativeModel.menu_links[pageIndex].page;
					onScrollEvent(page, 'fade')
					Globals.instance().trackEvent('click-link',{ 'event_category':'tools'})
					console.log('fromModule', pageIndex, index, page)
					if (props.socketPrefs && props.socketPrefs.mode === "isBroadcast") {
						// props.socket.emit('cast', [props.socketPrefs.room, 'select-module?module=-1&page=' + page]);
						props.socket.emit('cast', [props.socketPrefs.room, 'narrative-menu-link?page=' + page + '&origin=/narrative/' + props.nid]);
						
					}
					
					setCurrentPage(pageIndex)
					console.log("onClickLink: pageId, pageIndex", page, pageIndex)
					gsap.to(refNarrativeControls.current, {opacity: 1})
					setOpenMenu(false)	

				}
				
			}
			
		}
    }



	return  (
		<div className={`fullscreen narrative-holder`} >
			<div className={`fullscreen ${styles.narrativeContainer}`} ref={refEle}>

				<div className={`${styles.container} fullscreen hide-scroller  force3d ${loaded?'narrative_loaded':'false'}` } ref={refScroller}  >
				<div className={`${styles.scrollPosition}`} ref={refScrollPos} style={{left:'12px'}} >
		
				</div>
					<div className={`${styles.containerScroll} force3d`} ref={refScrollerContainer}>
					{
						PageModels && flatMenuItems &&  (
							PageModels.map((val,index)=>{
								return (
									<Page key={`page-${NarrativeModel.id}-${index}-${val.loaded}-${val.components_length}` }
									id={`${NarrativeModel.id}-${index}`}
									index={index}
									model={val}
									onLoad={onPageLoad}
									onScrollEvent={onScrollEvent}
									interaction={refInteraction.current}
									theme={theme}
									onIntersect={onIntersect}
									nid={NarrativeModel.id}
									flatMenuItems={flatMenuItems}
									PageModels={PageModels}
									setScrollCallback={setScrollTable}
									activePage={getActivePage}
									socket={props.socket}
									socketPrefs={props.socketPrefs}
									></Page>
								)
							})
						)
					}
					</div>



				</div>
				<div className={`${styles.topBarHolder}`} ref={refTopBar} loaded={loaded?'true':'false'}>
					{ loaded && flatMenuItems.map((obj, i)=>(<div className={`${styles.bar} ${i===parseInt(currentPage)?styles.barOn:""}`} index={i} active={i===parseInt(currentPage)?"true":"false"}
						style={Globals.instance().getThemeStyle(['top-bar'],theme)}
						key={"navbar-"+currentPage+"-"+i} onClick={() => onScrollToEvent(obj.page, NarrativeModel.transition)}
					></div>)
					
					)}
				
				</div>
				{<NarrativeMenu

					history={props.history}
					model={NarrativeModel}
					modules={modules}
					ref={refMenu}
					theme={theme}
					onFirstClose={onFirstClose}
					menuItems={menuItems}
					PageModels={PageModels}
					flatMenuItems={flatMenuItems}
					setShowDrawControls={setShowDrawControls}
					setShowControls={setShowNarrativeControls}
					setShowMarkup={setShowMarkup}
					onScrollEvent={onScrollToEvent}
					onSelectModule={selectModule}
					openMenuFull={openMenuFull}
					setOpenMenuFull={setOpenMenuFull}
					user={props.user}
					setCurrentPage={setCurrentPage}
					activePage={activePage}
					pageIndex={currentPage && currentPage}
					socket={props.socket}
					socketPrefs={props.socketPrefs}
					openMenu={openMenu}
					menuEle={refNarrativeControls.current}
					onLanding={onLanding}
					setOnLanding={setOnLanding}
					icons={icons}
					onClickModule={onClickModule}
					></NarrativeMenu>
				}
				{  showNarrativeControls &&
					<NarrativeControls
					scrollOffset={scrollOffset}
					ref={refNarrativeControls}
					modules={modules}
					setShowControls={setShowNarrativeControls}
					model={NarrativeModel}
					menuItems={menuItems}
					flatMenuItems={flatMenuItems}
					onScrollEvent={onScrollToEvent}
					onSelectModule={selectModule}
					key={'draw-controls-'+nid}
					setCurrentPage={setCurrentPage}
					pageIndex={currentPage}
					socket={props.socket}
					socketPrefs={props.socketPrefs}
					openMenu={openMenu}
					>
					</NarrativeControls>
				}


			</div>
			{

				currentModule && (
					<ModuleContainer
						module={currentModule}
						history={props.history}
						theme={theme}
						nid={nid}
						index={modules.findIndex(o => o.id === currentModule.id)}
						logo={NarrativeModel && NarrativeModel.menu_logo ? NarrativeModel.menu_logo : props.user && props.user.org && props.user.org.logo}
						username={props.user && props.user.username}
						category={props.user && props.user.category}
						market={props.user && props.user.market}
						setShowDrawControls={setShowDrawControls}
						setShowMarkup={setShowMarkup}
						socket={props.socket}
						socketPrefs={props.socketPrefs}
						icons={icons}
						onClickModule={onClickModule}
						closeModule={()=>{
						let params = new URLSearchParams(props.history.location.search)
						params.delete("module")
						params.delete("file")
						params.delete("group")
						params.delete("layer")
						params.delete("category")
						params.delete("links")
						params.delete("cta")
						props.history.push({pathname:props.history.location.pathname,search:params.toString()})

						setCurrentModule(null)

					}}></ModuleContainer>
				)
			}

			{
				showMarkup &&
					<Markup  setShowMarkup={setShowMarkup} nid={nid} history={props.history}></Markup>
			}

			{
				showDrawControls &&
					<DrawControls  setShowDrawControls={setShowDrawControls}  ></DrawControls>
			}

	
			{nid && !Globals.instance().controllerApp && !Globals.instance().serverApp && props.user && props.user.category !== "anon" && props.user.org.broadcast && onLanding && <StartMeeting loaded={loaded} user={props.user} setSocketPrefs={props.setSocketPrefs} socketPrefs={props.socketPrefs} socket={props.socket} nid={nid} />}
	
			{ !loaded && (
				<div className={`fullscreen fcenter ${styles.loaderBG}`} ref={refLoaderContainer}>
					<div className={`fullscreen fcenter`} style={{zIndex:1000}} >
						<LoaderWheel status={'Loading'} ></LoaderWheel>
					</div>
				</div>
			)}



		</div>
	)
}

const mapStateToProps = state => {
	const {narratives,themes,user} = state
	return { narratives:narratives,themes,user};
};

export default connect(mapStateToProps, null)(memo(Narrative,(prev,next)=>{
	return true
}))