import React,{useEffect,useState,useRef} from 'react'
import Globals from 'system/Globals'
import styles from './webgl.module.scss'
import galleryStyles from '../gallery/gallery.module.css';
import {ReactComponent as Chevron} from 'assets/icons/caret_down.svg'


function Menu (props){

    const [views,setViews]=useState([])
    const [category,setCategory]=useState(0)
    const [menuOpen , setMenuOpen] =  useState(false)
    const refMenuChoices=useRef()

    useEffect(()=>{
        if(props.menuViews){
            setViews(props.menuViews[Object.keys(props.menuViews)[0]]);
            setCategory(Object.keys(props.menuViews)[0])
            if(refMenuChoices.current)
            refMenuChoices.current.style.display='none'
        }
    },[props.menuViews])
    
    useEffect(()=>{
        if(refMenuChoices.current){
        refMenuChoices.current.style.height=menuOpen?'auto':'0px'
        refMenuChoices.current.style.opacity=menuOpen?1:0
        refMenuChoices.current.style.display=menuOpen?'block':'none'
        }
    },[menuOpen])

    function handleMenu(){  setMenuOpen(!menuOpen)}

    let  handleCategory=React.useCallback((c)=>{
        setCategory(c)
        setMenuOpen(false)
        if(c !== category){
            setViews(props.menuViews[c])
            props.onCategory(c)
        }
    },[category, props])

    function getViewMenu(){
        if(!props.menuViews) return
        let keys =Object.keys(props.menuViews)
        // console.log(keys,menuOpen)
        if(keys.length<=1){
            return (
                <div className={`${galleryStyles.rightNavMenuNav} ${menuOpen?'menuOn':''}` }  >
                <div 
                className={`${galleryStyles.dropdownHeading}`}
                style={(menuOpen)?(Globals.instance().getThemeStyle(['module-heading-bg-on'],props.theme)):(Globals.instance().getThemeStyle(['module-heading-bg'],props.theme))}
                >
                <span
                className={`${galleryStyles.moduleHeading}`}
                style={Globals.instance().getThemeStyle(['module-heading'],props.theme)}
                >{category}</span></div></div>)

        }else{
            return (
                
                <nav id="rightNavMenu" 
                    className={galleryStyles.rightNavMenuNav}
                    >
                            
                <div 
                    className={`${galleryStyles.dropdownHeading}`}
                    style={Object.assign({backgroundColor:'transparent'},(menuOpen)?(Globals.instance().getThemeStyle(['module-heading-bg-on'],props.theme)):(Globals.instance().getThemeStyle(['module-heading-bg'],props.theme)))}
                     onClick={() => handleMenu()} >
                        <span
                        className={`${galleryStyles.moduleHeading}`}
                        style={Globals.instance().getThemeStyle(['module-heading'],props.theme)}
                        >
                        <Chevron id={galleryStyles.dropDownChevron} style={{transform:'rotate('+(menuOpen?180:0)+'deg)'}}></Chevron>
                        {category}
                        </span>
                </div>
                    
                <div className={`${galleryStyles.dropdownChoices} ${styles.dropdownChoices} `} style={Object.assign({}, Globals.instance().getThemeStyle(['module-text'],props.theme), Globals.instance().getThemeStyle(['module-dropdown-bg'],props.theme))} ref={refMenuChoices}>
                    
                    {  keys.map((c,index)=>{
                        return <div 
                        className={`${galleryStyles.dropdownChoice} ${styles.dropdownChoice} ${(category === c)?(galleryStyles.choiceOn):("")}`}
                        key={'webgl_menu-category_' + index}
                        style={Globals.instance().getThemeStyle(['module-dropdown'],props.theme)}
                        onClick={() => handleCategory(c)}
                        >
                        {c}
                        </div>
                    })}
                </div>
                
                </nav> 
            )
        }
    }
    return (

        <div id="rightNav" className={`${galleryStyles.rightNavMenu} ${(menuOpen)?(galleryStyles.menuOn):("") } ${(props.navOpen)?(galleryStyles.navOpen):("")}`} style={Globals.instance().getThemeStyle(['module-drawer-bg'],props.theme)}>
                
                    { getViewMenu()}
                    
                        <div className={`${styles.itemsContainer} hide-scroller`}>
                            { views.map(  (v,index)=>{
                                 return(<div key={`viewmenu${category}-${index}`} className={`${styles.viewMenuItem} ${props.selectedItem===index?'selected':''}`} onClick={props.onClickView} index={index} category={category} >{v.title?v.title:v.name}</div>)
                            })
                        }
                        </div>

            </div>
    )



}

export default Menu