import CamManager from './CamManager'


export default class SixSixtyCamManager extends CamManager{

    // constructor(parent){
    //     super(parent)
    // }

    animateView(obj){
        super.animateView(obj)
        if(this.p.signage)
        this.p.signage.visible=obj.name==='Top of House Signage'
        if(this.p.lobby)
        this.p.lobby.visible=obj.name==='Private Entrance'
        
    }
}