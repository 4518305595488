import {Link} from 'react-router-dom'
import { useHistory } from "react-router-dom";

// import { useHistory } from "react-router-dom";
function Denied(props){
    return( 
        
        <div className="fullscreen denied-container loader-status"  style={{color:'var(--primary)'}}>
            
            <div style={{width:'300px', textAlign:'left'}}>
                <strong>Access Denied</strong>
                <br></br>
                <br></br>
                {  props.history && props.history.length > 1 && (
                <Link onClick={()=>{ props.history.goBack() }}>Back</Link> 
                )} &nbsp;
                <Link to={{pathname:'/'}}>Home</Link>
            </div>
        </div>
    )
}
 
export default Denied