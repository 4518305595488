import React,{useState,useRef,memo,useCallback,useEffect} from 'react'
import {animateParallax,getAnimationModel,animateIntersect,setInitialAnimIntersectState,setFinalAnimIntersectState} from './CanvasAnimator'
import Globals from 'system/Globals'
import history from 'system/navigation/MainRouterHistory'
import CharStyleOverrides from 'system/components/CharStyleOverrides'
// import {onErrorLoad} from 'system/AssetManager'

function TextComponent(props){
    
    
    const [inline,setInline] = useState({})
    const [text,setText] = useState(0)
    const [textCss, setTextCss] = useState({});
     
    let bLoaded=false
    const refEle = useRef()
    const refFormat = useRef()
    const refOrigOffset = useRef(0)
    const refAnimationModel = useRef({})

    function onLoad(){
        // console.log('onLoad Text')
        if(!bLoaded){
            props.onLoad(props.index,refEle.current,props.model)
            bLoaded=true

            if(props.renderOnly===true){
                refAnimationModel.current = getAnimationModel(refEle.current,refFormat.current.animation) 
                setFinalAnimIntersectState(refEle.current,refAnimationModel.current)
            }

         

        }
        
    }
    

    useEffect(()=>{ 
        refOrigOffset.current=refEle.current.offsetLeft
        if(refFormat.current && refFormat.current.animation && refFormat.current.animation.typeName){
            
            refAnimationModel.current = getAnimationModel(refEle.current,refFormat.current.animation) 
            
            console.log("inline text", refFormat.current.animation)

            if(props.renderOnly===true){
                setFinalAnimIntersectState(refEle.current,refFormat.current.animation)
            }
        }
        
    },[inline]) // eslint-disable-line react-hooks/exhaustive-deps
    
    
    const onScroll = useCallback((scrollLeft,scale,pageWidth)=>{
        // console.log(refAnimationModel.current)
        animateParallax(refEle.current,refAnimationModel.current,scrollLeft,pageWidth)
    },[props.model.animation,refEle])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // console.log('props.pageState', props.pageState)
        
        if (props.pageState === "animPage" && inline && refAnimationModel.current && refAnimationModel.current.animations) {
            // console.log('props.pageState', props.pageState)

            animateIntersect(refEle.current,refAnimationModel.current)
        }
    }, [props, inline])

    const onIntersect =  useCallback((evt,threshold)=>{
        
        // console.log('Text onINTERSECT', evt)
        // console.log('TEXT onIntersect', evt.detail.intersectionRatio, threshold)

        if(evt.detail.isIntersecting )
        {
            if(evt.detail.intersectionRatio >=threshold)
                animateIntersect(refEle.current,refAnimationModel.current)
              

        }
        else if(evt.detail.intersectionRatio <=0.001)
            setInitialAnimIntersectState(refEle.current,refAnimationModel.current)
    },[refEle])

    useEffect(() => {

        if (text && textCss && props) {
            onLoad()

            
        }
    }, [text, textCss, props])

    useEffect(()=>{

        
        refFormat.current = Globals.instance().getCanvasFormat(props.model.formats,props.format)

        console.log('refFormat', refFormat.current, props)
        
        let style ={position:'absolute',transform: 'translateZ(0), perspective: 1000px',willChange:'transform'}
        if(refFormat.current.style){ style = Object.assign(style,refFormat.current.style) }
        

        // console.log("seting ",getContentPath(f))

        // console.log("FORMAT", refFormat.current, props.model.formats,props.format)
        if (refFormat.current.html_text['text']) {
            setText(refFormat.current.html_text)
        }
       

        function translateCss(objCss) {
            let array = Object.entries(objCss);
            let reactCss = {};
            array.forEach((obj, i) => {
              switch (obj[0]) {
                case 'font-family':
                  reactCss['fontFamily'] = obj[1];
                  break;
                case 'font-weight':
                  reactCss['fontWeight'] = obj[1];
                  break;
                case 'font-size':
                  let s = obj[1];
                  let sVal = s + "px";
                  if (props.format) {
                    s = ((s/parseInt(props.format.canvas_width.replace('px','')))*100).toFixed(2);
                    sVal = s + 'vw';
                    if (refFormat.current) {
                        let width = Number(refFormat.current.style.width.replace('%','')/100);

                        console.log('sMax', width);
                    }   
                    
                    // reactCss['fontSize'] = "clamp(" + s + "vw," + s + "vw," + sMax + "px)"
                    reactCss['fontSize'] = sVal;
                  } else {
                    reactCss['fontSize'] = sVal;
                  }
                  
                  break;
                case 'text-align':
                  reactCss['textAlign'] = obj[1];
                  reactCss['display'] = 'block';
                  break;
                case 'color':
                  reactCss['color'] = obj[1];
                  break;
                case 'letter-spacing':
                    reactCss['letterSpacing'] = obj[1];
                    break;
                case 'line-height':
                    reactCss['lineHeight'] = obj[1];
                    break;
                default:
                  break;
              }
            })
            
            return reactCss
          }
            
        setTextCss( translateCss(refFormat.current.html_text['css']) )
        
        //set up animation Type
        if(refFormat.current.animation ){
            if(refFormat.current.animation.typeName==='parallax'){
                props.setScrollCallback(onScroll,props.index)
                style.willChange='transform'
            } 
            else if(refFormat.current.animation.typeName==='page'){
                props.onIntersect(onIntersect,props.index)
            
                style.willChange='transform, scale, opacity'
            }
        }
        setInline(style)
    
        
        return ()=>{}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props])

    function onError(evt){
        console.error("Text load error"+ bLoaded, evt.target.src)

        if(!bLoaded){
            onLoad() //dont hold up the page 
        }
    }

    function onClick() {
        console.log('props', props.model)
        let url = new URL(window.location.href)
        let pathLink = props.model.link;
        let urlparams= new URLSearchParams(pathLink)
       
        if (pathLink && url) {

            let isExternal = pathLink.includes('http') ||  pathLink.includes('https') ||  pathLink.includes('mailto');

            console.log('Globals.instance().useHashRouter', Globals.instance().useHashRouter, isExternal)

            if((Globals.instance().useHashRouter)&&(!isExternal))
            {
                let path = url.hash.substring(1)
                let arr=path.split('?')
                let search=''
                if(path.indexOf('?')){
                    path=arr[0]
                    search=arr[1]
                    
                }
                 search=search?'&'+pathLink:pathLink +"&landing=false"

                console.log(search,url.hash.substring(1)+'&'+pathLink)
                
                history.push({pathname:path,search:'?'+search})

            }else{

               
                if (isExternal) {
                    console.log('isExternal')
                    // history.push({pathname:url.pathname, search:'?'+pathLink})  
                    if(Globals.instance().electron) {
                        window.api.send("toApp", {"event":"external-link","link":pathLink});
                        console.log('electron', pathLink)
                    } else if(Globals.instance().ios) {
                        console.log('ios', pathLink)
                        window.webkit.messageHandlers.notification.postMessage({ Object: 'external-link', 'link': pathLink})
                        
                    } else {
                        window.open(pathLink, "_blank");
                    }

                } else {
                    console.log(url.pathname, pathLink)
                    history.push({pathname:url.pathname, search:'?'+pathLink}) 
                        
                }
                
                
            }
            
            let  moduleid=urlparams.get("module")
            
            if(moduleid){

                console.log(moduleid)
                refEle.current.dispatchEvent(new CustomEvent('narrative-pop-up', { detail:{typeName:"module",module:moduleid},bubbles:true}))
            }


        }
    }
    
  
    
    return ( 
            
        <div onClick={onClick} style={inline} onError={onError} ref={refEle} className={'force3d'}>
            {text && <span style={textCss}><span dangerouslySetInnerHTML={{__html: CharStyleOverrides(text, props.format)}} /></span>}
        </div> 
    )

}

const TEXT = memo( TextComponent, (prev,next)=>{ 
    return false
})

export {TEXT}
