

import React,{useEffect,useRef,useState} from 'react'
import gsap,{Back} from 'gsap'
import styles from '../styles/switch.module.css'


const Switch =props=>{

    const ref =  useRef()
    const [selected,setSelected] = useState(props.selected?props.selected:false)
    
    function onChangeSwitch(){
       if(props.onChange) props.onChange(!selected)
       setSelected(!selected)
    }

    return (<section className={`${styles.sectionContainer} `} ref={ref} >
            <input type="checkbox" checked={selected}   className={styles.switchCheckbox1} onChange={(evt)=>{console.log(evt)}} />
            <label className={styles.switchLbl} onClick={onChangeSwitch}></label>
    </section>)

}

export default Switch