
import React,{Fragment, useEffect,useRef,useState} from 'react'
import {getContentPath} from  'system/AssetManager'
import {fetchCMSJSON,fetchCMSJSONs} from 'system/AssetManager'
import gsap,{Power2} from 'gsap'
import Globals  from 'system/Globals';
import styles from './controller-narrative.module.css'
import nstyles from '../narrative/narrative.module.scss'
import mstyles from '../narrative/modules/modules.module.css'
import NarrativeMenuItem  from 'narrative/NarrativeMenuItem'
import ControllerServerComs from 'system/ControllerServerComs'
import {ReactComponent as Chevron} from '../assets/icons/caret_down.svg'
import {ReactComponent as CloseButton} from '../assets/icons/close.svg'
import {ReactComponent as ZoomOut} from '../assets/icons/minus.svg'
import {ReactComponent as ZoomIn} from '../assets/icons/plus.svg'
import {ReactComponent as CaretRight} from '../assets/icons/caret_right.svg'
import {ReactComponent as CaretLeft} from '../assets/icons/caret_left.svg'
import {ReactComponent as CaretUp} from '../assets/icons/caret_up.svg'
import {ReactComponent as CaretDown} from '../assets/icons/caret_down.svg'


const ControllerPDF = (props)=>{

    const scrollContainer = useRef();
    const joyStickRef = useRef();
    const videoRef = useRef();
    const [menuOpen, setMenuOpen] = useState(true);
    const [selected,setSelected] = useState(null)
    const [selectedId, setSelectedId] = useState(null);
    const [styleMenu,setStyleMenu]=useState({})
    const [menuItems,setMenuItems] = useState([])
    const [styleMenuSelected,setStyleMenuSelected]=useState({})
    const [pdfItems, setPDFItems] = useState(null);
    const [activePage, setActivePage] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [pages, setPages] = useState(0);
    const [pdfPath, setPdfPath] = useState();

    function processParams(){
        let params = new URLSearchParams(props.params);
        let file0 = params.get('file');

        if (file0){
            let fileId = parseInt(file0);
            let fileIndex = pdfItems.map(function(x) {return x.id; }).indexOf(fileId);
            let catId;
            onThumbnailClick(pdfItems[fileIndex].id, pdfItems[fileIndex].fileType, "", "params", catId)
            
            let dest = document.getElementById('galleryThumb_' + fileId);
            dest.scrollIntoView()
        }   
        
        
        props.setCurrentParams(null);

    }
    
    useEffect(()=>{
     
        console.log('pdf props', props)
        
        return () => {
 
            // setSelected(0);
        }
    },[])

    useEffect(() => {
        if (props.reload > -1) {
            console.log('reload', props.reload)

            setSelected(null);
        }
        
    }, [props.reload])

    useEffect(() => {
        if (props.params && menuItems && (menuItems.length > 0)) {
            processParams();
        }
    }, [props.params, menuItems])

    useEffect(()=>{
        if (props.module.id) {
            // setSelected(0);
            // setActivePage(props.activeNarrativePageIndex);
            // props.setActiveNarrativePageIndex(null);
   

            let arr = []
            if(props.module.docs)
                arr=arr.concat(props.module.docs)
            if(props.module.screenshot && props.module.screenshot.document)
                arr.push(props.module.screenshot)
            
            setPDFItems(arr);

            let arrMenuItems=[]
            arr.forEach((obj,i)=>{
                arrMenuItems.push({label:obj.caption, 'id': i})
            })
            setMenuItems(arrMenuItems)
          
            console.log('controller gallery', props.module.docs)
            setStyleMenu(Globals.instance().getThemeStyle(['nav-menu-item'],props.theme))
            setStyleMenuSelected(Object.assign({},Globals.instance().getThemeStyle(['nav-menu-item'],props.theme),{color:'var(--primary-accent)', '--hover-color': 'var(--primary-accent)'}))
            // setCategories(props.module.mapboxes)
        } 
          // eslint-disable-next-line react-hooks/exhaustive-deps

    }, [props.module.id])




    function handleMenu(){
        if (menuOpen) {
            setMenuOpen(false)
        } else {
            setMenuOpen(true);
        }
    }

    function onThumbnailClick(path, i) {
        console.log("onThumbnailClick", path, i);
        
        setSelectedId(i);
        ControllerServerComs.instance().sendUDPFromControllerToServer('module-pdf-select-file', path);
        setPageNumber(1);
        
        
        
    }
    const onClickLink = (evt) =>{
        
        // ControllerServerComs.instance().sendUDPFromControllerToServer('module-pdf-select-file', results[0].id);
        // onThumbnailClick(results[0].id, results[0].fileType, results[0].file, 'link')

    }   

    const onClickClose = () => {
        console.log('close')

        
       
    }

   

    const onZoomOut = () => {
        // console.log('zoomOut') 
        ControllerServerComs.instance().sendUDPFromControllerToServer('module-pdf-command', "vrzoomout");
     }
 
     const onZoomIn = () => {
        //  console.log('zoomIn') 
         ControllerServerComs.instance().sendUDPFromControllerToServer('module-pdf-command', "vrzoomin");
      }

      const onPrevClick = () => {
        console.log('onPrevClick') 
        let newPageNumber = pageNumber-1;
        console.log('onClickLeft', newPageNumber)
        if ((newPageNumber) > 1) {
            setPageNumber(newPageNumber)
            ControllerServerComs.instance().sendUDPFromControllerToServer('module-pdf-command','prev');
        }
       
     }

     const onNextClick = () => {
       
        console.log('onPrevClick') 
        let newPageNumber = pageNumber+1;
        console.log('onClickLeft', newPageNumber)
        if ((newPageNumber) > 0) {
            setPageNumber(newPageNumber)
            ControllerServerComs.instance().sendUDPFromControllerToServer('module-pdf-command',"next");
        }
        
     }

     const onKeyToggle = (command) => {
        console.log('onArrow', command) 
        ControllerServerComs.instance().sendUDPFromControllerToServer('key', command);
     }

     const onClickBack = () => {
        console.log('onClickBack', props.activePage) 
        props.setCurrentModule({typeName: 'controller-narrative'});
        // props.setActiveNarrativePageIndex(activePage);
        props.setAnimInCurrentModule(false);
        ControllerServerComs.instance().sendUDPFromControllerToServer('narrative-close-module');

        console.log('props', props)
        // setSelected(null);
        // setShowLanding(true);
     }

    return (
        <React.Fragment>
            <div className={`${styles.linksContainer} ${styles.moduleLinksContainer} hide-scroller ${nstyles.galleryLinksContainer}`}>

            {!props.showModules && <span className={nstyles.switchMenu} onClick={() => props.setShowModules(showModules => !showModules)}><CaretLeft /><h4>{props.module.title}</h4></span>}
            

            <div className={`${styles.controllerLinks} hide-scroller`} >

                    {!props.showModules && menuItems && (menuItems.length > 0) && menuItems.map((val, index) => (
                 
               
                            <span 
                                key={`pdf_menu_${index}`}
                                className={styles.controllerMenuLinkRow}
                            >
                                <NarrativeMenuItem
                                model={val}
                                objId={val.id}
                                index={index}
                                onClickLink={onClickLink} 
                                onExpand={()=>{}}
                                className={nstyles.menuLink+' '+styles.controllerMenuLink+' '}
                                theme={props.theme}
                                activePage={activePage && activePage}
                                style={selected===index ? styleMenuSelected : styleMenu}
                                ></NarrativeMenuItem>

                            </span>
         
               
                    ))}
                    </div>
            
            </div>

            {
            <section ref={scrollContainer} className={`${styles.moduleHolder}`}>
                {props.showBackBtn && <div className={`${styles.backBtnRow}`}>
                    <div className={`${styles.pillBtn}`} onClick={() => onClickBack()}>Back to Presentation</div>
                </div>}


                <div className={`${styles.galleryThumbnails} ${(props.showBackBtn)?styles.hasBackBtnRow:""}`}>
                    {pdfItems && pdfItems
                        .sort((a, b) => a.ordering - b.ordering)
                        .map((file, i) => (
                        <div 
                            onClick={() => onThumbnailClick(file.document, i)}
                            key={'gallery_file_' + i}
                            id={"galleryThumb_" + i}
                            className={`${styles.galleryThumbnail} ${(selectedId === i)?"linkOn":""}`}
                        >
                        <img src={getContentPath(file.image)} alt="" />
                        {(file.caption) ? 
                            <div 
                            className={`${styles.caption}`}
                       
                            >{file.caption}</div> 
                            : null}
                        </div>
                    ))}</div>
                
                    <div className={`${styles.moduleThumbsScrollContainerGradient}`}></div>
                    <div className={`${styles.buttons}`}>
                    <div className={`${styles.pillBtns}`}>
                    <div className={`${styles.pillBtn}`} onClick={() => onPrevClick()}><CaretLeft /></div>
                    <div className={`${styles.pillBtn} ${styles.pillBtnRight}`} onClick={() => onNextClick()}><CaretRight /></div>
                    </div>
                </div>
            </section>}
            
        </React.Fragment>
    )

}

export default ControllerPDF
