// import * as THREE from  'three'
import CamManager from './CamManager'
// import gsap from 'gsap'

export default class WellsCamManager extends CamManager{


    constructor(parent){
        console.log('Wells CamManager')
        super(parent)
    }

    update(){

        super.update()

        var theta = this.p.controls.getAzimuthalAngle()+Math.PI
		
		// check if we need to fade any objects
		// if (this.currentView && this.currentView.interactiveMeshes) {
		//     Object.keys(this.currentView.interactiveMeshes).forEach((key) => {
		  
        //         const obj = this.currentView.interactiveMeshes[key];
             
		// 	  if (typeof obj.minTheta !== 'undefined' && typeof obj.maxTheta !== 'undefined') {
                 
		// 		if (this.isVisibleInRange(theta, obj.minTheta, obj.maxTheta)) {
		// 		  if (obj.mesh.material && obj.mesh.material.opacity > 0 && (typeof obj.fadeable === 'undefined' || obj.fadeable === true)) {
		// 			if(obj.mesh.userData.fadingOut !== true){
		// 				this.tweenFadeOut(obj.mesh,0.5)
		// 				obj.mesh.userData.fadingOut=true 
		// 				obj.mesh.userData.fadingIn=false
		// 			}
		// 		  }
		// 		} else if (obj.mesh.visible === false || (obj.mesh.material && obj.mesh.material.opacity < 1)) {
		// 			if(obj.mesh.userData.fadingIn !== true){
		// 				this.tweenFadeIn(obj.mesh,0.5)
		// 				obj.mesh.userData.fadingIn=true
		// 		    	obj.mesh.userData.fadingOut=false
		// 			}
		// 		}
		// 	}
		//   });
		
		// }

		// var arrSort = []

		// check if we need to fade any objects
		if (this.currentView && this.currentView.interactiveMeshes) {
		  Object.keys(this.currentView.interactiveMeshes).forEach((key) => {
		  // for (const index in this.currentView.fadeMeshes) {
			const obj = this.currentView.interactiveMeshes[key];
			
			if(obj.mesh.material.opacity>0 && obj.mesh.material.opacity<1){
			  var camPosition = this.p.camera.position.clone()
			  obj.distance = (camPosition.sub(obj.mesh.localToWorld(obj.mesh.centroidPnt.clone()))).length()
			//   arrSort.push(obj)
			}
	
			
	
			  if (typeof obj.minTheta !== 'undefined' && typeof obj.maxTheta !== 'undefined') {
				if (this.isHiddenInRange(theta, obj.minTheta, obj.maxTheta)) {
				  if (obj.mesh.material && obj.mesh.material.opacity > 0 && (typeof obj.fadeable === 'undefined' || obj.fadeable === true)) {
					this.setAnimationOnObject(obj.mesh, 'fadeOut');
				  }
				} else if (obj.mesh.visible === false || (obj.mesh.material && obj.mesh.material.opacity < 1)) {
				  this.setAnimationOnObject(obj.mesh, 'fadeIn');
				}
			}
		  });
		
		}

	}
}