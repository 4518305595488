import React,{useRef} from 'react'
import LoginForm from './components/LoginForm'
import {ReactComponent as Logo} from 'assets/brand/logo.svg'
import history from './navigation/MainRouterHistory'
import Globals from 'system/Globals'

export default function Login(props){
    const refLogin  = useRef()
    function onLogin(){

        if(props.location && props.location.state && props.location.state.referrer)
        {
            let path = props.location.state.referrer.pathname
            let search = props.location.state.referrer.search
            console.log("referer ... "+path)
            history.replace({pathname:Globals.instance().getRoute(path),search:search})
        }
        else{
            history.replace({ pathname: '/' })
        }
        
    }

return  (

    
    <div className={`fullscreen fcenter genericLogin`} style={{ background:'#1a1a1a' }}>
        <div style={{width:'302px'}}>
            <Logo className={`logo`} />
            <LoginForm onLogin={onLogin} ref={refLogin}></LoginForm>
        </div>
    </div>
)
    
}