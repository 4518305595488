
import React,{useEffect} from 'react'
import {connect} from 'react-redux'

import { useHistory } from "react-router-dom";
function Landing(props){
    const history = useHistory();

    useEffect(()=>{
        setTimeout(()=>{
            console.log('going home user:',props.user)
            history.replace({pathname: '/home'})
        },3000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return( 
        
        <div className="fullscreen"  style={{color:'var(--primary)'}}>
            Welcome 
            {
                props.user && props.user  && (
                    <span> back  
                        {props.user.username}
                    </span>
                    )
            }
        </div>
    )
}



 
const mapStateToProps = state => {
    
    const {user} = state
    return {user};
};
export default connect(mapStateToProps)(Landing)