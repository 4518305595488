import React,{useRef,useEffect,useState} from 'react'
import styles from './narrative.module.scss'

import {ReactComponent as Close} from 'assets/icons/close.svg'
import {ReactComponent as Grip} from 'assets/icons/grip.svg'
import {ReactComponent as DrawIcon} from 'assets/icons/tool_draw.svg'
import {ReactComponent as EraseIcon} from 'assets/icons/tool_erase.svg'
// import {ReactComponent as EraseIcon} from 'assets/icons/erase.svg'

import gsap from 'gsap'


const DrawControls =(props)=>{
    let refDrawControls = useRef()
    let refX=useRef(0)
    let refY=useRef(0)
    let colors = [ "#FFFFFF","#000000","#03FF00","#FF4C55","#0A00FF","#FF0000"]
    let [selectedColor,setSelectedColor]=useState("#03FF00")


   
    useEffect(()=>{
        gsap.to(refDrawControls.current,{opacity:1,duration:0.35,delay:0.5})
        gsap.from(refDrawControls.current,{scale:0.75,duration:0.35,delay:0.5})
        refCanvas.current.setAttribute("height",window.innerHeight)
        refCanvas.current.setAttribute("width",window.innerWidth)
        return ()=>{

        }
    },[refDrawControls])

    function onDown(e){
        
        if(e.touches){
            refX.current=e.touches[0].clientX
            refY.current=e.touches[0].clientY
        } else{
            refX.current=e.clientX
            refY.current=e.clientY
        }
        let onMove=(evt)=>{
            evt.preventDefault();
            
            let xpos,ypos;
            if(evt.touches){
                xpos=evt.touches[0].clientX
                ypos=evt.touches[0].clientY
            } else{
                xpos=evt.clientX
                ypos=evt.clientY
            }
            let diffX = xpos-refX.current
            let diffY = ypos-refY.current
            let style= getComputedStyle(refDrawControls.current)
            refX.current=xpos
            refY.current=ypos
            
            refDrawControls.current.style.left = (parseFloat(style.getPropertyValue('left'))+diffX)+'px'
            refDrawControls.current.style.bottom = (parseFloat(style.getPropertyValue('bottom'))-diffY)+'px'
        }
        let onUp=()=>{
            window.removeEventListener('mousemove',onMove)
            window.removeEventListener('touchmove',onMove)
            window.removeEventListener('mouseleave',onUp)
            window.removeEventListener('mouseup',onUp)
            window.removeEventListener('touchend',onUp)
            window.removeEventListener('touchcancel',onUp)
        }
        window.addEventListener('mousemove',onMove, { passive: false })
        window.addEventListener('touchmove',onMove, { passive: false })
        window.addEventListener('mouseup',onUp)
        window.addEventListener('mouseleave',onUp)
        window.addEventListener('touchend',onUp)
        window.addEventListener('touchcancel',onUp)
    }
    function onSelectColor(evt){
        setSelectedColor(colors[Number(evt.currentTarget.getAttribute("index"))])
        setCurTool("marker")
    }
    function onClickClose(){
        gsap.to(refDrawControls.current,{opacity:0,duration:0.25, onComplete:()=>{props.setShowDrawControls(false)}})
        gsap.to(refDrawControls.current,{scale:0.75,duration:0.25})
    }




     //canvas


     const refCanvas =useRef()
     const paint=useRef(false);
     const clickX =useRef([]);
     const clickY = useRef([]);
     const clickColor = useRef([]);
     const clickTool = useRef([]);
     const clickSize = useRef([]);
     const clickDrag = useRef([]);
     const [curTool,setCurTool] = useState("marker");
     
     const curSize = useRef("small");
    //  const curColor = useRef("rgb(237,0,140)");
     
 
     function addClick(x,y,dragging){
        clickX.current.push(x)
        clickY.current.push(y)
        clickTool.current.push(curTool)
        clickColor.current.push(selectedColor)
        clickSize.current.push(curSize.current)
        clickDrag.current.push(dragging)
    }

    function onClickClear(){
        clickX.current =[];
        clickY.current =[];
        clickColor.current = [];
        clickTool.current = [];
        clickSize.current = [];
        clickDrag.current = [];
        // redraw()
        const context = refCanvas.current.getContext('2d');
        context.clearRect(0, 0, refCanvas.current.width, refCanvas.current.height);
    }
    function onClickMarker(){
        setCurTool("marker")
    }
    function onClickEraser(){
        setCurTool("eraser")
        // setSelectedColor("0")
    }

    function redraw(){

        var context = refCanvas.current.getContext('2d') // Grab the 2d canvas context
        context.beginPath()
  
        var radius
        var i = 1
  
        // context.beginPath()
        context.moveTo(clickX.current[0], clickY.current[0])
  
        for (; i < clickX.current.length - 1; i++) {
          radius = 5
          context.quadraticCurveTo(clickX.current[i - 1], clickY.current[i - 1], (clickX.current[i - 1] + clickX.current[i]) / 2, (clickY.current[i - 1] + clickY.current[i]) / 2)
  
          if (clickTool.current[i] === 'eraser') {
            context.globalCompositeOperation = 'destination-out' // To erase instead of draw over with white
            context.strokeStyle = 'white'
            radius = 45
          } else {
            context.globalCompositeOperation = 'source-over'
            context.strokeStyle = clickColor.current[i]
          }
          context.lineJoin = 'round'
          context.lineWidth = radius
        }
  
        context.quadraticCurveTo(clickX.current[i - 1], clickY.current[i - 1], (clickX.current[i - 1] + clickX.current[i]) / 2, (clickY.current[i - 1] + clickY.current[i]) / 2)
        context.stroke()
        context.globalAlpha = 1 // No IE support
    }

    function onDownCanvas(e){

        const onMove=(evt)=>{

            if (paint.current === true) {
                var x
                var y
        
                if (evt.touches && e.touches.length) {
                  x = evt.touches[0].pageX
                  y = evt.touches[0].pageY
                } else {
                  x = evt.pageX
                  y = evt.pageY
                }
        
                addClick(x, y, true)
                redraw()
              }

        }
        const onUp=(evt)=>{

            evt.stopPropagation()
            paint.current = false
            redraw()

            window.removeEventListener('mousemove',onMove)
            window.removeEventListener('touchmove',onMove)
            window.removeEventListener('mouseup',onUp)
            window.removeEventListener('mouseleave',onUp)
            window.removeEventListener('touchend',onUp)
            window.removeEventListener('touchcancel',onUp)
        }

        e.stopPropagation()

        clickX.current = []
        clickY.current = []
        clickTool.current = []
        clickColor.current = []
        clickSize.current = []
        clickDrag.current = []
  
        let x
        let y
  
        if (e.touches && e.touches.length) {
          x = e.touches[0].pageX
          y = e.touches[0].pageY
        } else {
          x = e.pageX
          y = e.pageY
        }
  
        let mouseX = x
        let mouseY = y
  
        paint.current = true
        
        addClick(mouseX, mouseY, false)
        
        window.addEventListener('mousemove',onMove)
        window.addEventListener('touchmove',onMove)
        window.addEventListener('mouseup',onUp)
        window.addEventListener('mouseleave',onUp)
        window.addEventListener('touchend',onUp)
        window.addEventListener('touchcancel',onUp)
        
        redraw()
    }

     

    return (
        <React.Fragment>
            <div className={'fullscreen '} style={{zIndex:2}}>
                <canvas className={'fullscreen'} ref={refCanvas} onTouchStart={onDownCanvas} onMouseDown={onDownCanvas}  ></canvas>
            </div>
            <div className={`${styles.controlsContainer} ${ styles.drawControlsContainer}`} onMouseDown={onDown} onTouchStart={onDown} ref={refDrawControls} onContextMenu={evt=>{evt.preventDefault();}}>
                <div className={`${styles.controlsTop}`} > <Close onClick={onClickClose} className={styles.controllsBtn}></Close></div>
                <div className={`${styles.drawControlsColorRow}`} tool={curTool} >
                    { colors.map((c,index)=>(
                        <div className={`${styles.drawControlsColor} ${selectedColor===c?'selected':''}`} style={{backgroundColor:c}} index={index} color={String(c===selectedColor)} key={'colorkey'+index} onClick={onSelectColor}></div>
                    ))}
                </div>
                <div className={`${styles.drawControlsColorRow} ${styles.drawControlsColorRowBtm}`}>
                    <div className={`${styles.controlsPill}`}>
                        <div className={`${styles.drawControlsBrushBtn} ${curTool==="marker"?"selected":""}`} onClick={onClickMarker}><DrawIcon></DrawIcon></div>
                        <div className={`${styles.separator}`}></div>
                        <div className={`${styles.drawControlsEraseBtn} ${curTool==="eraser"?"selected":""}`} onClick={onClickEraser}><EraseIcon></EraseIcon></div>
                    </div>
                    <div className={`${styles.drawControlsClearBtn}`} onClick={onClickClear}>Clear</div>
                   
                </div>
            </div>
        </React.Fragment>
    )


}

export default DrawControls