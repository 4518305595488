import DefaultProject from './default'
import * as THREE from 'three'
import WellsCamManager from './WellsCamManager'
import Utils from '../scene/Utils'
import {getAlphaFresnel,getAlphaFresnel3} from '../shaders/FresnelAlpha'

// import { FresnelShader } from 'three/examples/jsm/shaders/FresnelShader';

export default class WellsProject extends DefaultProject{

    constructor(){ 
        super()
        this.name='wells'
		console.log("Wells")
		this.skybox_folder='skybox2'
		this.colorOffsetMeshes={
			'landmarks':{
				'cultural amenities':{
					'colorOffset': 'rgb(117, 146, 172)'
				}
			},
			'transp08': {
				'brookfield dtla':{
					'colorOffset': 'rgb(117, 146, 172)'
				  }
			},
			'transp09': {
					'brookfield dtla':{
						'colorOffset': 'rgb(117, 146, 172)'
					}
			},
			"BP_buildings": {
				'brookfield dtla':{
					"colorOffset": "rgb(117, 146, 172)"
				  }
				}
			
		}
    }

	parseViews(){
		super.parseViews()
		
	}

	// getReflectionMaterial(blendAlpha,refractionRatio,power,bias,scale){
    //     const shader = FresnelShader;
    //     const uniforms = THREE.UniformsUtils.clone(shader.uniforms);
    //     uniforms.tCube.value = this.cubeRenderTarget//this.reflectionCube;
    //     uniforms.mFresnelPower.value = power//3;
    //     uniforms.mFresnelBias.value = bias///0.80;
    //     uniforms.mFresnelScale.value = scale//2.0;
    //     uniforms.mRefractionRatio.value = refractionRatio;
        
    //     const fragmentShader = [
    //       'uniform samplerCube tCube;',
    //       'varying vec3 vReflect;',
    //       'varying vec3 vRefract[3];',
    //       'varying float vReflectionFactor;',
    //       'void main() {',
    //       '	vec4 reflectedColor = textureCube( tCube, vec3( vReflect.x, vReflect.yz ) );',
    //       ' reflectedColor.r = ( reflectedColor.r * 1.0 ) + -0.25;',
    //       ' reflectedColor.g = ( reflectedColor.g * 1.0 ) + -0.25',
    //       ' reflectedColor.b = ( reflectedColor.b * 1.0 ) + -0.25;',
    //       ' reflectedColor.a = '+blendAlpha+';',
    //       '	vec4 refractedColor = vec4( 0.5 , 0.5 , 0.5 ,  '+blendAlpha+');',
    //       '	refractedColor.r = -0.25 + textureCube( tCube, vec3( vRefract[0].x, vRefract[0].yz ) ).r * 1.0;',
    //       '	refractedColor.g = -0.25 + textureCube( tCube, vec3( vRefract[1].x, vRefract[1].yz ) ).g * 1.0;',
    //       '	refractedColor.b = -0.25 + textureCube( tCube, vec3( vRefract[2].x, vRefract[2].yz ) ).b * 1.0;',
    //       '	gl_FragColor = mix( refractedColor, reflectedColor, clamp( vReflectionFactor, 0.0, 1.0 ) );',
    //       '}',
    //     ].join('\n');

    //     const material = new THREE.ShaderMaterial({
    //       uniforms,
    //       vertexShader: shader.vertexShader,
    //       fragmentShader,
    //     });
    //     // material.sides=THREE.DoubleSide
        
    //     return material
      
    //   }
	// initScene(){
    //     // if(this.config.scene.skybox.enabled){
    //         if(this.cubeCam===undefined){
    //             console.log("set cube cam")
    //             this.cubeRenderTarget = new THREE.WebGLCubeRenderTarget( 2048, {  generateMipmaps: true, minFilter: THREE.LinearMipmapLinearFilter } );
    //             // this.matRefl = this.getReflectionMaterial(0.45,1.02,3,0.8,2)
    //             // this.matRefl2 = this.getReflectionMaterial(0.7,1.1,3,0.7,2)
    //             this.cubeCam = new THREE.CubeCamera(this.camera.near,this.camera.far,this.cubeRenderTarget)
    //             this.scene.add(this.cubeCam)
    //         }
    //         // console.log(this.cubeCam)
    //         this.cubeCam.update(this.renderer, this.scene);
    //     // }
    // }
    async traverseMesh(child,meshDefault){

        if (child.name.toLowerCase().indexOf('glass3') === 0 ) {
				// child.material=this.matRefl
					// this.getReflectionMaterial(0.45,1.02,3,0.8,2)

				// if(child.material)Utils.disposeMaterial(child.material)
				// if(!this.matRefl2)
				// this.matRefl2 =new THREE.MeshPhysicalMaterial({
				// 	color:new THREE.Color(0x0),
				// 	envMap:this.cubeRenderTarget.texture,
				// 	envMapIntensity:2.0,
				// 	clearcoat:0.5,
				// 	metalness:0.5,
				// 	roughness:0.1

				// })
				// child.material=this.matRefl
				// let mat = getAlphaFresnel(this.cubeRenderTarget,this.uniontexture,
				// 	0.05,1.02,0.1,2.0,0.1,2.0)
				//   Utils.disposeMaterial(child.material)
				//   child.material.needsUpdate=true
				//   child.material = mat

				// child.material=new THREE.MeshPhysicalMaterial({
				// 	color:new THREE.Color(0x0),
				// 	envMap:this.cubeRenderTarget.texture,
				// 	envMapIntensity:2.0,
				// 	clearcoat:0.5,
				// 	metalness:0.5,
				// 	roughness:0.1

				// })
				// if(child.material)
				// child.material.envMap = this.cubeRenderTarget.texture
				// child.material.needsUpdate=true
				// child.material.depthWrite = true;
				// child.material.depthTest = true;
				// // child.renderOrder = 3;
				// child.castShadow = true
				// child.receiveShadow = true;
				// child.material.transparent = false;
				let mat = getAlphaFresnel3(this.cubeRenderTarget,this.uniontexture,
					0.5,1.5,0.01,0.5,0.1,1.0)
				  Utils.disposeMaterial(child.material)
				  child.material.needsUpdate=true
				  child.material = mat
			
			child.material.depthTest = true;
			child.material.depthWrite = false;
			child.renderOrder = 1;
			child.castShadow = false;
			child.receiveShadow = false;
			child.material.transparent = true;
			// child.material=cubeMaterial3
			child.material.needsUpdate=true
			this.arrReflections.push(child.material)
		}
		else{
			super.traverseMesh(child,meshDefault)
		}
         
    }


	async traverseModel(obj){
        console.log("TRAVERSING wells")
		const PI2= (2* Math.PI)
  		super.traverseModel(obj)

		    
		  await new Promise(r => setTimeout(r, 1500));
		console.log(this.config.scene.mesh_settings)
		Object.keys(this.interactiveMeshes).forEach((key,i)=>{
			var pntCenter = new THREE.Vector3()
		   
			const bbox = new THREE.Box3()
			this.interactiveMeshes[key].updateMatrixWorld(true)
            
			this.interactiveMeshes[key].geometry.computeBoundingBox()
	
			bbox.copy( this.interactiveMeshes[key].geometry.boundingBox )
			bbox.applyMatrix4( this.interactiveMeshes[key].matrixWorld );
			
            bbox.getCenter(pntCenter)
			pntCenter.multiply(this.model.scale)
            
			this.interactiveMeshes[key].centroidPnt = pntCenter.clone()
			
            console.log("here")
			console.log(this.config.views)
			//calculate some min max
			this.config.views.forEach((v)=>{
				
				let lookout =  new THREE.Vector2(pntCenter.x-v.lookat[0] ,  pntCenter.z-v.lookat[2])
				
				let angle=  PI2 - lookout.angle() - Math.PI/2
				angle = this.camManager.rangePI2(angle );
				if(angle>PI2) angle = angle - PI2;
				console.log(v.interactiveMeshes)
				if(!v.interactiveMeshes)v.interactiveMeshes={}
				if(!v.interactiveMeshes[key])v.interactiveMeshes[key]={}
	  
				const spread=Math.PI/1.5
				let min =angle-(spread/2)
				let max= angle+(spread/2)
				if(v.name.toLowerCase()==='brookfield dtla'){
					min=PI2
					max=0
				}
				v.interactiveMeshes[key].minTheta= this.camManager.rangePI2(min)
				v.interactiveMeshes[key].maxTheta= this.camManager.rangePI2(max)
				v.interactiveMeshes[key].mesh=this.interactiveMeshes[key]
				
			})
			
			
		})
    }

    getCamManager(){ return new WellsCamManager(this) }


}