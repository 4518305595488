import TweenMax from 'gsap';
import * as THREE from 'three';

export default class Utils {
  static cleanUpThree(obj) {
    if (obj !== null) {
      for (let i = 0; i < obj.children.length; i += 1) {
        Utils.cleanUpThree(obj.children[i]);
      }
      
      if (obj.geometry) {
        obj.geometry.dispose();
        // obj.geometry = undefined;
      }
      if (obj.material) {
        if (obj.material.map) {
          obj.material.map.dispose();
          // obj.material.map = undefined;
        }
        obj.material.envMap=undefined
        obj.material.dispose();
        // obj.material = undefined;
      }
      if(obj.parent)obj.parent.remove(obj)
    }
   
    obj = undefined;
  }

  static disposeMaterial (mtrl){
    if (mtrl.map)               mtrl.map.dispose ();
    if (mtrl.lightMap)          mtrl.lightMap.dispose ();
    if (mtrl.bumpMap)           mtrl.bumpMap.dispose ();
    if (mtrl.normalMap)         mtrl.normalMap.dispose ();
    if (mtrl.specularMap)       mtrl.specularMap.dispose ();
    if (mtrl.envMap)            mtrl.envMap.dispose ();
    if (mtrl.alphaMap)          mtrl.alphaMap.dispose();
    if (mtrl.aoMap)             mtrl.aoMap.dispose();
    if (mtrl.displacementMap)   mtrl.displacementMap.dispose();
    if (mtrl.emissiveMap)       mtrl.emissiveMap.dispose();
    if (mtrl.gradientMap)       mtrl.gradientMap.dispose();
    if (mtrl.metalnessMap)      mtrl.metalnessMap.dispose();
    if (mtrl.roughnessMap)      mtrl.roughnessMap.dispose();

    mtrl.dispose ();    // disposes any programs associated with the material
  }
  static  disposeNode (node)
  {
    // console.log(node)
      if (node instanceof THREE.Mesh || node instanceof THREE.Sprite)
      {
          if (node.geometry)
          {
              node.geometry.dispose ();
          }
  
          if (node.material)
          {
              if (node.material instanceof THREE.MeshFaceMaterial)
              {   
                node.material.materials.forEach(mtrl => {
                     Utils.disposeMaterial(mtrl)
                  });
              }
              else
              {
                Utils.disposeMaterial(node.material)            
              }
          }
      }
      if(node.parent)
      node.parent.remove(node)
  }   // disposeNode
  static disposeHierarchy (node)
{
  if(!node)return
    for (var i = node.children.length - 1; i >= 0; i--)
    {
        var child = node.children[i];
        Utils.disposeHierarchy (child);
        Utils.disposeNode(child)
    }
}

  static tweenToVector3(posOrig, posto, time, params) {
    const animObj = {
      tV: 0,
      start: posOrig.clone(),
      diff: posto.clone().sub(posOrig),
    };

    params.tV = 1;

    params.onUpdate = (o) => {
      o.set(animObj.diff.x * animObj.tV + animObj.start.x, animObj.diff.y * animObj.tV + animObj.start.y, animObj.diff.z * animObj.tV + animObj.start.z);
    };
    params.onUpdateParams = [posOrig];
    // console.log(params)
    TweenMax.to(animObj, time, params);
  }

  static tweenToVector2(posOrig, posto, time, params) {
    const animObj = {
      tV: 0,
      start: posOrig.clone(),
      diff: posto.clone().sub(posOrig),
    };

    params.tV = 1;

    params.onUpdate = (o) => {
      o.set(animObj.diff.x * animObj.tV + animObj.start.x, animObj.diff.y * animObj.tV + animObj.start.y);
    };
    params.onUpdateParams = [posOrig];

    TweenMax.to(animObj, time, params);
  }

  static tweenToColor(mesh, colorTo, time, params) {
    const animObj = {
      percent: 0,
      fromR: mesh.material.color.r,
      fromG: mesh.material.color.g,
      fromB: mesh.material.color.b,
      toR: colorTo.r,
      toG: colorTo.g,
      toB: colorTo.b,
      m: mesh,
    };
    TweenMax.to(animObj, time, Object.assign({
      percent: 1,
      onUpdate(oJ) {
        oJ.m.material.color = new THREE.Color(
          oJ.fromR + (oJ.toR - oJ.fromR) * oJ.percent,
          oJ.fromG + (oJ.toG - oJ.fromG) * oJ.percent,
          oJ.fromB + (oJ.toB - oJ.fromB) * oJ.percent,
        );
      },
      onUpdateParams: [animObj],
    }, params));
  }
}
