
import React,{forwardRef,useEffect,useState, useRef} from 'react';
import {getContentPath} from 'system/AssetManager.js';
import { ReactCompareSlider, ReactCompareSliderImage, styleFitContainer } from 'react-compare-slider';
import Globals from 'system/Globals.js';
import gsap,{Power2} from 'gsap'
import mstyles from '../modules.module.css';
import style from './screensaver.module.css';
import LoaderWheel from 'system/components/LoaderWheel.js';


const ScreensaverModule = forwardRef((props, ref)=>{

    const refActiveFile = useRef();
    const refIsPlaying = useRef();
    const [loaded,setLoaded] = useState(false);
    const [status,setStatus] = useState('initializing');
    const [files, setFiles] = useState([]);
    const [activeFile, setActiveFile] = useState(null);
    const [navOpen, setNavOpen] = useState(true);
    const [backgroundColor, setBackgroundColor] = useState('#6a6a6a');
    const [activeFileType, setActiveFileType] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [showModules, setShowModules] = useState(false);

    function removeParamUrl(name){
        let params = new URLSearchParams(props.history.location.search);
        params.delete(name)
        props.history.replace({ pathname:props.history.location.pathname, search:decodeURIComponent(params.toString())})
    }

    function onClickClose(evt){
        props.closeModule()
    }   





    useEffect(()=>{ 
    
        ref.current.style.display='block';
        gsap.from(ref.current,{duration:0.5,opacity:0,top:'80%', ease:Power2.easeInOut,onComplete:()=>{
        }});

        (async ()=>{
            try { 
                setStatus('Loading JSON')
                if (props.model.files) {
                    console.log('Screensaver JSON', props.model)
                    setFiles(props.model.files);
                    
                    if (props.model.files.length > 0) {
                        setActiveFile(props.model.files[0])
                        setActiveFileType(props.model.files[0].fileType);
                    }
                    
                    if (props.model.backgroundColor) {
                        setBackgroundColor(props.model.backgroundColor);
                    }
                   
                }
                setStatus('Setting Screensaver')
           
            }catch(err){
          
            }
        })();

        document.addEventListener('click',  () => {props.closeModule()})
        document.addEventListener('keydown',  () => {props.closeModule()})
        
        return () => {
            document.removeEventListener('click',  () => {props.closeModule()})
            document.removeEventListener('keydown',  () => {props.closeModule()})
        }

    },[]);


    function onVidBack() {
        console.log('onVidBack');
        var elem = document.getElementById("file_" + activeFile);
        elem.currentTime = elem.currentTime - 15;
    }

    function onVidForward() {
        console.log('onVidForward');
        var elem = document.getElementById("file_" + activeFile);
        elem.currentTime = elem.currentTime + 15;
    }

    function onVidPause() {
        console.log('onVidPause');
        let videos = document.getElementsByTagName('video');
        let vidArray = [].slice.call(videos);
        for (let i = 0; i < vidArray.length; i++) {
            vidArray[i].pause();

            if (props.socketPrefs && props.socketPrefs.mode === "isBroadcast") {
           
                props.socket.emit('cast', [props.socketPrefs.room, 'module-gallery-command?command=vidplay']);
                console.log('module-gallery-command?command=vidpause')
            }
        }
    }

    function onVidPlay(activeFileId) {
        console.log('onVidPlay', activeFileId);
        var elem = document.getElementById("file_" + activeFileId);
        
        if (elem) {
            setActiveFileType('video');
            
            setIsPlaying(isPlaying => !isPlaying);

     
            if (refIsPlaying.current) {
                
                elem.pause();
                refIsPlaying.current = false;

            } else {
                
                elem.play();
                refIsPlaying.current = true;
            }
         
        }
        
    }



    useEffect(() => {
        if (props.model.files) {
            setLoaded(true)
            props.preAnimateWindowIn()
        // refContainer.current.style.display='block'
       
            props.animateWindowIn()
            setFiles(props.model.files);
            if (props.model.backgroundColor) {
                setBackgroundColor(props.model.backgroundColor);
            }
        }
    }, [props.model.files])

    // useEffect(()=>{ 
        
    //     if (files.length > 0){
    //         setLoaded(true) 
    //         // setActiveFile(files[0].id);
    //         console.log('files', files, files[0].id, props.socket, props.socketPrefs, props.index);
            
            
    //         props.socket.emit('handshake', [props.socketPrefs.room, props.socket.id, 'select-module?module=' + props.index]);
    //         console.log('page handsake sent', 'select-module?module=' + props.index);

    //     }
    // },[files, props.socket, props.socketPrefs, props.index]);


    useEffect(()=>{ 

        if (loaded) {
            

        } 
        
    },[loaded]);

    
  
    return (
        <div className={`fullscreen fcenter ${mstyles.moduleHolder}` } ref={ref}  style={{background: backgroundColor }}>
            {(!loaded)?(<div className={`fullscreen fcenter`}>
            <div>
                <LoaderWheel status={status}></LoaderWheel>
            </div>
             </div>): null }
             

            {activeFile && activeFileType === 'video' && <video width="100%" height="100%" className={`${style.screensaverVideo}`} autoPlay loop playsInline disablePictureInPicture allowFullScreen controlsList="nodownload">
                <source src={getContentPath(activeFile.file)} type="video/mp4" />
            </video>}

        </div>
        
    )

})

export default ScreensaverModule


