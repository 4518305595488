import DefatulProject from './default'
import HoustonCammanager from './houstoncammanager'
import * as THREE from 'three'
import axios from 'axios'
import gsap,{Power3} from 'gsap'
import svgClose from 'assets/icons/close.svg'
import {getAlphaFresnel} from '../shaders/FresnelAlpha'
import Utils from '../scene/Utils'


export default class Houston extends DefatulProject{

    
    constructor(){
        super()
        console.log("houston")        
        this.landmarkBuildings=[]
        this.BldgResName=[]
        this.reflHides=[]
        
        this.HOUSTONBLDGAVAIL= [
			{
				'mesh':'Dummy-Floors-Lyondell',
				'view':'Lyondell',
				'building-id':'LyondellBasell-Tower',
				'url':'https://webapi.brookfieldproperties.app/api/building-listings/lyondellbasell-tower/?format=json',
				'avail':[]
			},
			
			{
				'mesh':'Dummy-Floors-2Houston',
				'view':'2 Houston',
				'building-id':'2-Houston-Center',
				'url':'https://webapi.brookfieldproperties.app/api/building-listings/two-houston-center/?format=json',
				'avail':[]
			},
			{
				'mesh':'Dummy-Floors-Fulbright',
				'view':'3 Houston',
				'building-id':'Fulbright-Tower',
				'url':'https://webapi.brookfieldproperties.app/api/building-listings/fulbright-tower/?format=json',
				'avail':[]
			},
			{
				'mesh':'Dummy-Floors-4Houston',
				'view':'4 Houston',
				'building-id':'4-Houston-Center',
				'url':'https://webapi.brookfieldproperties.app/api/building-listings/four-houston-center/?format=json',
				'avail':[]
			}
		]
    }
    init(dom) {
      return new Promise(async resolve  => {

        await super.init(dom)
        this.arrReflections = []
        let loaded=0
        this.HOUSTONBLDGAVAIL.forEach(grp => {
          axios.get(grp.url).then(result => {
            var avails = {}
            result.data.availabilities.forEach(function (a) {
              if (a.floor_name) {
                var name = a.floor_name.replace(' ', '-')
                if (avails[name]) {
                  avails[name].plans.push({
                    pdf: a.pdf,
                    floor_id: a.floor_id,
                    floor: a.floor
                  })
                } else {
                  avails[name] = {
                    floor_name: a.floor_name,
                    plans: [{
                      pdf: a.pdf,
                      floor_id: a.floor_id,
                      floor: a.floor
                    }]
                  }
                }
              }
            })
            grp.avail = avails
            loaded++;
            if(loaded === this.HOUSTONBLDGAVAIL.length){
              resolve()
            }
          })
        })
      })
    }
    updateCubeCam(){
      if (this.bSetCubeCam === false) {
        let tempBG=this.scene.background
      if(!this.scene.background){
        this.scene.background= this.reflectionCube
      }
  
      this.arrReflections.forEach(ref=>{ref.visible=false})
      this.cubeRenderTarget.clear(this.renderer, true, true, true);
      
      
      this.cubeCam.position.set(-0.02, 0.085, -0.03)
      this.controls.update()
      this.scene.visible=true
        
      if(this.model)this.model.visible=false
  
      if (this.model && this.config.scene.model && this.config.scene.skybox.render_reflection === true ) {
        this.model.visible = true;
      }
    
      this.reflHides.forEach((mesh)=>{mesh.visible=false})
      this.cubeCam.update(this.renderer, this.scene);
      
      
      this.matRefl.uniforms.tCube.value =  this.cubeRenderTarget
      this.arrReflections.forEach(ref=>{
        ref.uniforms.tCube.value=  this.cubeRenderTarget.texture
        ref.needsUpdate=true
        ref.visible=true
      })
      
      if(this.model)this.model.visible = true;
      this.scene.background=tempBG  //set to the previous bg 
      this.bSetCubeCam = true;
      this.reflHides.forEach((mesh)=>{mesh.visible=true})
      
    }
  }

  traverseModel(){
		console.log("TRAVERSING")
		const PI2= (2* Math.PI)
  
		super.traverseModel()
		// console.log(this.config.scene.mesh_settings)
		Object.keys(this.interactiveMeshes).forEach((key,i)=>{
			var pntCenter = new THREE.Vector3()
		   
			const bbox = new THREE.Box3()
			this.interactiveMeshes[key].updateMatrixWorld(true)
	
			this.interactiveMeshes[key].geometry.computeBoundingBox()
	
			bbox.copy( this.interactiveMeshes[key].geometry.boundingBox )
			bbox.applyMatrix4( this.interactiveMeshes[key].matrixWorld );
			bbox.getCenter(pntCenter)
			this.interactiveMeshes[key].centroidPnt = pntCenter.clone()
			
			
			//calculate some min max
			this.config.views.forEach((v)=>{
			  
			  let lookout =  new THREE.Vector2(pntCenter.x-v.lookat[0] ,  pntCenter.z-v.lookat[2])
			  let angle=  PI2 - lookout.angle() + Math.PI/2
			  angle = this.camManager.rangePI2(angle );
	
			  if(!v.interactiveMeshes)v.interactiveMeshes={}
			  if(!v.interactiveMeshes[key])v.interactiveMeshes[key]={}
	
			  const spread=Math.PI/2
			  let min =angle-(spread/2)
			  let max= angle+(spread/2)
			  
			  v.interactiveMeshes[key].minTheta= this.camManager.rangePI2(min)
			  v.interactiveMeshes[key].maxTheta= this.camManager.rangePI2(max)
			  v.interactiveMeshes[key].mesh=this.interactiveMeshes[key]
			})
			
			
		})

		this.HOUSTONBLDGAVAIL.forEach((grp)=>{
			
			var bldg = this.model.getObjectByName(grp.mesh)
			if (bldg){
				bldg.visible = false
				this.reflHides.push(bldg)
			}
			
			var mat = null
			bldg.children.forEach(function(child)  {
				child.visible = false
				if (mat == null) mat = child.material
				else { child.material = mat.clone() }
				child.material.opacity = 0.5
				child.renderOrder = 2
				child.material.transparent = true
			})

		})

	}
    traverseMesh(child,meshDefault){

		  if (Object.prototype.hasOwnProperty.call(this.config.scene.mesh_settings, child.name)) {
			  const objSettings = this.config.scene.mesh_settings[child.name];
			//   console.log("found")
			 if (objSettings.interactive === true) {
				this.interactiveMeshes[child.name] = child;
			  }
			  this.setMeshParams(child, Object.assign({}, meshDefault, objSettings));
			} else {
			  this.setMeshParams(child, Object.assign({}, meshDefault));
			 }
			 
			if(this.reflectionCube){
				
				 if (child.name.toLowerCase().indexOf('bldg-fade') === 0) {
           console.log('adding fade')
					this.interactiveMeshes[child.name] = child
					child.material.transparent=true
				}
				else if (child.name.toLowerCase().indexOf('refl') === 0) {
					// child.material=this.matRefl
					let map
					if(child.material.map ){
							map = child.material.map.clone()
					
						let mat = getAlphaFresnel(this.cubeRenderTarget,map.clone(),
						0.65,1,0.0025,1.5,0.1,0.5)
						Utils.disposeMaterial(child.material)
						child.material = mat
						child.material.transparent=true
						child.material.needsUpdate=true
						child.material.depthWrite = false;
					// console.log(child.material)
					}else{
						let mat = getAlphaFresnel(this.cubeRenderTarget,this.uniontexture,
							0.65,1,0.0025,1.5,0.1,0.5)

						child.material = mat
						child.material.transparent=true
						// console.log(child.material)
					
					}   

					child.material.depthTest = true;
					child.renderOrder = -1;
					child.castShadow = false;
					child.receiveShadow = false;
					child.material.transparent = true;
		
		
					this.arrReflections.push(child.material)
				}
				else if (child.name.toLowerCase().indexOf('-landmk') >= 0){
					
					// console.log("PUSHING LNdmK")		
					
					child.material.color = new THREE.Color(0.855, 0.855, 0.855)
					child.material.color = new THREE.Color(0.855, 0.855, 0.855)
					child.oColor = new THREE.Color(0.855, 0.855, 0.855)
					child.highColor = new THREE.Color(117 / 255, 142 / 255, 172 / 255)
					this.landmarkBuildings.push(child)
					
				}
				else if (child.name.indexOf('Bldg-Res') === 0) {
					this.BldgResName.push(child.name)
					console.log('pushing buildigns res')
					child.material.color = new THREE.Color(0.855, 0.855, 0.855)
					child.material.color = new THREE.Color(0.855, 0.855, 0.855)
					child.oColor = new THREE.Color(0.855, 0.855, 0.855)
					child.highColor = new THREE.Color(117 / 255, 142 / 255, 172 / 255)
					this.landmarkBuildings.push(child)
				}
				else if (child.name === 'Layer_3D_bldgs_context') { 
					child.position.y = -5 

				}
        // if(child.name.indexOf('Bldg-transp')===0 || child.name==="Bldg-landmk05"){
        //   this.fadeMeshes.push(child)
        // }
				this.checkHidden(child)
			}
		
		
	  }

    checkHidden(child){
      if( child.name.indexOf('Dummy-')===0 ||
      child.name === 'LayerBldg-Lyondell'||
      child.name === 'Bldg-2Houston'  ||
      child.name === 'Bldg-4Houston' ||
      child.name === 'Bldg-Lyondell' ||
      child.name === 'Object042'||
      child.name === 'Bldg-transp04' ||
      child.name.toLowerCase().indexOf('bldg-fulbright')===0 ||
      (child.material && child.material.name.indexOf('Bldg-2houston')===0 )
      
      ){
        // console.log(child.name)
        this.reflHides.push(child)
      }
      }
      traverseModelObject(child){
        
      this.checkHidden(child)
      }

  onChangeCategory(c){
	let v = this.config.views.find(v=>{
		if(c==="Views" && v.name.trim()==="Initial")
			return true 
		else 
			return  v.name===c
	})

	console.log(v)
    
	let floorView= Object.assign({},v)
    floorView.category=c

    this.camManager.animateView(floorView)
  }

  selectFloor(building,floor){
    // console.log("select floor ",building,floor,this.camManager.currentBldgGrpMesh)
    this.deSelectFloor()
    var floorMesh = this.camManager.currentBldgGrpMesh.getObjectByName(building+'-'+floor)
		if(floorMesh){
      gsap.to(floorMesh.material,{duration:0.25,opacity:1})
      this.highlightedMesh = floorMesh.name
    }
    else{
      this.camManager.currentBldgGrpMesh.userData.highlighted=null
    }

		
    var modelFloor= this.camManager.currentBldgGrp.avail[floor]
		// console.log(modelF	loor)
		if(modelFloor){
			var eleContainer = document.createElement('div')
			eleContainer.classList.add('hous-floor-plans-container')
			this.renderer.domElement.parentElement.appendChild(eleContainer)
			modelFloor.plans.forEach(function(plan){
				if(plan.pdf){
					var div = document.createElement('div')
					div.classList.add('btn')
					div.innerHTML='PLAN '+plan.floor
					eleContainer.appendChild(div)
					div.setAttribute('pdf',plan.pdf)
					div.onclick=function(evt){
						
            let eleOverlay = document.createElement('div')
						eleOverlay.setAttribute('id','hous-pdf-preview')
            eleOverlay.classList.add('fullscreen')

						let eleFrame = document.createElement('iframe')
						eleFrame.setAttribute('src',encodeURI(evt.currentTarget.getAttribute('pdf')))
						eleFrame.setAttribute('allowFullscreen','true')
						// eleFrame.setAttribute('sandbox','')
						eleFrame.setAttribute('frameborder','0')
						
						let eleFrameContainer = document.createElement('div')
						eleFrameContainer.classList.add('frame-container')
						eleFrameContainer.appendChild(eleFrame)
						eleOverlay.appendChild(eleFrameContainer)

            let imgClose= new Image()
						imgClose.classList.add('round-btn')
            imgClose.src=svgClose
						imgClose.onclick=function(evt){
            //   console.log(evt.currentTarget)
							evt.currentTarget.parentElement.remove()
						}
            eleOverlay.appendChild(imgClose)

						document.body.appendChild(eleOverlay)
						eleOverlay.style.opacity=0
						imgClose.style=Object.assign(imgClose.style,{bottom: 0,
							position: 'absolute',
							cursor:'pointer',
							left: 0,'z-index':99})
              imgClose.onload=function(){
							  gsap.to(eleOverlay,{opacity:1,duration:0.5,ease:Power3.easeInOut})
						  }
						
					}
				}
			})
		}

  }

  deSelectFloor(){
    if( this.highlightedMesh){
			var mesh=this.camManager.currentBldgGrpMesh.getObjectByName(this.highlightedMesh)
			if(mesh)
				gsap.to(mesh.material ,{duration:0.25,opacity:0.5})
		}
	  this.removePlanContainer()
	}
	removePlanContainer=function(){
		var fpcontainer = this.renderer.domElement.parentElement.querySelector('.hous-floor-plans-container')
		if(fpcontainer)fpcontainer.remove()
	}

  getMenuViews(){
    let menu={}
    this.config.views.forEach(v=>{
      if(!menu[v.category])menu[v.category]=[]
      menu[v.category].push(v)
      this.HOUSTONBLDGAVAIL.forEach(grp=>{
        if(grp.view===v.name){
          if(!menu[v.name])menu[v.name]=[]
          Object.values(grp.avail).forEach(avail=>{
            if(avail.floor_name){
              let floorView=Object.assign({},v)
              var floor = avail.floor_name.replace(' ','-')
              floorView.avail=avail
              floorView.name=floor
              floorView.category=v.name
              floorView["building-id"]=grp["building-id"];
              menu[v.name].unshift(floorView)
            }
          })
        }
      })
      
      
    })
    
    
    return  menu
  }
  onDown(event){
    
    super.onDown(event)
    let mouse = this.getMouse(event)
      
    if(!this.camManager.currentBldgGrpMesh)return
	//   console.log(mouse)
    let raycaster = new THREE.Raycaster()
    raycaster.setFromCamera(mouse, this.camera)
    // console.log(this.camManager.currentBldgGrpMesh.children)
    var intersects = raycaster.intersectObjects(this.camManager.currentBldgGrpMesh.children,true)
    console.log(intersects.length)
    if(intersects.length){
      this.camManager.currentBldgGrpMesh.userData.highlighted = intersects[0].object.name
      var name =intersects[0].object.name
      name=name.replace(this.camManager.currentBldgGrp['building-id']+'-','')
    //   console.log(this.camManager.currentBldgGrp['building-id'],name)
      this.selectFloor(this.camManager.currentBldgGrp['building-id'],name)

    }
  }

  
    getCamManager(){
        return new HoustonCammanager(this)
      }

      cleanup(){
        this.fadeMeshes=[]
        this.reflHides=undefined
        super.cleanup()
        this.landmarkBuildings=[]
        this.BldgResName=[]
        this.HOUSTONBLDGAVAIL=undefined
      }

}