import {useRef,useEffect} from 'react'

// eslint-disable-next-line react-hooks/exhaustive-deps
export const useComponentDidMount = func => useEffect(func, []);
export const useMountUnMount = (f1,f2) => useEffect(()=>{
  if(f1)f1()
  return ()=>{
    if(f2)f2()
  }
// eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

export const useComponentWillMount = func => {
  const willMount = useRef(true);
  if (willMount.current) {
    func();
  }
  useComponentDidMount(() => {
    willMount.current = false;
  });
};
