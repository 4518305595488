import React,{useEffect,useRef,useState} from 'react'
import {getContentPath} from  'system/AssetManager'
import gsap,{Power2} from 'gsap'
import Globals  from 'system/Globals'
import ControllerServerComs from 'system/ControllerServerComs'
import styles from './controller-narrative.module.css'
import {ReactComponent as CaretLeft} from 'assets/icons/caret_left.svg'
import {ReactComponent as CaretRight} from 'assets/icons/caret_right.svg'
import {ReactComponent as CaretUp} from 'assets/icons/caret_up.svg'
import {ReactComponent as CaretDown} from 'assets/icons/caret_down.svg'

const ControllerDPad = (props)=>{

    const posMouseRef = useRef([0.5,0.5]);
    const moveSignal = useRef();
    useEffect(() => {
        window.oncontextmenu = function() { return false; }

    }, [])

    function touchStart(e, dir) {
        ControllerServerComs.instance().sendUDPFromControllerToServer('joystick-mouse-down', posMouseRef.current[0]+','+posMouseRef.current[1]);
        gsap.fromTo(e.target, {scale: 1, background: '#ffffff'}, {scale: 0.95, background: '#fafafa', duration: 0.2})

        ControllerServerComs.instance().sendUDPFromControllerToServer('joystick-start-move', dir);
    }

    function touchEnd(e, dir) {
        console.log('touchEnd dir', dir)
        ControllerServerComs.instance().sendUDPFromControllerToServer('joystick-mouse-up', posMouseRef.current[0]+','+posMouseRef.current[1]);
        ControllerServerComs.instance().sendUDPFromControllerToServer('joystick-end-move');
        posMouseRef.current = [0.5, 0.5]

        gsap.fromTo(e.target, {scale: 0.95, background: '#fafafa'}, {scale: 1, background: '#ffffff', duration: 0.2})
        clearInterval(moveSignal.current);
    }

    return (      

        <section id="dPad" className={`${styles.dPad}`} onContextMenu={() => {}} >           
            <span className={`${styles.dPadSpace}`} />
            <span className={`${styles.dPadBtn} ${styles.dPadUp}`} onTouchStart={(e) => touchStart(e, 'U')} onTouchEnd={(e) => touchEnd(e, 'U')}><CaretUp /></span>
            <span className={`${styles.dPadSpace}`} />
            <span className={`${styles.dPadBtn} ${styles.dPadLeft}`} onTouchStart={(e) => touchStart(e, 'L')} onTouchEnd={(e) => touchEnd(e, 'L')}><CaretLeft /></span>
            <span className={`${styles.dPadSpace}`} />
            <span className={`${styles.dPadBtn} ${styles.dPadRight}`} onTouchStart={(e) => touchStart(e, 'R')} onTouchEnd={(e) => touchEnd(e, 'R')}><CaretRight /></span>
            <span className={`${styles.dPadSpace}`} />
            <span className={`${styles.dPadBtn} ${styles.dPadDown}`} onTouchStart={(e) => touchStart(e, 'D')} onTouchEnd={(e) => touchEnd(e, 'D')}><CaretDown /></span>
            <span className={`${styles.dPadSpace}`} />
        </section>   

    )

}

ControllerDPad.defaultProps = { sensitivity: 1 }

export default ControllerDPad