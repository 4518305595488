
import * as THREE from 'three';

export default function ParseLight(obj){

    const Light = new THREE[obj.type]();
      if (obj.name) Light.name = obj.name;
      if (obj.color) Light.color = new THREE.Color(Number(obj.color));
      if (obj.ground) Light.groundColor = new THREE.Color(Number(obj.ground));
      if (obj.intensity) Light.intensity = obj.intensity;
      if (obj.position) Light.position.set(obj.position[0], obj.position[1], obj.position[2]);
      if (obj.decay) Light.decay = obj.decay;
      if (obj.angle) Light.angle = obj.angle;
      if (obj.penumbra) Light.penumbra = obj.penumbra;
      if (obj.distance) Light.distance = obj.distance;
      if (obj.shadow) Light.castShadow = obj.shadow;
      if (obj.target) Light.target.position.set(obj.target[0], obj.target[1], obj.target[2]);
      if(obj.id)
        Light.userData.uid=obj.id
    //  console.log("SHADOW ",obj.shadow)
      if (obj.shadow && obj.type !== 'HemisphereLight') {
        Light.shadow.bias = -0.0000025;// Number.MIN_VALUE;
        // Light.shadow.darkness = 0.0001;
        Light.shadow.mapSize.width = 2048;
        Light.shadow.mapSize.height = 2048;
        Light.shadow.radius = 1.75;
    
        Light.shadow.camera.near = 0.1;
        Light.shadow.camera.far = 20;
        // console.log(Light);
        // Light.shadow.camera.near = 0.3;
        const offset = 0.25;
        Light.shadow.camera.right = offset;
        Light.shadow.camera.left = -offset;
        Light.shadow.camera.top = offset;
        Light.shadow.camera.bottom = -offset;
      }

      return Light;

}