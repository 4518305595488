import React,{useState,useEffect,useRef, Fragment} from 'react'
import styles from './narrative.module.scss'
import gsap,{Power3} from 'gsap'
import classNames from 'classnames';
import {getContentPath} from 'system/AssetManager';
import {ReactComponent as IconCaret} from 'assets/icons/caret_closed.svg';
import Globals  from 'system/Globals';

const NarrativeMenuItem =  ({model,style,onClickLink,onExpand,index,className,activePage,pageIndex,serialOn,onSerialCmdOff,thumbnail})=>{
    
    const [open,setOpen] = useState(false)
    const refContainer = useRef()
    const refEle = useRef()

    //allow for close event from outside
    useEffect(()=>{
       
        let ele =refEle.current
        const onClose = ()=>{setOpen(false)}
        ele.removeEventListener('close',onClose)
        ele.addEventListener('close',onClose)
        return ()=>{ ele.removeEventListener('close',onClose)}
    },[])

    useEffect(() => {
        // console.log('pageIndex in MenuPageItem', pageIndex, model.index)
    }, [pageIndex])

    useEffect(() => {
        if (activePage) {
            console.log('activePage id in MenuPageItem', activePage && activePage)
        } else {
            console.log('activePage id in MenuPageItem', null)
        }
        
    }, [activePage])

    //handle open/close state change
    useEffect(()=>{
        // console.log('onExpand hook', onExpand, index)
        if(refContainer.current){
            if(open){
                if(typeof(onExpand) ==='function')onExpand(index)
                let theight = refContainer.current.style.height
                refContainer.current.style.height='auto'
                let height = refContainer.current.clientHeight
                refContainer.current.style.height=theight
                // console.log(theight,height,refContainer.current.style.height)
                gsap.to(refContainer.current,{height:height,duration:0.5,ease:Power3.easeInOut})
            }
            else{
                // refContainer.current.style.height='0px'
                gsap.to(refContainer.current,{height:0,duration:0.5,ease:Power3.easeInOut})
            }
        }
        
    },[index, onExpand, open])

    function onClick(index){
        console.log('onClick MenuLink', index)
        
        // if(evt.target.getAttribute('type')==='page'){
            
        // }
        // else{
            
        // }
        // let index = evt.target.getAttribute("index");
        onClickLink(index)
    //    setOpen(!open)
    }

    function openSub(){
        setOpen(!open)
    }

    return(
        <div 
            className={`${className}`}
            ref={refEle}
            open={open}
            index={index}
            style={style}
        >


            <span 
                index={model.index}
                page={model.page}
                type={model.subs ? 'group' : 'page'  } 
                className={classNames(
                    !pageIndex && (index === 0) && "linkOn",
                    model.subs && model.subs.length > 0 && styles.groupLink,
                    pageIndex && (pageIndex === model.index) && "linkOn"
                )} 
                // onClick={(e)=> onClick(e) } 
                >
                {
                    model.subs && model.subs.length > 0 && (
                        <span onClick={() => openSub()} className={styles.linkCaret}><IconCaret className={'themeSVGStroke themeSVGStrokeHover'} style={{...style,'--svg-stroke':style.color,'--svg-stroke-hover':style['--hover-color'],'opacity':0.75}} ></IconCaret></span>
                    )
                }
                <span onClick={(e)=> onClick(model.index)} className={styles.linkText}>{model.label}</span>
                
                {thumbnail && <img src={getContentPath(model.thumbnail)} alt="thumbnail" />}
            </span>
            
  

            {
                model.subs && activePage && (    
                        <div className={styles.menuSubContainer + ' menuSubs'} ref={refContainer}>
                            {
                             model.subs.map((sub,i)=>{
                                return <div key={`narmenu-${index}-${i}`} 
                                    index={sub.index}
                                    pageid={sub.page}
                                    onClick={()=> onClick(sub.index)} 
                                    type='page'
                                    // style={style}
                                    className={classNames(
                                        className,
                                        styles.menuSubLink,
                                        activePage && (activePage === sub.page) && "linkOn"
                                    )}>
                        
                                    {/* <span className={styles.linkText}>{sub.label}</span> */}
                                    {sub.thumbnail && <img src={getContentPath(sub.thumbnail)} alt="thumbnail" />}
                                    </div>
                             })   
                             
                            }
                        </div>
                        )
        
            }    
        </div> 
    )

}


export default NarrativeMenuItem