export default function CharStyleOverrides(textObj, format) {
    let span = document.createElement('span');
    span.innerHTML = "";
    let charOverrides = textObj['charStyleOverrides'];
    let styleOverrides = textObj['styleOverrides'];
    let result;

    let newText = textObj['text'];

   
    // console.log('processHtmlText start', textObj, newText)

    if (charOverrides && charOverrides.length > 0) {     
      let tempSpan = "";
      for (let i = 0; i < newText.length; i++) {

        if (charOverrides[i] && (charOverrides[i] !== 0)) {
          if (charOverrides[i - 1] && (charOverrides[i - 1] === charOverrides[i])) {
              // let ws = /\s/.test(newText.charAt(i));
              tempSpan+=newText.charAt(i)

              if (i === charOverrides.length - 1) {
                tempSpan+="</span>";
              }
        
          } else {
            let style = styleOverrides.find(a => a.id === charOverrides[i])
            if (style) {
              const styleString = (
                Object.entries(style['css']).map(([k, v]) => 
                  {
                    if (k === "font-size"){
                      let value = `${v}`;
                      let s0 = value.replace('px', '');
                      let s = parseInt(s0);
                      let sVal = parseInt(s) + "px"
                      if (format) {
                        s = ((s/parseInt(format.canvas_width.replace('px','')))*100).toFixed(2)
                        sVal = s + 'vw';
                        // sVal = "clamp(" + s + "vh," + s + "vh," + sVal + ")"
                      }
                      
                      return `${k}:${sVal}`
                  } else {
                    return `${k}:${v}`
                  }
                }
                    
              ).join(';')
              );

              if ((charOverrides[i - 1] && (charOverrides[i - 1] !== charOverrides[i]))) {
                tempSpan+="</span>";
              }

              tempSpan+="<span style='" + styleString + "'>" + newText.charAt(i);
              if (i === charOverrides.length - 1) {
                tempSpan+="</span>";
              }
          
            }
        
          }   

        } else {
          tempSpan+=newText.charAt(i)

          if ((charOverrides[i - 1] && (charOverrides[i - 1] !== charOverrides[i]))) {
            tempSpan+="</span>";
          }
          
        }
        
      }


      
      result = tempSpan;
      // console.log('processHtmlText result', span0)
    } else {
      result = textObj['text'];
    }
    return result
  }