import './App.css';
import Main from './system/Main'

import React, { useState, useEffect } from 'react';
// import ControllerCursor from 'controller/ControllerCursor';
import Globals from 'system/Globals';


// const socket = io('https://socket.expostudio.co');

function App() {


  return (
    <div className={"App"}>
  
      <iframe id="analytics-frame" width="200" height="100" src="" title="analytics"></iframe>
     
      <Main></Main>  
     


    </div>
  );
}

export default App;
