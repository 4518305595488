import React,{forwardRef,useEffect,useState,useRef,useCallback} from 'react'
import mstyles from '../modules.module.css'
import styles from './webgl.module.scss'

import Globals from 'system/Globals'
import LoaderWheel from 'system/components/LoaderWheel'
import axios from 'axios'
import {ReactComponent as CloseButton} from 'assets/icons/close.svg'
// import gsap,{Power2} from 'gsap'
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader';
import {DRACOLoader} from 'three/examples/jsm/loaders/DRACOLoader';
import {getContentPath} from 'system/AssetManager'
import * as THREE from 'three'
import DefaultProject from './projects/default'
import LeadhallProject from './projects/leadenhall'
import HoustonProject from './projects/houston'
import WellsProject from './projects/Wells'
import SixSixtyProject from './projects/SixSixty'

import RemoteJoystick from 'system/RemoteJoystick'

// import ToolsMenu from 'system/components/ToolsMenu'
import {ReactComponent as ChevronButton} from 'assets/icons/caret_right.svg'
import {ReactComponent as TrayClose} from 'assets/icons/tray_close.svg'
import ModuleContainer from '../ModuleContainer'
import {loadContentFile} from  'system/AssetManager'
import Menu from './menu'

const WebGLModule = (props)=>{

    const [config,setConfig]=useState(null)
    const [loading,setLoading]=useState(true)
    const refEle = useRef()
    const refContainer = useRef()
    const refProject = useRef(null)
    const refNavOpen = useRef(true)
    const [navOpen, setNavOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState();
    const [status,setStatus] = useState("Loading")
    const [module,setModule] =useState(null)
    const refModuleContainer = useRef()
    const [menuViews, setMenuViews]=useState()
    const refServerAppListener =  useRef()
    const moduleListener = useRef()
    const refFollowUpModule =useRef()
    console.log("RENDER WEBGL")
   
    let mounted = false

    useEffect(()=>{
        mounted = true
        let proj=refProject.current
       
        moduleListener.current = (evt)=>{
            console.log(props.model.markers,evt.detail.key)
            let m= props.model.markers.find((m)=>{return m.key===evt.detail.key})

            if(m.files && m.files.length){
                refProject.current.stopRender()
           
                m.typeName="gallery"
                m.standard=false
                console.log(m)
                
                if(m){
                    console.log("setting module")
                    setModule(m)
                }
            }else{ console.log('no content files found for this marker ') 
            refProject.current.startRender()}
        }

        // let container =refContainer.current
        
        const loadConfigModel=async()=>{

            if(props.model.loadImage){
                try{
                    await(loadContentFile(props.model.loadImage))
                }catch( e){
                    console.error(e)
                }
            }

            await props.animateWindowIn()
            
            axios.get(getContentPath(props.model.config,false),{
                
            }).then(async response =>{
                // loadContentFile(props.model.config,undefined,false).then(async r =>{
                    // let response= {data:r}

                setConfig(response.data)
                 
                if(response.data.project==="leadenhall"){
                    // refProject.current=new DefaultProject()
                    let room;
                    if (props.socketPrefs.mode === "isReceive") {
                        room = props.socketPrefs.room
                    }
                    refProject.current=new LeadhallProject(room)
                }
                else if(response.data.project==="houston"){
                    refProject.current=new HoustonProject()
                }
                else if(response.data.project==="wells"){
                    refProject.current =new WellsProject()
                }
                else if(response.data.project==="660"){
                    refProject.current =new SixSixtyProject()
                }
                else{
                    refProject.current=new DefaultProject()
                    console.log('default project')
                }
                proj=refProject.current
       
                refProject.current.setConfig(response.data)
                refProject.current.markerLookup=props.model.markers
                await refProject.current.init(container)
                setMenuViews(refProject.current.getMenuViews())
                setSelectedItem(refProject.current.initCamViewIndex)
                setStatus("Loading Model")
            
                axios.request(
                    {
                        responseType: 'arraybuffer',
                        url:getContentPath(props.model.model,false),
                        
                        onDownloadProgress: (progressEvent) => {}
                    }).then(response=>{
                    
                    if(!mounted) return
                    console.log("complete" ,typeof response.data)
                    
                    const assetMap = new Map();
                    const fileURL = URL.createObjectURL(new Blob([response.data]));
                    const baseURL = THREE.LoaderUtils.extractUrlBase(fileURL);
                    const manager = new THREE.LoadingManager();
                    const blobURLs = [];
                    
                    manager.setURLModifier((url, path) => {
                      const normalizedURL = url
                        .replace(baseURL, '')
                        .replace(/^(\.?\/)/, '');
              
                      if (assetMap.has(normalizedURL)) {
                        console.log('has');
                        const blob = assetMap.get(normalizedURL);
                        const blobURL = URL.createObjectURL(blob);
                        blobURLs.push(blobURL);
                        return blobURL;
                      }
                      return (path || '') + url;
                    });

                    setStatus("Creating Scene")
                    
                    const loader = new GLTFLoader(manager);
                    loader.setCrossOrigin('anonymous');
                    loader.setDRACOLoader(new DRACOLoader());                        
                    loader.load(fileURL, (gltf) => {
                        if(!mounted) return
                    
                        setStatus("Running")
                        setLoading(false)
                        refProject.current.addModel(gltf,async ()=>{
                            
                            await new Promise(r => setTimeout(r, 300));
                            setNavOpen(true)
                            refNavOpen.current = true
                        })


                        if (props.socket && props.socketPrefs && props.socketPrefs.mode && props.index) {

                            props.socket.emit('handshake', [props.socketPrefs.room, props.socket.id, 'select-module?module=' + props.index]);
                            console.log('page handsake sent', 'narrative-module?module=' + props.index)
                        
                        }
        
                        
                        
                    }, undefined, (e) => {
                        console.error(e);
                        });
                    })
            })
            .catch(error=>{
                console.error('could not fetch json 3d',error)
            })
        }
        
        
        props.preAnimateWindowIn()

        refEle.current.style.display='block'

        const onResize=()=>{ refProject.current.handleResize() }
        window.addEventListener('resize',onResize)

        loadConfigModel()
        
        let container =refContainer.current

        container.removeEventListener('module',moduleListener.current) 
        container.addEventListener('module',moduleListener.current)
        
        return ()=>{
            mounted = false
            console.log("CLEAN")
            // container.removeEventListener('module',onOverlayModule)
            container.removeEventListener('module',moduleListener.current) 
        
            refEle.current=null
            if(proj){
                proj.cleanup()
                proj=undefined
                refProject.current=undefined
            }
            window.removeEventListener('resize',onResize)
            refFollowUpModule.current=null
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const serverAppListener = useCallback( (evt)=>{
        let detail = evt.detail
        if(!detail)return
        if(detail.event==='module-webgl-select-view'){ 
            console.log(detail.command)
            if(refProject.current.camManager.currentView){
                let elemodule =refEle.current.querySelector('.'+mstyles.container)
                if(elemodule)elemodule.dispatchEvent(new CustomEvent('close-module'))
                refFollowUpModule.current=null
            }
            let arrcmd=detail.command.split(",")
            let v= menuViews[arrcmd[0]][Number(arrcmd[1])]
            setSelectedItem(Number(arrcmd[1]))
            console.log(v)
            refProject.current.camManager.animateView(v)
        }
        else if(detail.event==='module-webgl-zoom-in'){ 
            refProject.current.controls.dollyOut()
        }
        else if(detail.event==='module-webgl-zoom-out'){ 
            refProject.current.controls.dollyIn()
        }
        else if(detail.event==='module-webgl-select-marker'){ 
            
            if(refProject.current.camManager.currentView){
                let m = props.model.markers[Number(detail.command)]
                if(refModuleContainer.current){
                    refModuleContainer.current.closeModule()
                    refFollowUpModule.current=m
                }
                else{
                    moduleListener.current(new CustomEvent('module',{detail:m}))
                }
            }
        }
        else if(detail.event==='gallerypopup-close'){ 
            console.log(detail.command)
            if(refProject.current.camManager.currentView){
                let elemodule =refEle.current.querySelector('.'+mstyles.container)
                if(elemodule)elemodule.dispatchEvent(new CustomEvent('close-module'))
            }
        }
        else if(detail.event==='webgl-mousemove'){
            console.log('mousemove received', detail.command)
            let objs = detail.command.split(','); 
            // refContainer.current.click();
            refProject.current.onDown(null, [Number(objs[0]),Number(objs[1])])
            // if (objs) {
            //     refProject.current.camManager.animateView([Number(objs[0]), Number(objs[1]), 0])
            // }
            
        }  
        else if(detail.event==='module-webgl-command'){
            console.log('module-webgl-command received', detail.command)
            // let command = detail.command;
            onClickToggleMenu();
            // if (refNavOpen.current)
            props.socket.emit('handshake', [props.socketPrefs.room, props.socket.id, 'module-webgl-command?command=toggleMenu']);
            
        }    
       
    },[menuViews, module, props.model.markers, props.socketPrefs])
    

    useEffect(()=>{
        
        if(!loading && menuViews && (Globals.instance().serverApp || (props.socketPrefs && props.socketPrefs.mode === "isReceive")) && refServerAppListener.current===undefined){
            
            window.removeEventListener('server-event', refServerAppListener.current)
            refServerAppListener.current=serverAppListener
            window.addEventListener('server-event', refServerAppListener.current)
        }
    },[loading, menuViews, module, serverAppListener, props.socketPrefs])



    
    let onCategoryCB=useCallback((c)=>{
        setSelectedItem(-1)
        refProject.current.onChangeCategory(c)
    },[setSelectedItem,refProject])
    
    const  onClickView=useCallback((evt)=>{
        let index =Number(evt.currentTarget.getAttribute("index"))
        let category =evt.currentTarget.getAttribute("category")
        let v= menuViews[category][index]
        setSelectedItem(index)
        refProject.current.camManager.animateView(v)

        if (props.socketPrefs && props.socketPrefs.mode === 'isBroadcast') {
            props.socket.emit('cast', [props.socketPrefs.room, 'module-webgl-select-view?v=' + category + ','+index]);
            console.log("onClickView", category, index)
        }

      
         
    },[menuViews])
   

    const  onClickToggleMenu=useCallback(()=>{
        if (props.socketPrefs && props.socketPrefs.mode === "isBroadcast") { 
            props.socket.emit('cast', [props.socketPrefs.room, 'module-webgl-command?command=toggleMenu']);
            console.log('cast module-gallery-command?command=toggleMenu')
        } 

        if (refNavOpen.current === true) {
            setNavOpen(false)
            refNavOpen.current = false;
        } else {
            setNavOpen(true);
            refNavOpen.current = true;
        }


    },[navOpen,setNavOpen])

    function onClickClose(){props.closeModule()}

    function onDownContainer(evt){
        if(!Globals.instance().serverApp)
            refProject.current.onDown(evt, null)

            // console.log('onDownContainer evt', evt)
    }

    useEffect(()=>{
        if(refFollowUpModule.current){
            moduleListener.current(new CustomEvent('module',{detail:refFollowUpModule.current}))
            refFollowUpModule.current=null
        }
    },[module])
    return (
        <div className={`webglele fullscreen fcenter ${mstyles.moduleHolder} ${styles.container}` } ref={refEle}>
            <div className={`fullscreen`} ref={refContainer} 
            onMouseDown={onDownContainer} onTouchStart={onDownContainer} 
            id="onDownContainer"
            key='webgl-container'></div>
            { props.model.loadImage && loading && 
                <div className={`fullscreen ${styles.bgImageContainer}`}>
                    <img src={getContentPath(props.model.loadImage)} className={`${styles.bgImage}`} alt=""></img>
                </div>
            }
            { loading && ( <div className={`fullscreen fcenter`}>
                
                <div>
                    <LoaderWheel status={status} ></LoaderWheel>
                </div>
                
            </div>
            )}
            
           

            {!Globals.instance().serverApp && !loading && (
                <React.Fragment>
                
                <Menu key='webgl-menu' menuViews={menuViews} selectedItem={selectedItem} setSelectedItem={setSelectedItem} onClickView={onClickView} onCategory={onCategoryCB} navOpen={navOpen} theme={props.theme}></Menu>
                
                <div className={`${styles.rightNavBtn2} ${(navOpen)?(styles.navOpen):(null)} ifServerHide`}  >
                    <div className={`round-btn`} onClick={() => onClickToggleMenu()} style={Globals.instance().getThemeStyle(['nav-menu-btn'],props.theme)} key='webgl-menu-toggle'>
                        <TrayClose></TrayClose>
                    </div>
                </div>

                <div className={`${mstyles.closeBtnContainer} ifServerHide`}>
                        
                    <div className={`round-btn  `} onClick={onClickClose} >
                        <CloseButton></CloseButton>
                    </div>
                </div>
                </React.Fragment>
            )}
            { module && (
                <ModuleContainer
                module={module} 
                history={props.history} 
                theme={props.theme} 
                setShowDrawControls={props.setShowDrawControls} 
                key='webgl-module-container'
                closeModule={()=>{
                    refProject.current.startRender()
                    setModule(null)
                }}
                ></ModuleContainer>
            )} 

            {props.socketPrefs && props.socketPrefs.mode === 'isBroadcast' && <RemoteJoystick joystickRef={refContainer.current} socket={props.socket} socketPrefs={props.socketPrefs} />}

            {props.socketPrefs && props.socketPrefs.mode === 'isReceive' && <span id="remoteCursor" />}

        </div>
    )

}

export default WebGLModule

