
import React,{useEffect,useRef,useState,forwardRef} from 'react'
import styles from './controller-narrative.module.css'
import ControllerServerComs from 'system/ControllerServerComs'
// import nstyles from '../narrative/narrative.module.scss'
// import mstyles from '../narrative/modules/modules.module.css'
// import {ReactComponent as CloseButton} from '../assets/icons/close.svg'
// import Globals from 'system/Globals'

const ControllerVideoUI = forwardRef( (props ,ref)=>{
    
    const onClickVidBack = () => {
        ControllerServerComs.instance().sendUDPFromControllerToServer('module-gallery-command', "vidback");
    }

    const onClickVidForward = () => {
        ControllerServerComs.instance().sendUDPFromControllerToServer('module-gallery-command', "vidforward");
    }

    const onClickVidPause = () => {
        if (props.vidIsPlaying) {
            props.setVidIsPlaying(false);
        } else {
            props.setVidIsPlaying(true);
        }
    }
    useEffect(()=>{
     
        // eslint-disable-next-line react-hooks/exhaustive-deps   
    },[])

    useEffect(() => {
        console.log("props.vidIsPlaying", props.vidIsPlaying)
        if (props.vidIsPlaying) {
            ControllerServerComs.instance().sendUDPFromControllerToServer('module-gallery-command', "vidplay");
        } else {
            ControllerServerComs.instance().sendUDPFromControllerToServer('module-gallery-command', "vidpause");
        }
    }, [props.vidIsPlaying])

    return (
             
        <section ref={ref} className={`${styles.galleryPopup} ${styles.videoControls}`}>
        <div className={`${styles.videoButtons}`}>
            <div className={`${styles.videoBtnRow}`}>
                <div className={`${styles.videoBtn}`} onClick={onClickVidBack}>Back<br/>15 secs</div>
                <div className={`${styles.videoBtn}`} onClick={onClickVidForward}>Forward<br/>15 secs</div>
            </div>
            <div className={`${styles.videoBtn}`} onClick={onClickVidPause}>{(props.vidIsPlaying)?"Pause":"Play"}</div>
        </div>
        
    </section>
    )

})

export default ControllerVideoUI
