import React, { useState, useEffect, useRef } from "react";
import gsap from "gsap";
import styles from '../styles/modal.module.css'


function Modal(props) {

    return (
        <div className={`${styles.modalContainer} + ((props.threeBtn)?${styles.threeBtn}:"")`}>
            <div className={styles.modal}>
                <span className={styles.textContainer}>
                    <h3>{props.modal.heading}</h3>
                    {props.modal.text && <p><span  dangerouslySetInnerHTML={{ __html: props.modal.text }} /></p>}
                    {props.modal.choices && props.modal.choices}
                    {props.modal.formInput && props.modal.formInput}
                </span> 
              
                <div className={`${styles.errorRow} ${((!props.modal.btn2Text)?styles.oneBtn:"")}`}>
                    {props.threeBtn ? <React.Fragment>
                        {props.modal.btn2Text && <span className={styles.saveContinue} onClick={() => props.modal.onBtn2Click(props.modal.onBtn2)}>{props.modal.btn2Text}</span>}
                        {props.modal.btn3Text && <span onClick={() => props.modal.onBtn3Click(props.modal.onBtn3)}>{props.modal.btn3Text}</span>}
                        <span className={styles.cancel} onClick={() => props.modal.onBtn1Click(props.modal.onBtn1)}>{props.modal.btn1Text}</span> 
                        </React.Fragment>:
                    <React.Fragment>
                    <span onClick={() => props.modal.onBtn1Click(props.modal.onBtn1)}>{props.modal.btn1Text}</span> 
                    {props.modal.btn2Text && <span onClick={() => props.modal.onBtn2Click(props.modal.onBtn2)}>{props.modal.btn2Text}</span>}
                   
                    </React.Fragment>}
                   
                </div>
            </div>
        </div>
    )

}

export default Modal;