

import style from 'system/styles/remotecoms.module.css';
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Globals from 'system/Globals';
import Modal from 'system/components/Modal';
import {ReactComponent as Cast} from 'assets/icons/cast.svg'


function StartMeeting(props) {

    const [modal,setModal]=useState()

    function generateRandomString(length) {
        const charset = "abcdefghijklmnopqrstuvwxyz0123456789";
        let result = "";
    
        for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charset.length);
        result += charset.charAt(randomIndex);
        }
    
        return result;
    }


    function joinRoom(){
        const randomString = generateRandomString(8)
        props.socket.emit("join", [randomString,  props.user.username]);
        // const parentUrl = window.location.origin;
        // const shareUrl = parentUrl + '/home?join=' + randomString
        // console.log('join', shareUrl, props.user)
        // Globals.instance().socketRoom = randomString

        let postData = {
            room_id: randomString
        }
        let url = Globals.instance().adminAPI+'/api/start-meeting/';
        axios({
            method: "post",
            url: url,
            data: postData,
            headers: {  Authorization: 'Token ' + props.user.token}
        }).then((response) => {
            console.log("meeting_hash", response.data['meeting_hash'])
            let meeting_id = response.data['meeting_hash']
            props.setSocketPrefs({'mode': 'isBroadcast', 'room': randomString, 'nid': props.nid, 'meeting': meeting_id})
            let cookie = sessionStorage.getItem('setpref-socket');
            if (cookie) {
                sessionStorage.removeItem('setpref-socket')
                sessionStorage.setItem('setpref-socket', JSON.stringify({'mode': 'isBroadcast', 'room': randomString, 'nid': props.nid, 'meeting': meeting_id}))
            } else {
                sessionStorage.setItem('setpref-socket', JSON.stringify({'mode': 'isBroadcast', 'room': randomString, 'nid': props.nid, 'meeting': meeting_id}))
            }


        });

    }

    function broadcastEmail(val) {

        if (val) {
            console.log('email')
        
            Globals.instance().broadcastMode = val;
            console.log('onChangePrefBroadcast', val, props.nid)
            joinRoom();
            setModal(null);
        }
       
       
    }

    function onChangePrefBroadcast() {

        let formInput = <input type='text' placeholder='Add email addresses' />
        setModal({
            "heading":  "New Meeting",
            "onBtn1Click": setModal,
            "onBtn1": null,
            "btn1Text": "Cancel",
            "onBtn2Click": broadcastEmail,
            "onBtn2": true,
            "btn2Text": "Invite",
            "formInput": formInput
        })
        
    }

    return(
        <React.Fragment>
        { modal &&  (<Modal modal={modal} threeBtn={false} />)}
        {props.user && props.socketPrefs && props.loaded && props.socketPrefs.mode !== "isReceive" && props.socketPrefs.mode !== "isBroadcast" && (
            <div className={`${style.startMeetingBtn} round-btn`} onClick={() => onChangePrefBroadcast()}>
                <Cast className='gMat' />
            </div>
        )}
        </React.Fragment>
        
    ) 

}

export default StartMeeting;

