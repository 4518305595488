import axios from 'axios'
import Globals from '../Globals'

const postEvent = (username, data, market, category) => {

    function getBrowserData(nav) {
        var data = {};
    
        var ua = data.uaString = nav.userAgent;
        var browserMatch = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*([\d.]+)/i) || [];
        if (browserMatch[1]) { browserMatch[1] = browserMatch[1].toLowerCase(); }
        var operaMatch = browserMatch[1] === 'chrome';
        if (operaMatch) { operaMatch = ua.match(/\bOPR\/([\d.]+)/); }
    
        if (/trident/i.test(browserMatch[1])) {
            var msieMatch = /\brv[ :]+([\d.]+)/g.exec(ua) || [];
            data.name = 'msie';
            if (data.version) {
                data.version = msieMatch[1];
            }
        }
        else if (operaMatch) {
            data.name = 'opera';
            data.version = operaMatch[1];
        }
        else if (browserMatch[1] === 'safari') {
            var safariVersionMatch = ua.match(/version\/([\d.]+)/i);
            data.name = 'safari';
            if (data.version) {
                data.version = safariVersionMatch[1];
            }
        }
        else {
            data.name = browserMatch[1];
            data.version = browserMatch[2];
        }
    
        var versionParts = [];
        if (data.version) {
            var versionPartsMatch = data.version.match(/(\d+)/g) || [];
            for (var i=0; i < versionPartsMatch.length; i++) {
                versionParts.push(versionPartsMatch[i]);
            }
            if (versionParts.length > 0) { data.majorVersion = versionParts[0]; }
        }
        data.name = data.name || '(unknown browser name)';
        data.version = {
            full: data.version || '(unknown full browser version)',
            parts: versionParts,
            major: versionParts.length > 0 ? versionParts[0] : '(unknown major browser version)'
        };
    
        return data;
    };
    var brData = getBrowserData(window.navigator);
 
    var base_url = 'https://cdn.origin.track.udxd.co/api/';
    const userAgent = window.navigator.userAgent;
    const isElectron = Globals.instance().electron;
    const isIpad = Globals.instance().ipad;
    let os = null;
    if (isIpad) {
        os = "iPad";
    } else if (/Macintosh|Mac|Mac OS|MacIntel|MacPPC|Mac68K/gi.test(userAgent)) {
        os = 'Mac';
    } else if (/'Win32|Win64|Windows|Windows NT|WinCE/gi.test(userAgent)) {
        os = "Windows";
    } else {
        os = userAgent;
    }
    const controllerMode = Globals.instance().controllerMode;
    const serverApp = Globals.instance().serverApp;
    const presentationMode = Globals.instance().presentationMode;
    var mode = "";
    if (controllerMode) {
        mode = "controller"
    } else if (serverApp) {
        mode = "controller-server"
    } else if (presentationMode) {
        mode = "large-touch"
    } else {
        mode = "default"
    }

    // console.log('market', market)

    const getData = async () => {
        const res = await axios.get('https://api.ipify.org/');
        var ip = res.data;
        // console.log('postEvent', ip, isElectron, os)
        try {
            const get_url = base_url + "app-event/";
            let postData =  {
                "app": 120,
                "username": username,
                "market": market,
                "category": category,
                "ip_address": ip,
                "detail": data.detail,
                "name": data.event,
                "page": data.page,
                "page_id": data.page_id,
                "page_title": data.page_title,
                "search": data.search,
                "platform": os,
                "electron": isElectron,
                "browser": brData.name + " | " + brData.version.full,
                "version": Globals.instance().version + " | " + Globals.instance().build,
                "mode": mode
            };
            console.log('postData', postData);
            axios({
                method: "post",
                url: get_url,
                data: postData
            }).then((response) => {
                console.log("postEvent", response['data'])
                // setRenewCertbotOn(true);
             
            });
        } catch (error) {
            console.error(error);
        }
    }
    // getData();
}

export default postEvent

