
import * as THREE from 'three'
import CamManager from './CamManager'

export default class LeadenCamManager extends CamManager {


    update(){
        super.update()

        var theta = this.p.controls.getAzimuthalAngle()
		var arrSort = []

		// check if we need to fade any objects
		if (this.currentView && this.currentView.interactiveMeshes) {
		  Object.keys(this.currentView.interactiveMeshes).forEach((key) => {
		  // for (const index in this.currentView.fadeMeshes) {
			const obj = this.currentView.interactiveMeshes[key];
			
			if(obj.mesh.material.opacity>0 && obj.mesh.material.opacity<1){
			  var camPosition = this.p.camera.position.clone()
			  obj.distance = (camPosition.sub(obj.mesh.localToWorld(obj.mesh.centroidPnt.clone()))).length()
			  arrSort.push(obj)
			}
	
			
	
			  if (typeof obj.minTheta !== 'undefined' && typeof obj.maxTheta !== 'undefined') {
				if (this.isHiddenInRange(theta, obj.minTheta, obj.maxTheta)) {
				  if (obj.mesh.material && obj.mesh.material.opacity > 0 && (typeof obj.fadeable === 'undefined' || obj.fadeable === true)) {
					this.setAnimationOnObject(obj.mesh, 'fadeOut');
				  }
				} else if (obj.mesh.visible === false || (obj.mesh.material && obj.mesh.material.opacity < 1)) {
				  this.setAnimationOnObject(obj.mesh, 'fadeIn');
				}
			}
		  });
		
		}
		arrSort.sort((a, b)=>{
			if (a.distance < b.distance) { return -1 }
			if (a.distance > b.distance) { return 1 }
			return 0
		})
		arrSort.forEach(function(mesh, index){
			// console.log(mesh)
			mesh.renderOrder = 3 + index
		})

    }
	updateCameraAnimation(){
		if (this.cameraAnimation.animating) {
			this.removeBoundsOnControls() // turn ooff bounds
			// complete then finish up
			if (this.cameraAnimation.percent > 1) {
			 this.onCompleteAnimView()
			}
	  
			// sine animation
			const per = Math.sin(this.cameraAnimation.percent * Math.PI / 2)
	  
			this.cameraAnimation.percent = ((new Date()).getTime() - this.cameraAnimation.timeStart) / (this.cameraAnimation.timeEnd - this.cameraAnimation.timeStart)
	  
			const tar = this.cameraAnimation.targetEnd.clone()
			tar.sub(this.cameraAnimation.targetStart)
			tar.multiplyScalar(per)
			tar.add(this.cameraAnimation.targetStart)
			this.p.controls.target.set(tar.x, tar.y, tar.z)
	  
			// calc distance
			const d = this.cameraAnimation.d1 + (per * (this.cameraAnimation.d2 - this.cameraAnimation.d1))
	  
			// angleEnd.normalize()
			let angleDiff = this.cameraAnimation.angleEnd.clone().sub(this.cameraAnimation.angleStart)
			angleDiff.multiplyScalar(per)
			angleDiff.add(this.cameraAnimation.angleStart)
			angleDiff.add(this.cameraAnimation.yNudge.clone().multiplyScalar(Math.sin(per * Math.PI)))
			angleDiff.normalize()
	  
			// console.log('d :' + d, distanceDest)
	  
			let pos = angleDiff.multiplyScalar(d).add(tar)
	  
			this.p.camera.position.set(pos.x, pos.y, pos.z)
			this.p.controls.update()
		  }
	}

	animateTo (pos, targetend) {
		const distance = this.p.camera.position.distanceTo(pos)
	
		this.p.controls.enabled = false
		this.cameraAnimation.animating = true
		this.cameraAnimation.startpos = this.p.camera.position.clone()
		this.cameraAnimation.endpos = pos.clone()
		this.cameraAnimation.targetStart = this.p.controls.target.clone()
		this.cameraAnimation.targetEnd = targetend.clone()
	
		this.cameraAnimation.d1 = this.p.camera.position.distanceTo(this.p.controls.target)
	
		this.cameraAnimation.timeStart = (new Date()).getTime()
		this.cameraAnimation.angleStart = this.cameraAnimation.startpos.clone().sub(this.cameraAnimation.targetStart).normalize()
		let angleEnd = this.cameraAnimation.endpos.clone().sub(this.cameraAnimation.targetEnd)
		this.cameraAnimation.d2 = angleEnd.length()
		this.cameraAnimation.angleEnd = angleEnd.normalize()
	
		const angle = angleEnd.angleTo(this.cameraAnimation.angleStart)// Math.acos((distanceCam*distanceCam+distanceDest*distanceDest-distance*distanceDest)/(2*distanceCam*distanceDest));
		let angleDiff = this.cameraAnimation.angleEnd.clone().sub(this.cameraAnimation.angleStart).normalize()
	
		var quaternion = new THREE.Quaternion() // create one and reuse it
		quaternion.setFromUnitVectors(this.cameraAnimation.angleStart, this.cameraAnimation.angleEnd)
		this.cameraAnimation.yNudge = angleDiff.applyAxisAngle(new THREE.Vector3(0, 1, 0), Math.PI / 2).multiplyScalar(angle / Math.PI * -0.5 * Math.sign(quaternion.y))
	
		// console.log(angle, quaternion)
		this.cameraAnimation.timeEnd = this.cameraAnimation.timeStart + 700 + (angle * 500) + distance * 600
		if (this.cameraAnimation.timeEnd > this.cameraAnimation.timeStart + 10000) { this.cameraAnimation.timeEnd = this.cameraAnimation.timeStart + 10000 }
		this.cameraAnimation.percent = 0
		this.cameraAnimation.angleVel = 0
	  }
}