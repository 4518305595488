import React, { useEffect, useRef } from 'react';

const CircularProgressBar = ({ percentage, strokeWidth, sqSize }) => {
  const radius = (sqSize - strokeWidth) / 2;
  const circumference = radius * 2 * Math.PI;
  const progress = ((100 - percentage) / 100) * circumference;

  const progressBarRef = useRef(null);

  useEffect(() => {
    const progressBar = progressBarRef.current;
    progressBar.style.strokeDashoffset = progress;
  }, [progress]);

  return (
    <svg width={sqSize} height={sqSize}>
      <circle
        className="progress-ring-circle"
        strokeWidth={strokeWidth}
        stroke="#444"
        fill="transparent"
        r={radius}
        cx={sqSize / 2}
        cy={sqSize / 2}
      />
      <circle
        className="progress-ring-indicator"
        strokeWidth={strokeWidth}
        fill="transparent"
        stroke="green"
        strokeDasharray={circumference}
        strokeDashoffset={0}
        r={radius}
        cx={sqSize / 2}
        cy={sqSize / 2}
        ref={progressBarRef}
      />
    </svg>
  );
};

export default CircularProgressBar;