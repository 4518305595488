
import React, {useEffect,useRef } from 'react'

const RemoteJoystick = (props)=>{
    // const posStickRef = useRef([0,0]);
    const posMouseRef = useRef([0.5,0.5]);
    const posmousedownRef = useRef([0.0,0.0]);
    const mouseState = useRef("ready");
    
    // const intervalRef = useRef(null);
    const joystickRef = useRef();
    const didMoveRef = useRef(false);

    const mouseDragStartListener = useRef();
    const mousedownListener = useRef();
    const mousemouseListener = useRef();
    const mouseupListener = useRef();
    const dragStateRef = useRef(false);

    function handleDragVal(evt) {
        let posx= evt.clientX
        let posy= evt.clientY
        let w = evt.target.offsetWidth;
        let h =  evt.target.offsetHeight
        // console.log("handelDragVal", posx, posy, w, h, evt)
        return [posx/w,posy/h]
    }

    function keepbounds(arr){ 
        if(arr[0]<0)arr[0]=0
        if(arr[0]>1)arr[0]=1
        if(arr[1]<0)arr[1]=0
        if(arr[1]>1)arr[1]=1
    }

    useEffect(() => {
        console.log('props', props);
       if (props.joystickRef) {
        
        mousedownListener.current = props.joystickRef.addEventListener('mousedown', function(e){ 
            if (mouseState.current !== "down") {
                props.socket.emit('cast', [props.socketPrefs.room, 'joystick-mouse-down?pos=' + posMouseRef.current[0]+','+posMouseRef.current[1]]);

                    dragStateRef.current = true;
                    mouseState.current = "down";
            }
        });

        mousemouseListener.current = props.joystickRef.addEventListener('mousemove', function(e){
            
            if (mouseState.current === "down") {
                let newPos= handleDragVal(e);
                let change = [newPos[0]-posmousedownRef.current[0], newPos[1]-posmousedownRef.current[1]]
                posmousedownRef.current = newPos
                posMouseRef.current = [posMouseRef.current[0]+ (props.sensitivity * change[0]),posMouseRef.current[1]+(props.sensitivity * change[1])]
                keepbounds(posMouseRef.current)

                // ControllerServerComs.instance().sendUDPFromControllerToServer('joystick-move',posMouseRef.current[0]+','+posMouseRef.current[1]);

                props.socket.emit('cast', [props.socketPrefs.room, 'joystick-mousemove?pos=' + posMouseRef.current[0]+','+posMouseRef.current[1]]);

                console.log('mousemove',posMouseRef.current, newPos)
                didMoveRef.current = true;
            }
            
        });

        // mousemouseListener.current = props.joystickRef.addEventListener('mousemouse', function(e){
            
        //     let newPos= handleDragVal(e);
        //     let change = [newPos[0]-posmousedownRef.current[0], newPos[1]-posmousedownRef.current[1]]
        //     posmousedownRef.current = newPos
        //     posMouseRef.current = [posMouseRef.current[0]+ (props.sensitivity * change[0]),posMouseRef.current[1]+(props.sensitivity * change[1])]
        //     keepbounds(posMouseRef.current)

        //     // ControllerServerComs.instance().sendUDPFromControllerToServer('joystick-move',posMouseRef.current[0]+','+posMouseRef.current[1]);

        //     props.socket.emit('cast', [props.socketPrefs.room, 'joystick-mouse?pos=' + posMouseRef.current[0]+','+posMouseRef.current[1]]);

        //     console.log('move',posMouseRef.current)
        //     didMoveRef.current = true;
        // });
        

        mouseupListener.current = props.joystickRef.addEventListener('mouseup', function(e){ 
            if (mouseState.current === "down") {    
                console.log('up')

                // ControllerServerComs.instance().sendUDPFromControllerToServer('joystick-mouse-up', posMouseRef.current[0]+','+posMouseRef.current[1]);

                props.socket.emit('cast', [props.socketPrefs.room, 'joystick-mouse-up?pos=' + posMouseRef.current[0]+','+posMouseRef.current[1]]);

                if (dragStateRef.current === true) {
                    dragStateRef.current = false;
                }          
                mouseState.current = "up";
                if (!didMoveRef.current) {

                    // ControllerServerComs.instance().sendUDPFromControllerToServer('joystick-mouse-click', posMouseRef.current[0]+','+posMouseRef.current[1])

                    props.socket.emit('cast', [props.socketPrefs.room, 'joystick-mouse-click?pos=' + posMouseRef.current[0]+','+posMouseRef.current[1]]);

                } else{
                  
                         //reset to center
                        (async ()=>{
                            await new Promise(resolve => setTimeout(resolve, 100));

                            // ControllerServerComs.instance().sendUDPFromControllerToServer('joystick-move', '0.5,0.5');

                            props.socket.emit('cast', [props.socketPrefs.room, 'joystick-mouse?pos=0.5,0.5']);

                            posMouseRef.current=[0.5,0.5]
                        })()
             
                   
                }
                didMoveRef.current = false;
            }  else {
                
           
                    if (!didMoveRef.current) {
                        
                        // ControllerServerComs.instance().sendUDPFromControllerToServer('joystick-mouse-click', '0.5,0.5')

                        props.socket.emit('cast', [props.socketPrefs.room, 'joystick-mouse-click?pos=0.5,0.5']);

                        console.log("1f click")
                    }
                    mouseState.current = "up";
                
            }
        });
        
        if (props.twoFingerDrag !== true) {

            // ControllerServerComs.instance().sendUDPFromControllerToServer('joystick-move', '0.5,0.5');

        }
        
        }
        

        return ()=>{
            window.removeEventListener('mousedown', mousedownListener.current)
            window.removeEventListener('mousemove', mousemouseListener.current)
            window.removeEventListener('mouseup', mouseupListener.current)

            // window.removeEventListener('dragstart', mouseDragStartListener.current)
        }   
    }, [props.joystickRef])

    return (null);

}

RemoteJoystick.defaultProps = { sensitivity: 1 }

export default RemoteJoystick