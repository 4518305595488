import {getContentPath} from 'system/AssetManager'
import Globals from 'system/Globals'
import axios from 'axios';


import {ReactComponent as IconMap} from 'assets/icons/_modules/expo_map.svg';
import {ReactComponent as IconGallery} from 'assets/icons/_modules/expo_gallery_images.svg'
import {ReactComponent as IconStack} from 'assets/icons/_modules/expo_stackbuilding.svg'
import {ReactComponent as Icon3D} from 'assets/icons/_modules/expo_3d.svg'
import {ReactComponent as IconDocs} from 'assets/icons/_modules/expo_documents.svg'
import {ReactComponent as IconVR2} from 'assets/icons/_modules/expo_gallery_vrs.svg'
import {ReactComponent as Presentation} from 'assets/icons/_modules/expo_presentation.svg'

function getModuleIcon(m,theme,i){
    console.log('getModuleIcon', m)
    return new Promise(resolve=>{
    let i = null
    let s = Globals.instance().getThemeStyle(['nav-menu-module-icon'], theme)
    let title = m.title;
    if(m.icon){
        const ext = m.icon.split(".").pop()
        if(ext.toLowerCase()==="svg")
        {
            axios.get(getContentPath(m.icon)).then(response=>{
                resolve(<span><div style={{width:'100%',height:'100%'}} dangerouslySetInnerHTML={ {__html : response.data} }></div><p>{title}</p></span>)
            })
        }else 
            resolve( <span><img src={getContentPath(m.icon)} alt="" className={'themeSVGStroke themeSVGStrokeHover'}></img><p>{title}</p></span>)
    } else {
        
        switch(m.typeName){
            case 'map':
                i=<span><IconMap style={s} className={'themeSVGStroke themeSVGStrokeHover'} key={`anarmodicon${i}`}></IconMap><p>{title}</p></span>
            break;
            case 'mapbox':
                i=<span><IconMap style={s} className={'themeSVGStroke themeSVGStrokeHover'} key={`anarmodicon${i}`}></IconMap><p>{title}</p></span>
            break;
            case 'gallery':
                i=<span><IconGallery style={s} className={'themeSVGStroke themeSVGStrokeHover'} key={`anarmodicon${i}`}></IconGallery><p>{title}</p></span>
            break;
            case 'stack':
                i=<span><IconStack style={s} className={'themeSVGStroke themeSVGStrokeHover'} key={`anarmodicon${i}`}></IconStack><p>{title}</p></span>
            break;
            case 'vr':
                i=<span><IconVR2 style={s} className={'themeSVGStroke themeSVGStrokeHover'} key={`anarmodicon${i}`}></IconVR2><p>{title}</p></span>
            break;
            case 'webgl':
                i=<span><Icon3D style={s} className={'themeSVGStroke themeSVGStrokeHover'} key={`anarmodicon${i}`}></Icon3D><p>{title}</p></span>
            break;
            case 'document':
                i=<span><IconDocs style={s} className={'themeSVGStroke themeSVGStrokeHover'} key={`anarmodicon${i}`}></IconDocs><p>{title}</p></span>
            break;
            case 'narrative':
                i=<span><Presentation style={s} className={'themeSVGStroke themeSVGStrokeHover'} key={`anarmodicon${i}`}></Presentation><p>{title}</p></span>
            break;
            case 'controller-narrative':
                i=<span><Presentation style={s} className={'themeSVGStroke themeSVGStrokeHover'} key={`anarmodicon${i}`}></Presentation><p>{title}</p></span>
            break;
            default:
                i=<span><IconGallery style={s} className={'themeSVGStroke themeSVGStrokeHover'} key={`anarmodicon${i}`}></IconGallery><p>{title}</p></span>
            break;
        }
        
        resolve(i)
    }
    })
}


export default getModuleIcon;