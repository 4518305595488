import React,{forwardRef,useEffect,useState,useRef, useCallback} from 'react';
import {getContentPath} from 'system/AssetManager';
import Globals from 'system/Globals';
import ControllerServerComs from 'system/ControllerServerComs';
import mstyles from '../modules.module.css';
import gsap,{Power2} from 'gsap'
import mapboxgl, { setNow } from 'mapbox-gl/dist/mapbox-gl-csp';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapStyles from './mapbox.module.css';
import './mapbox.css'
import ToolsMenu from 'system/components/ToolsMenu'
import MapBoxMenu from './mapbox.menu';
import LoaderWheel from 'system/components/LoaderWheel';
import {ReactComponent as CloseButton} from 'assets/icons/close.svg'
import chevron from 'assets/icons/caret_down.svg'
import {ReactComponent as ChevronButton} from 'assets/icons/caret_down.svg'
import {ReactComponent as CheckCircle} from 'assets/icons/checkCircle.svg'
import {ReactComponent as TrayClose} from 'assets/icons/tray_close.svg'
import {ReactComponent as ChevronThinButton} from 'assets/icons/caret_right.svg'
import {ReactComponent as Pin} from 'assets/icons/map_center.svg'
import {ReactComponent as ZoomOut} from 'assets/icons/minus.svg'
import {ReactComponent as ZoomIn} from 'assets/icons/plus.svg'

// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
import { PositionOptions } from 'mapbox-gl';
// import { remove } from 'dom7';

mapboxgl.workerClass = MapboxWorker;
mapboxgl.accessToken = 'pk.eyJ1IjoiYnBzdjMiLCJhIjoiY2tpNG5oZXpoMjA2ODJxcGRmYnJlajV2ayJ9.ibWqAVMhvzPSqEZfLJE63w';

const MapBoxModule = forwardRef((props, ref)=>{
	const [loaded,setLoaded] = useState(false);
	const [status,setStatus] = useState('initializing');
	const [files, setFiles] = useState([]);
	const [menuOpen, setMenuOpen] = useState(false);
	const [navOpen, setNavOpen] = useState(true);
	const [format, setFormat] = useState('desktop');

	const [activeFileHeading, setActiveFileHeading] = useState(null);
	const [prevActiveFile, setPrevActiveFile] = useState(null);
	const [activeFile, setActiveFile] = useState(null);
	const [activeMap, setActiveMap] = useState(null);
	const [activeOverlayCategories, setActiveOverlayCategories] = useState([]);
	const [activeOverlays, setActiveOverlays] = useState([]);
	const [activeOverlayGroups, setActiveOverlayGroups] = useState([]);
	const [pngGroups, setPngGroups] = useState([]);
	const [popUps, setPopUps] = useState([]);

	const [selectedOverlays, setSelectedOverlays] = useState([]);
	const [selectedOverlayGroups, setSelectedOverlayGroups] = useState([]);
	const [selectedCategories, setSelectedCategories] = useState([]);
	const [selectedPngGroups, setSelectedPngGroups] = useState([]);

	const [targetLayerId, setTargetLayerId] = useState();
	const [targetPngGroupLayerIds, setTargetPngGroupLayerIds] = useState();
	const [runTurnOffAllLayers, setTurnOffAllLayers] = useState(false);
	const [runTurnOffRadioLayers, setTurnOffRadioLayers] = useState(false);
	const [runTurnOffOverlayParamLayers, setTurnOffOverlayParamLayers] = useState(null);
	const [runTurnOffLayerArray, setTurnOffLayerArray] = useState(null);

	const [handleCategoryId, setHandleCategoryId] = useState(null);
	const [radioOn, setRadioOn] = useState(false);
	const [radioObj, setRadioObj] = useState(null);

	const [overlaysLoaded, setOverlaysLoaded] = useState(false);
	const [overlayGroupsLoaded, setOverlayGroupsLoaded] = useState(false);
	const [paramsProcessed, setParamsProcessed] = useState(false);

	const [flyToPoint, setFlyToPoint] = useState(null);
	const [flyToZoom, setFlyToZoom] = useState(null);
	const [flyToFile, setFlyToFile] = useState(null);
	const [flyToGroup, setFlyToGroup] = useState(null);
	const [flyToLayer, setFlyToLayer] = useState(null);
	const [flyToAnim, setFlyToAnim] = useState(false);

	const [showLanding, setShowLanding] = useState(true);
	const [mapState, setMapState] = useState(null);

	const refMapContainer = useRef();
	const refServerAppListener =  useRef();
	const activeFileId = useRef(null);
	const refMap = useRef();
	const refMapState = useRef();

	

	// Functions
 	
	function onClickClose(evt){
		props.closeModule()
		removeParamUrl('pngs')
	}

	const flattenArray = useCallback((array) => {
        var flatArray = '';
        array.forEach((obj, i) => {
            if (i === array.length - 1) {
                flatArray+=obj
            } else {
                flatArray+=obj + ','
            }  
        });
        return flatArray
    }, [])


	function convertColors(elemId) {
		try {
			var elem = document.getElementById(elemId);
			const style = getComputedStyle(elem)
			const backgroundColor = style.backgroundColor
			var newBackgroundColor = backgroundColor.replace(')', ', 0.85)').replace('rgb', 'rgba');
			elem.style.backgroundColor = newBackgroundColor;
		} catch {
			// pass
		}
	}

	function resetFlyTo() {
		setFlyToPoint(null);
		setFlyToZoom(null);
		setFlyToFile(null);
		setFlyToGroup(null);
		setFlyToLayer(null);
	}

	function updateParamUrl(name, objIDs){
		let params = new URLSearchParams(props.history.location.search);
		params.set(name, objIDs)
		props.history.replace({ pathname:props.history.location.pathname, search:decodeURIComponent(params.toString())})
	}


	const removeParamUrl = useCallback((name) => {
		let params = new URLSearchParams(props.history.location.search);
		params.delete(name)
		props.history.replace({ pathname:props.history.location.pathname, search:decodeURIComponent(params.toString())})
	}, [props.history])

	function handleTurnOffOverlays() {
		setSelectedOverlays([]);
		setSelectedOverlayGroups([]);
		setSelectedPngGroups([]);
		removeParamUrl('group');
		removeParamUrl('layer');
		var overlays = document.getElementsByClassName('mapboxgl-marker');
		var overlayArray = [].slice.call(overlays);
		for (let i = 0; i < overlayArray.length; i++) {
			overlayArray[i].remove();
		  }
	}

	function handleMenu(){
		if (menuOpen) {
			setMenuOpen(false)
		} else {
			setMenuOpen(true);
		}
	}
	
	function onClickHide() {
		if (navOpen === true) {
			setNavOpen(false)
		} else {
			setNavOpen(true);
		}
	}

	const turnOffAllLayers = useCallback((handleStates) => {
		console.log('turnOffAllLayers', selectedOverlays, selectedOverlayGroups, selectedPngGroups)

		if (selectedOverlays && selectedOverlays.length > 0) {
			selectedOverlays.forEach((item, i) => {
				var target = document.getElementById("mapboxOverlay_" + item);
				if (target) {
					target.classList.remove(`${mapStyles.markerOn}`);
				}
			});  
			if (handleStates) {
				setSelectedOverlays([]);
				setSelectedCategories([]);
			}
		} 
		if (selectedOverlayGroups && selectedOverlayGroups.length > 0) {
			selectedOverlayGroups.forEach((item, i) => {
				var target = document.getElementById("mapboxOverlay_" + item);
				if (target) {
					target.classList.remove(`${mapStyles.markerOn}`);
				} 
			});  
			if (handleStates) {
				setSelectedOverlayGroups([]);	
			}
		} 
		if (selectedPngGroups && selectedPngGroups.length > 0) {
			selectedPngGroups.forEach((item, i) => {
				var targets = document.getElementsByClassName("pngGroup-" + item);
				var targetArray = [].slice.call(targets);
				for (let i = 0; i < targetArray.length; i++) {
					targetArray[i].classList.remove('markerOn');
				}
			})
			if (handleStates) {
				setSelectedPngGroups([]);
			}
		}
		if (runTurnOffAllLayers) {
			setTurnOffAllLayers(false);
		}

	}, [selectedOverlays, selectedPngGroups, selectedOverlayGroups, runTurnOffAllLayers])

	const turnOffOverlayParamsFiles = useCallback((objID) => {
		removeParamUrl('group');
        removeParamUrl('layer');
        var overlays = document.getElementsByClassName('mapboxgl-marker');
        var overlayArray = [].slice.call(overlays);
        for (let i = 0; i < overlayArray.length; i++) {
            if (overlayArray[i].id !== 'mapboxOverlay_' + objID) {
                overlayArray[i].classList.remove(`${mapStyles.markerOn}`);
            } 
          }
		setTurnOffOverlayParamLayers(null);
	}, [removeParamUrl])

	function processPngGroupOverlay(objID, isOnlyOn) {
		console.log('processPngGroupOverlay', objID)
		var targets = document.getElementsByClassName("pngGroup-" + objID);
		var targetArray = [].slice.call(targets);
		if (selectedPngGroups.includes(objID)) { 
			var newSelectedPngGroups = [ ...selectedPngGroups ];
			const overlayIndex = newSelectedPngGroups.indexOf(objID);
			newSelectedPngGroups.splice(overlayIndex, 1);
			setSelectedPngGroups(newSelectedPngGroups);
			for (let i = 0; i < targetArray.length; i++) {
				targetArray[i].classList.remove('markerOn');
			}
		} else {
			if (isOnlyOn) {
				var newSelectedPngGroups = [];
				setSelectedOverlays([]);
				setSelectedOverlayGroups([]);
				setSelectedCategories([]);
				setRadioObj({"id": objID, "format": "pngGroup"});
				turnOffAllLayers(true);
			} else {
				var newSelectedPngGroups = [ ...selectedPngGroups ];
				if (radioObj) {
					if (radioObj['format'] === "pngGroup") {
						const overlayIndex = newSelectedPngGroups.indexOf(radioObj['id']);
						newSelectedPngGroups.splice(overlayIndex, 1);
					}
				} 
			}
			newSelectedPngGroups.push(objID)
			setSelectedPngGroups(newSelectedPngGroups);
			for (let i = 0; i < targetArray.length; i++) {
				targetArray[i].classList.add('markerOn');
			}
		}
		if (newSelectedPngGroups.length === 0) {
			removeParamUrl('pngs');
		} else {
			if (newSelectedPngGroups.length === 1) {
				updateParamUrl("pngs", newSelectedPngGroups);
			} else {
				updateParamUrl("pngs", newSelectedPngGroups.join());
			}
		}
	}

	function handlePinClick(fileId) {
		if (refMapState.current !== 'moving') {
			const activeFileMap = files.filter(item => ((item.id === fileId)))[0];  

			activeMap.flyTo({
				center: [activeFileMap.longitude, activeFileMap.latitude],
				zoom: (format === 'iPad') ? (activeFileMap.zoom_iPad) : (activeFileMap.zoom)
			})

		}

	}

	function handleZoomInClick() {
		if (refMapState.current !== 'moving') {
			activeMap.zoomIn({duration: 1000});
		}
		
	}
	function handleZoomOutClick() {
		if (refMapState.current !== 'moving') {
			activeMap.zoomOut({duration: 1000});
		}
	}

	function handleMapFileClick(file) {
		removeParamUrl('links');
		// setActiveOverlayGroups([]);
		var link = file.link_target_url;
		var url = new URL(window.location.protocol + '//' + window.hostname + link);
		var fileLink = url.searchParams.get('file');
		var fileLinkInt = parseInt(fileLink);
		var groupLink = url.searchParams.get('group');
		var layerLink = url.searchParams.get('layer');
		var flyLink = url.searchParams.get('fly');
		var zoomLink = parseFloat(url.searchParams.get('zoom'));
		
		// console.log('handleMapFileClick', fileLink, groupLink, layerLink, flyLink)

		if (file.map_target !== null) {
			resetFlyTo();                                    
			updateParamUrl('file', fileLink);
			updateParamUrl('group', groupLink); 
			updateParamUrl('layer', layerLink);                                       
			setFlyToPoint(flyLink);

			if (flyLink) {
				var flyArray = flyLink.split(','); 
				setFlyToPoint([parseFloat(flyArray[1]), parseFloat(flyArray[0])]);
			}
			setFlyToZoom(zoomLink);
			if ((fileLink)||(file.map_target)) {
				if (fileLink) {
					setFlyToFile(fileLinkInt); 
				} else {
					setFlyToFile(file.map_target);
				}
			}
			if (groupLink) {
				var groupLinkArray = groupLink.split(',');
				var groupIntArray = [];
				for (let i = 0; i < groupLinkArray.length; i++) {
					groupIntArray.push(parseInt(groupLinkArray[i]))
					}
				setFlyToGroup(groupIntArray);
			}
		  
			if (layerLink) {
				var layerLinkArray = layerLink.split(',');
				var layerIntArray = [];
				for (let i = 0; i < layerLinkArray.length; i++) {
					layerIntArray.push(parseInt(layerLinkArray[i]))
					}
				setFlyToLayer(layerIntArray);
			} 
		} else {
			if (link !== "") {
				props.history.push(link);
			}
		}
	}

	function handlePngParams() {
		let params = new URLSearchParams(props.history.location.search);
		let pngs = params.get('pngs');
		if (pngs) {
			let params = new URLSearchParams(props.history.location.search);
			let pngs = params.get('pngs');
			if (pngs.length > 1){
				var pngGroups = pngs.split(',').map(x=>+x);
				for (let i = 0; i < pngGroups.length; i++) {
					processPngGroupOverlay(parseInt(pngGroups[i]), false);
				}
			} else {
				processPngGroupOverlay(parseInt(pngs), false);
				
			}
		}
	}

	class ClickableMarker extends mapboxgl.Marker {
		onClick(handleClick) {
		  this._handleClick = handleClick;
		  return this;
		}
		_onMapClick(e) {
		  const targetElement = e.originalEvent.target;
		  const element = this._element;
		  if (this._handleClick && (targetElement === element || element.contains((targetElement)))) {
			this._handleClick();
		  }
		}
	  };

	// handles the process of changes base mapbox files
	function processFileChange(file){
		// console.log("processFileChange", file);
		// handleTurnOffOverlays();
		setActiveOverlays([]);

		if (prevActiveFile === null) {
			setPrevActiveFile(file.id);
		}
		setActiveFile(file.id);
		setActiveFileHeading(file.short_title);
		setActiveOverlayCategories(file.categories);
		setActiveOverlayGroups(file.overlaygroups);
		setPngGroups(file.pnggroups);
		

		updateParamUrl("file", file.id);
		var overlays = file.overlays;
		if (file.base_overlay) {
			overlays.push({
				"id": "override",
				"file": file.base_overlay,
				"file_type": "svg",
				"interactive": false
			});
		}
		setActiveOverlays(overlays);
	}

	function handleThumbnailClick(file){
		gsap.to(refMap.current, {opacity: 0, duration: 0})
		processFileChange(file);
		setShowLanding(false);
		gsap.to(refMap.current, {opacity: 1, duration: 0.3})
	}

	function flyToNewMap(file) {
		refMapState.current = 'moving';
		setMapState('moving');
		console.log('flyToNewMap', file, file.id, file.zoom, format);
		handleTurnOffOverlays();
		setActiveOverlays([]);
		// setActiveOverlayGroups([]);

		activeMap.setMinZoom(0)
		activeMap.setMaxZoom(100);
		

		activeMap.flyTo({
			center: [file.longitude, file.latitude],
			zoom: (format === 'iPad') ? (file.zoom_iPad) : (file.zoom),
			essential: true
		});

		if (refMapState.current === 'moving') {
			activeMap.once("idle", function (){
				refMapState.current = 'idle';
				setMapState('idle');
				activeMap.setMinZoom((format === 'iPad') ? (file.zoom_min_iPad) : (file.zoom_min))
				activeMap.setMaxZoom((format === 'iPad') ? (file.zoom_max_iPad) : (file.zoom_max));
				console.log('flyTo End', file.id, refMapState.current, file.zoom)
				

			});
		}

		
		setActiveOverlayCategories(file.categories);
		setActiveOverlayGroups(file.overlaygroups);
		setPngGroups(file.pnggroups);
		updateParamUrl("file", file.id);
		var overlays = file.overlays;
		if (file.base_overlay) {
			overlays.push({
				"id": "override",
				"file": file.base_overlay,
				"file_type": "svg",
				"interactive": false
			});
		}
		setActiveOverlays(overlays);
	
		// 	setActiveFile(file.id);
			
		// 	// setActiveFileHeading(file.short_title);
		// 	setActiveOverlayCategories(file.categories);
		// 	setActiveOverlayGroups(file.overlaygroups);
		// 	setPngGroups(file.pnggroups);
		// 	updateParamUrl("file", file.id);
		// 	var overlays = file.overlays;
		// 	if (file.base_overlay) {
		// 		overlays.push({
		// 			"id": "override",
		// 			"file": file.base_overlay,
		// 			"file_type": "svg",
		// 			"interactive": false
		// 		});
		// 	}
		// 	setActiveOverlays(overlays);
		
		// }
	}

	function turnOffSemiOn(){
		var labels = document.getElementsByClassName('choiceSemiOn');
		// console.log('turnOffSemiOn', labels);
		for (var i = 0; i < labels.length; i++) {
			labels[i]['status'] = "off";
			}
		
	
		setSelectedOverlayGroups([]);

		if (selectedOverlayGroups.length === 0) {
			removeParamUrl("links");
		}
	}



	// Effects

	// 1. Initial loading, sets files from props

	useEffect(()=>{
		setShowLanding(true);
		ref.current.style.display='block'
		gsap.from(ref.current,{duration:0.5,opacity:0,top:'80%', ease:Power2.easeInOut,onComplete:()=>{
		}});
	   
		(async ()=>{
			try { 
				setStatus('Loading JSON')
				if (props.model.mapboxes) {
					setFiles(props.model.mapboxes);
					
				}
				setStatus('Setting Map')
		   
			}catch(err){
		  
			}
		})();
		convertColors('rightNav');
		convertColors('rightNavMenu');
		var isIpad = document.body.classList.contains('ipad');
		if (isIpad) {
			setFormat('iPad');
		}
	},[])

	
	// 2. Setup Effects

	useEffect(()=>{ 
		setLoaded(true) 
		// console.log("files", files)
		if (files.length > 0) {	
			
			let params = new URLSearchParams(props.history.location.search);
			let file0 = params.get('file');
			if (file0){
				setShowLanding(false);
				activeFileId.current = parseInt(file0);
				var file = files.filter(item => ((item.id === parseInt(file0))))[0];
				if (file) {

					if (prevActiveFile === null) {
						setPrevActiveFile(file.id);
					}   
					setActiveFile(file.id);
					setActiveFileHeading(file.short_title);
					setActiveOverlayCategories(file.categories);
					setActiveOverlayGroups(file.overlaygroups);
					
					setPngGroups(file.pnggroups);
					var overlays = file.overlays;
					if (file.base_overlay) {
						overlays.push({
							"id": "override",
							"file": file.base_overlay,
							"file_type": "svg",
							"interactive": false
						});
					}
					setActiveOverlays(overlays);
					console.log('files loaded, processFileChange', file0);
				} else {
					activeFileId.current = files[0].id;
					processFileChange(files[0]);
					console.log('files loaded, processFileChange, no params')
				}
			} else {
				activeFileId.current = files[0].id;
				processFileChange(files[0]);

			}	
		}
	},[files]);

	useEffect(() => {
		if (activeFile && (files.length > 0)){
			var file = files.filter(item => ((item.id === activeFile)))[0];
			console.log("activeFile TEST 0", activeFile, file)
			if ((paramsProcessed === true) && file) {
				
				setActiveFileHeading(file.short_title);
				setActiveOverlayCategories(file.categories);
				setActiveOverlayGroups(file.overlaygroups);
				setPngGroups(file.pnggroups);
				setSelectedCategories([]);
				setSelectedOverlays([]);
				turnOffSemiOn();
				updateParamUrl("file", activeFile);
				removeParamUrl('group');
				removeParamUrl('layer');
				removeParamUrl('pngs');

				var overlays = file.overlays;
				if (file.base_overlay) {
					// console.log("activeFileMap.base_overlay", activeFileMap.base_overlay);
					overlays.push({
						"id": "override",
						"file": file.base_overlay,
						"file_type": "svg",
						"interactive": false
					});
				}
				setActiveOverlays(overlays);
				console.log("activeFile TEST 1", file);
			} else {

				// if (Globals.instance.serverApp) {
				// 	console.log("Globals.instance.serverApp", file);

				// }
				console.log("activeFile TEST 2", file);
				let params = new URLSearchParams(props.history.location.search);
				let group = params.get('group');
				if (group) {
					if (group.length > 1){
						var groupSet = group.split(',').map(x=>+x);
						setSelectedCategories(groupSet);
					} else {
						setSelectedCategories(group); 
					}
					
				} else {
					setParamsProcessed(true);
					
					
				}
				
			}

			const activeFileMap = files.filter(item => ((item.id === activeFile)))[0];
			console.log("activeFileMap", activeFileMap, activeFileMap.camera_longitude, activeMap)
			if ((activeMap === null) && activeFileMap && (activeFileMap.longitude)) {
				var map = new mapboxgl.Map({
					container: refMapContainer.current,
					pitchWithRotate: false,
					style: activeFileMap.style_url,
					center: [activeFileMap.longitude, activeFileMap.latitude],
					touchPitch: false,
					scrollZoom: (Globals.instance().serverApp)?false:true,
					doubleClickZoom: (Globals.instance().serverApp)?false:true,
					zoom: (format === 'iPad') ? (activeFileMap.zoom_iPad) : (activeFileMap.zoom),
					minZoom: (format === 'iPad') ? (activeFileMap.zoom_min_iPad) : (activeFileMap.zoom_min),
					maxZoom: (format === 'iPad') ? (activeFileMap.zoom_max_iPad) : (activeFileMap.zoom_max),
					dragPan: {
						linearity: (Globals.instance().serverApp ? 0 : 0),
						easing: t => t,
						deceleration: (Globals.instance().serverApp ? 2500 : 2500)
					}
				});

				console.log('zooms', activeFileMap.zoom_min, activeFileMap.zoom_max)
				map.dragRotate.disable();
				map.touchZoomRotate.disableRotation();

				// map.setMinZoom((format === 'iPad') ? (activeFileMap.zoom_min_iPad) : (activeFileMap.zoom_min));
				// map.setMaxZoom((format === 'iPad') ? (activeFileMap.zoom_max_iPad) : (activeFileMap.zoom_max))


				map.once("render", function(){
					setActiveMap(map);
					
					setTimeout(() => {
						map.resize();
						console.log('resize on render');
						// map.zoomTo((format === 'iPad') ? (activeFileMap.zoom_iPad) : (activeFileMap.zoom));
						// console.log('on idle');
					}, 250);


				});

				// map.once("idle", function(){
				// 	setTimeout(() => {
						
				// 	}, 250);
				// });
				

			} else {
				if (activeFileMap.camera_longitude) {
					refMapState.current = 'moving';
					setMapState('moving');
					// console.log('activeFileMap.camera_longitude')	

					activeMap.setMinZoom(0)
					activeMap.setMaxZoom(100);

					activeMap.flyTo({
						center: [activeFileMap.camera_longitude, activeFileMap.camera_latitude],
						zoom: (format === 'iPad') ? (activeFileMap.zoom_iPad) : (activeFileMap.zoom),
						essential: true
					});

					if (refMapState.current === 'moving') {
						activeMap.once("idle", function (){
							refMapState.current = 'idle';
							setMapState('idle');
							activeMap.setMinZoom((format === 'iPad') ? (activeFileMap.zoom_min_iPad) : (activeFileMap.zoom_min))
							activeMap.setMaxZoom((format === 'iPad') ? (activeFileMap.zoom_max_iPad) : (activeFileMap.zoom_max));
							console.log('flyTo End', refMapState.current, activeFileMap.zoom_min)
							
	
						});
					}
			
					
					handleTurnOffOverlays();
					
					let group = flyToGroup;
					if (group) {
						if (group.length > 1){
							var groupSet = group.split(',').map(x=>+x);
							setSelectedCategories(groupSet);
						} else {
							setSelectedCategories(group); 
						}
					   
					} 
					let layer = flyToLayer;
					if (layer) {
						if (layer.length > 1){
							var selectedOverlayParams = layer.split(',').map(x=>+x);
							setSelectedOverlays(selectedOverlayParams);
						} else {
							setSelectedOverlays(layer);
						}
					}
				}
			}
			
									
		}

		setTimeout(() => {
			const activeFileMap = files.filter(item => ((item.id === activeFile)))[0];
			if (activeFileMap) {
				activeFileMap.categories.forEach((obj) => {
					console.log('activeFile handle cat id', obj.id, activeOverlayGroups)
					setHandleCategoryId(obj.id)
				})
			}
			
		}, 300);
		
	}, [activeFile])


	useEffect(()=>{ 

		if (((activeOverlays)||(pngGroups))&&(activeMap)&&(activeFile)){
			
			console.log('activeFile, activeMap', activeFile, activeMap);

			if (activeOverlays.length > 0) {
				const activeFileMap = files.filter(item => ((item.id === activeFile)))[0];
				var center = [activeFileMap.longitude, activeFileMap.latitude];
				var map = activeMap;
			
				var activeOverlayLength = activeOverlays.length;

				let activeOverlayIds = [];
				activeOverlays.forEach((file, i) => {

					
					if (activeFileMap.default_on) {
						activeOverlayIds.push(file.id);
						if (activeOverlayLength === (i + 1)) {
							setSelectedOverlays(activeOverlayIds);
					
						}
					}
					
					if (file.latitude) {
						var markerContainer = document.createElement('div');
						if (file.interactive === false) {
							markerContainer.className = `${mapStyles.marker} ${mapStyles.markerOverlay} ${mapStyles.markerOn} nonInteractive`;
						} else {
							if (selectedOverlays.includes( file.id )){
								markerContainer.className = `${mapStyles.marker} ${mapStyles.markerOverlay} ${mapStyles.markerOn}`;
							} else {
								if (activeFileMap.default_on) {
									markerContainer.className = `${mapStyles.marker} ${mapStyles.markerOverlay} ${mapStyles.markerOn}`;
								} else {
									markerContainer.className = `${mapStyles.marker} ${mapStyles.markerOverlay}`;
								}
								
							}
						}

						markerContainer.id = "mapboxOverlay_" + file.id
						var imageURL = getContentPath(file.file);
						var ext = file.file.split('.').pop();
						
						if (ext === 'svg') {
							fetch(imageURL)
							.then(response => response.text())
							.then(result => {
								markerContainer.innerHTML = result;
								new mapboxgl.Marker({
									element: markerContainer,
									anchor: file.anchor
								})
									.setLngLat([file.longitude, file.latitude])
									.addTo(map);
							})
						} else {
							markerContainer.style.backgroundImage = 'url(' + imageURL + ')';
							markerContainer.style.backgroundSize = '100%';
							markerContainer.style.height = file.height;
							markerContainer.style.width = file.width;
							
							new ClickableMarker({
								element: markerContainer,
								anchor: file.anchor
								})
							.setLngLat([file.longitude, file.latitude])
							.onClick(() => { 
								handleMapFileClick(file);
								console.log('file', file);
							})
							.addTo(map);
						}

					} else  {
						var markerOverlayContainer = document.createElement('div');

						if (file.interactive === false) {
							markerOverlayContainer.className = `${mapStyles.markerOverlay} ${mapStyles.markerOn} nonInteractive`;
						} else {
							
							if (selectedOverlays.includes( file.id )){
								markerOverlayContainer.className = `${mapStyles.markerOverlay} ${mapStyles.markerOn}`;
							} else {
								if (activeFileMap.default_on) {
									markerOverlayContainer.className = `${mapStyles.markerOverlay} ${mapStyles.markerOn}`;
								} else {
									if (file.default_on) {
										markerOverlayContainer.className = `${mapStyles.markerOverlay} ${mapStyles.markerOn}`;
										var currOverlays = [ ...selectedOverlays ];
										currOverlays.push(file.id)
										setSelectedOverlays(currOverlays);
									} else {
										markerOverlayContainer.className = `${mapStyles.markerOverlay}`;
									}
									// markerOverlayContainer.className = `${mapStyles.markerOverlay}`;
								}	
									
								
							}
						}

						markerOverlayContainer.id = "mapboxOverlay_" + file.id;
						// console.log('file id', file.id);
						if (file.file_type === 'svg') {
							if (file.file) {
								var imageURL = getContentPath(file.file);
								fetch(imageURL)
								.then(response => response.text())
								.then(result => {
									markerOverlayContainer.innerHTML = result;
									var delay = 0;
									var duration = '1.5';
									var paths = markerOverlayContainer.getElementsByTagName('path');
										
									if (paths.length > 0) {
										if (file.delay) {
											var delay = file.delay;
										}
										if (file.duration) {
											var duration = file.duration;   
										}
										// path[0].setAttribute('style', 'animation-duration: ' + duration + 's; animation-delay: ' + delay + 's;');
										var pathsArray = [].slice.call(paths);
										   
										for (let i = 0; i < pathsArray.length; i++) {
											pathsArray[i].setAttribute('style', 'animation-duration: ' + duration + 's; animation-delay:' + (delay + (0.005 * i)) + 's');
										}
										
										if (file.link_target_url) {
											paths[0].setAttribute('data-target', file.link_target_url);
										}
										new ClickableMarker(markerOverlayContainer)
										.setLngLat(center)
										.onClick(() => { 
											console.log('clicked', file);
											handleMapFileClick(file);
										})
										.addTo(map);
									} 
								})
							}
						} 
						
						if (file.file_type === 'png') {
							var imageURL = getContentPath(file.file);
							var delay = 0;
							var duration = '1.5';
							var imageElem = "<img style='animation-duration: " + duration + 's; animation-delay:' + delay + "s; src='" + imageURL +  "' />";
							markerOverlayContainer.innerHTML = imageElem
							new ClickableMarker(markerOverlayContainer)
							.setLngLat(center)
							.onClick(() => { 
								handleMapFileClick(file);
							})
							.addTo(map);
						}

						function getScale(fileWidth, fileHeight) {
							var center = map.getCenter();
							var zoom = map.getZoom() + (Math.round(activeFileMap.zoom/activeFileMap.zoom_min, 0));
							var metersPerPixel = 156543.03392 * Math.cos(center.lat * Math.PI/180) / Math.pow(2, zoom);
							var meterSizeInPixelWidth = fileWidth/metersPerPixel;
							var meterSizeInPixelHeight = fileHeight/metersPerPixel;
							return [meterSizeInPixelWidth, meterSizeInPixelHeight];
						}

						function render() {
							var pixelSizes = getScale(
								activeFileMap.width,
								activeFileMap.height
							);
							markerOverlayContainer.style.width = pixelSizes[0] + "px";
							markerOverlayContainer.style.height = pixelSizes[1] + "px";
						}

						map.on("viewreset", render);
						map.on("zoom", render);
						map.on("drag", render);
						map.on("rotate", render);

						render();
						
					}

					if (i === (activeOverlayLength - 1)) {  
						setOverlaysLoaded(true);
						
					}
					
					
				});
				if (activeOverlayGroups.length > 0){
			
					const activeFileMap = files.filter(item => ((item.id === activeFile)))[0];
					var center = [activeFileMap.longitude, activeFileMap.latitude];
					var map = activeMap;
					var activeOverlayGroupSvgIds = [];
					activeOverlayGroups.forEach((file, i) => {
					
						var newId = "mapboxOverlayGroup_" + file.id;
						var exists = document.getElementById(newId);
				
						if (!exists) {
						
							var markerOverlayGroupContainer = document.createElement('div');
							console.log('exists', markerOverlayGroupContainer);
							if (selectedOverlays.includes( file.id )){
								markerOverlayGroupContainer.className = `${mapStyles.markerOverlayGroup} ${mapStyles.markerOn}`;
							} else {
								markerOverlayGroupContainer.className = `${mapStyles.markerOverlayGroup}`;

								if (activeFileMap.default_on) {
									setTimeout(() => {
										markerOverlayGroupContainer.classList.add(`${mapStyles.svgReady}`)
									}, 1000);
								}
								
							}
		
							markerOverlayGroupContainer.id = newId;
		
							var imageURL = getContentPath(file.file);
							fetch(imageURL)
							.then(response => response.text())
							.then(result => {
								markerOverlayGroupContainer.innerHTML = result;
								var delay = 0;
								var duration = '1.5';
								
								var paths = markerOverlayGroupContainer.getElementsByTagName('path');
								// console.log('pathsArray', paths);
								if (paths.length > 0) {
								
									new mapboxgl.Marker(markerOverlayGroupContainer)
									.setLngLat(center)
									.addTo(map);
		
									var svg_ids = file.svg_ids.split(",");
									for (var i = 0; i < svg_ids.length; i++) {
										var svgId = svg_ids[i];

										activeOverlayGroupSvgIds.push(svgId);
										// console.log('svgId', svgId);
										// eslint-disable-next-line no-loop-func
										const overlayFile = activeOverlays.filter(item => ((item.svg_id === svgId)))[0];
										// console.log('activeOverlays', activeOverlays);
										if (overlayFile) {
											// console.log('overlayFile', overlayFile);
											if (overlayFile.delay) {
												var delay = overlayFile.delay;
											}
											if (overlayFile.duration) {
												var duration = overlayFile.duration;   
											}
											
											var pathsArray = [].slice.call(paths);
										   
											for (let i = 0; i < pathsArray.length; i++) {
												pathsArray[i].setAttribute('style', 'animation-duration: ' + duration + 's; animation-delay:' + delay + 's');
											}

											var target = markerOverlayGroupContainer.querySelector("#" + svgId);
											// let groupIds = [ ...selectedOverlayGroups ];
											// groupIds.push(svgId)
											if (activeFileMap.default_on) {
												target.classList.add(`${mapStyles.markerOn}`);
												// console.log('activeFileMap', activeFileMap.default_on, target)
											}
										
										

											target.addEventListener('click', function(){
												
												handleMapFileClick(overlayFile);
											});

											
										} 
									}
									
									// console.log('activeOverlayGroupSvgIds', activeOverlayGroupSvgIds)
								
									
								} 
							})
							
							function getScale(fileWidth, fileHeight) {
								var center = map.getCenter();
								var zoom = map.getZoom() + (Math.round(activeFileMap.zoom/activeFileMap.zoom_min, 0));
								var metersPerPixel = 156543.03392 * Math.cos(center.lat * Math.PI/180) / Math.pow(2, zoom);
								var meterSizeInPixelWidth = fileWidth/metersPerPixel;
								var meterSizeInPixelHeight = fileHeight/metersPerPixel;
								return [meterSizeInPixelWidth, meterSizeInPixelHeight];
							}
		
							function render() {
								var pixelSizes = getScale(
									activeFileMap.width,
									activeFileMap.height
								);
								markerOverlayGroupContainer.style.width = pixelSizes[0] + "px";
								markerOverlayGroupContainer.style.height = pixelSizes[1] + "px";
							}
							map.on("viewreset", render);
							map.on("zoom", render);
							map.on("drag", render);
							map.on("rotate", render);
							render();

							if (activeOverlayGroups.length === (i + 1)) {
								map.on("render", function(){
									// console.log('loaded', activeOverlayGroups.length)
									setOverlayGroupsLoaded(true);
								});

								if (activeFileMap.default_on) {
									setSelectedOverlayGroups(activeOverlayGroupSvgIds);
									
									console.log('activeOverlayGroupSvgIds', activeOverlayGroupSvgIds)
									
								}

							}
						}
					});
					
				}

				
			}

				if (pngGroups && (pngGroups.length > 0)){
					var map = activeMap;
					var newPopups = [ ...popUps ];
					let pngGroupIds = [];
					const activeFileMap = files.filter(item => ((item.id === activeFile)))[0];
					pngGroups.forEach((group, i) => {

						if (activeFileMap.default_on) {
							pngGroupIds.push(group.id);
							if (pngGroups.length === (i + 1)) {
								setSelectedPngGroups(pngGroupIds);
								console.log('pngGrp ids', pngGroupIds);
								
							}
						}

						

						group['pngs'].forEach((file, i) => {
							var markerContainer = document.createElement('div');
							
							var imageURL = getContentPath(file.file);
							markerContainer.style.backgroundImage = 'url(' + imageURL + ')';
							markerContainer.style.backgroundSize = '100%';
							markerContainer.style.height = file.height;
							markerContainer.style.width = file.width;

							if (file.popup_text) {
								
								if (activeFileMap.default_on) {
									markerContainer.className = `${mapStyles.marker} markerPopup ${'pngGroup-' + group.id} markerOn`;
								} else {
									markerContainer.className = `${mapStyles.marker} markerPopup ${'pngGroup-' + group.id} `;
								}
								const popup = new mapboxgl.Popup({ 
													offset: 25,
													className: `${mapStyles.popup}`,
													focusAfterOpen: false,
													closeButton: false,
													closeOnClick: false,
													})
													.setHTML("<p>" + file.popup_text + "</p>")

								function handleMarkerClick(marker) {
									marker.togglePopup();
								}
								
								const marker = new ClickableMarker({
										element: markerContainer,
										anchor: file.anchor
										})
									.setLngLat([file.longitude, file.latitude])
									.setPopup(popup)
									.onClick(() => { 
										handleMarkerClick(marker);
									})
									.addTo(map);
								
					   
								newPopups.push({'id': group.id, 'marker': marker, 'popup': popup, 'zoom': file.popup_zoom});
								
								if (i === pngGroups.length - 1) {
									setPopUps(newPopups);
								}
							} else {
								markerContainer.className = `${mapStyles.marker} markerPopup ${'pngGroup-' + group.id}`;
								
								new mapboxgl.Marker(markerContainer) 
									.setLngLat([file.longitude, file.latitude])
									.addTo(map);
							}
							
						});   
						if (i === pngGroups.length - 1) {
							let params = new URLSearchParams(props.history.location.search);
							let pngs = params.get('pngs');
							if (pngs) {
								handlePngParams();
								
							}
						}
					});
	
				}

				if (Globals.instance().serverApp) {

					function buildArray(command) {
						let objArray = command.split(",");
						let overlays = [];
						objArray.forEach((obj) => {
							if (!overlays.includes(obj)) {
								overlays.push(parseInt(obj))
							}
							
						});
						return overlays
					}
	
					function buildStrArray(command) {
						let objArray = command.split(",");
						let overlays = [];
						objArray.forEach((obj) => {
							overlays.push(obj)
						});
						return overlays
					}
	
					window.removeEventListener('server-event', refServerAppListener.current)
					const serverAppListener = (evt)=>{
						let detail = evt.detail
						if(!detail)return
						if(detail.event==='module-mapbox-select-map'){ 
							var file = files.filter(item => ((item.id === parseInt(detail.command))))[0];
							// flyToNewMap(file);
							handleTurnOffOverlays();
							processFileChange(file);
							activeFileId.current = file.id;
							// setActiveFile(file.id);
							console.log('module-mapbox-select-map received', detail.command, file)
							setShowLanding(false);
						}
	
						if(detail.event==='module-mapbox-select-overlays'){ 
							console.log('module-mapbox-select-overlays received', detail.command)
							let result = buildArray(detail.command);
							console.log('module-mapbox-select-overlays overlays', result)
							setSelectedOverlays(result);
							
						}
	
						if(detail.event==='module-mapbox-select-overlaygroups'){ 
							console.log('module-mapbox-select-overlaygroups received', detail.command)
							let result = buildStrArray(detail.command);
							console.log('module-mapbox-select-overlays overlaygroups', result)
							setSelectedOverlayGroups(result);
						}
	
						if(detail.event==='module-mapbox-select-categories'){ 
							console.log('module-mapbox-select-categories received', detail.command)
							let result = buildArray(detail.command);
							console.log('module-mapbox-select-categories overlays', result)
							setSelectedCategories(result);
						}
	
						if(detail.event==='module-mapbox-select-pnggroups'){ 
							console.log('module-mapbox-select-pnggroups received', detail.command)
							let result = buildArray(detail.command);
							console.log('module-mapbox-select-pnggroups pnggroups', result)
							setSelectedPngGroups(result);
						}
	
						if(detail.event==='module-mapbox-select-targetlayerid'){ 
							console.log('module-mapbox-select-targetlayerid received', detail.command)
							let objArray = detail.command.split(",");
							let overlays = [];
							objArray.forEach((obj) => {
								switch (obj) {
									case "null":
										overlays.push(null)
										break;
									case "true":
										overlays.push(true)
										break;
									case "false":
										overlays.push(false)
										break;
									default:
										overlays.push(obj)
								}
								
							});
							console.log('module-mapbox-select-targetlayerids targetlayerid', overlays)
							setTargetLayerId(overlays);
						}
	
						if(detail.event==='module-mapbox-select-targetpnggroupid'){ 
							console.log('module-mapbox-select-targetpnggroupid received', detail.command)
							let objArray = detail.command.split(",");
							let overlays = [];
							objArray.forEach((obj) => {
								overlays.push(obj)						
							});
							console.log('module-mapbox-select-targetpnggroupid targetpnggroupid', overlays)
							setTargetPngGroupLayerIds(overlays);
						}
	
						if(detail.event==='module-mapbox-select-turnoffarray'){ 
							console.log('module-mapbox-select-turnoffarray received', detail.command)
							let objArray = detail.command.split(",");
							let method = objArray[objArray.length - 1];
							let overlays = [];
							objArray.forEach((obj, i) => {
								if (i < (objArray.length - 1)) {
									overlays.push(obj)
								}
														
							});
							console.log('module-mapbox-select-turnoffarray', [overlays, method])
							setTurnOffLayerArray([overlays, method]);
						}
	
						if(detail.event==='module-mapbox-control'){
							const activeFileMap = files.filter(item => ((item.id === activeFileId.current)))[0];
							switch (detail.command) {
								case 'reset':
									refMapState.current = 'moving';
									setMapState('moving');
									activeMap.setMinZoom(0)
									activeMap.setMaxZoom(100);

									activeMap.flyTo({
										center: [activeFileMap.longitude, activeFileMap.latitude],
										zoom: (format === 'iPad') ? (activeFileMap.zoom_iPad) : (activeFileMap.zoom)
									});

									if (refMapState.current === 'moving') {
										activeMap.once("idle", function (){
											refMapState.current = 'idle';
											setMapState('idle');
											activeMap.setMinZoom((format === 'iPad') ? (activeFileMap.zoom_min_iPad) : (activeFileMap.zoom_min))
											activeMap.setMaxZoom((format === 'iPad') ? (activeFileMap.zoom_max_iPad) : (activeFileMap.zoom_max));
											console.log('flyTo End', refMapState.current, activeFileMap.zoom_min)
											

										});
									}



									break;
								case 'zoom-in':
									activeMap.zoomIn({duration: 1000});
									console.log('module-mapbox-zoom-in', activeFileMap);
									break;
								case 'zoom-out':
									activeMap.zoomOut({duration: 1000});
									console.log('module-mapbox-zoom-out', activeFileMap);
									break;
								default:
									{}                                
									// console.log('no command')
							}
						}
	
						if(detail.event==='module-mapbox-management'){
							console.log('module-mapbox-management received', detail.command)
							switch (detail.command) {
								case 'turnoffalllayers':
									setTurnOffAllLayers(true);
									console.log('turnoffalllayers received')
									break;
								case 'turnoffradiolayers':
									setTurnOffRadioLayers(true);
									console.log('turnoffradiolayers received')
									break;
								case 'turnoffoverlayparamLayers':
									let objArray = detail.command.split(",");
									if (objArray) {
										setTurnOffOverlayParamLayers(objArray[1]);
									}	
									console.log('turnoffradiolayers received')
									break;
								case 'closemodule':
									setTurnOffAllLayers(true);
									setTimeout(() => {
										processFileChange(files[0])
									}, 250);
									
									
								
									break;
								default:
									{}                                
							}
						}
					}
					refServerAppListener.current = serverAppListener
					window.addEventListener('server-event', refServerAppListener.current)
					
				}


			
		}
	},[activeOverlays, activeMap, activeFile, activeOverlayGroups, pngGroups]);

// useEffect(() => {
// 	if ((activeOverlayGroups.length > 0) && activeFile) {
// 		// setTimeout(() => {
// 		// 	const activeFileMap = files.filter(item => ((item.id === activeFile)))[0];
// 		// 	if (activeFileMap) {
// 		// 		activeFileMap.categories.forEach((obj) => {
// 		// 			console.log('activeFile handle cat id', obj.id, selectedOverlayGroups)
// 		// 			setHandleCategoryId(obj.id)
// 		// 		})
// 		// 	}
			
// 		// }, 300);
// 		console.log('activeOverlayCategories', activeOverlayGroups)
// 	}
// }, [activeOverlayGroups, activeFile])


// 3. Interaction Effects

	useEffect(() => {
		if ((popUps)&&(selectedPngGroups)&&(activeMap)) {
			if (popUps.length > 0) {

				// console.log('popUps', popUps, selectedPngGroups);
				popUps.forEach((item, i) => {
					function renderPopup() {
						const isOpen = item['popup'].isOpen();
						if (selectedPngGroups.includes(item['id'])) {
							var zoom = activeMap.getZoom();
							// console.log("renderPopup YES")
							if (zoom >= item['zoom']) {
								if (!isOpen) {
									item['marker'].togglePopup();
								}
								
							} else {
								if (isOpen) {
									item['marker'].togglePopup();
								}
							}
							
						} else {
							if (isOpen) {
								// console.log("renderPopup NO")
								item['marker'].togglePopup();
							}
						}
					}
					activeMap.on("zoomend", renderPopup);
					renderPopup();
				});
			
			}
			
		}
		
	}, [popUps, selectedPngGroups, activeMap])

	useEffect(()=>{ 
		if (selectedCategories) { 

			console.log('selectedCategories', selectedCategories)

			const unique = (value, index, self) => {
				return self.indexOf(value) === index
			  }
			var currSelectedCategories = [];
			if (selectedCategories.length > 1) {
				selectedCategories.forEach(objID => {
					var id = parseInt(objID);
					currSelectedCategories.push(id)
				});
			} else {
				currSelectedCategories.push(parseInt(selectedCategories[0]))
			}
			
			if ((overlaysLoaded === true)&&(paramsProcessed === true)) {
				if ((currSelectedCategories.filter(unique)) > 0) {
					updateParamUrl("group", currSelectedCategories.filter(unique));
				} else {
					removeParamUrl('group');
				}
			   
				selectedOverlays.forEach(objID => {
					var target = document.getElementById("mapboxOverlay_" + objID);
					if (target) {
						target.classList.add(`${mapStyles.markerOn}`);
					}  
				});
			}

			setTimeout(() => {
				selectedOverlays.forEach(objID => {
					var target = document.getElementById("mapboxOverlay_" + objID);
					if (target) {
						target.classList.add(`${mapStyles.markerOn}`);
					}  
				});
			}, 250);
			
			if (paramsProcessed === false) {
				let params = new URLSearchParams(props.history.location.search);
				let layer = params.get('layer');
				// let paramsString = 
				if (layer) {
					if (layer.length > 1){
						var selectedOverlayParams = layer.split(',').map(x=>+x);
						setSelectedOverlays(selectedOverlayParams);
					} else {
						setSelectedOverlays(layer);
					}
				}

				let links = params.get('links');       
				if (links) {
					let linksArray = links.split(',');
					if (linksArray.length > 1){
						setSelectedOverlayGroups(linksArray);

					} else {
						// console.log('selectedOverlayGroupParams links', linksArray, linksArray.length);
						setSelectedOverlayGroups([links]);
					} 
				}

				if (Globals.instance().serverApp) {

					ControllerServerComs.instance().postMessageFromServerToElectron('params', props.history.location.search);
					console.log("props.history.location.search", props.history.location.search)
					
				}	
			}
		} 

	},[selectedCategories]);

	useEffect(()=>{ 
		if (overlaysLoaded === true) {
			if (selectedOverlays.length > 0) {
				// updateParamUrl("layer", selectedOverlays.join());        
			} else {
				removeParamUrl('group');
				removeParamUrl('layer');
				turnOffSemiOn();
			}
			
		}
		if (selectedOverlays.length > 0) {
			var layerIDs = [];
			selectedOverlays.forEach((objID, i) => {
				var overlay = activeOverlays.filter(item => ((item.id === objID)))[0];
				if (overlay) {
					if (overlay.svg_id !== null) {
						
					} else {
						layerIDs.push(objID)
					}
					if (i === (selectedOverlays.length - 1)) {
						updateParamUrl("layer", layerIDs);
					}
				}
			});

			if (paramsProcessed === false) {
				if (selectedCategories.length > 0) {
					if (activeOverlayCategories.length > 0){
						if (selectedCategories.length > 1) {
							selectedCategories.forEach(objID => {
								setHandleCategoryId(objID);
							});
						} else {
							setHandleCategoryId(parseInt(selectedCategories[0]));
						}
					}
				}
			   
			}
		}

	},[selectedOverlays]);

	useEffect(()=>{ 
		if (selectedOverlayGroups.length > 0) {
			// console.log('selectedOverlayGroups', selectedOverlayGroups.length, selectedOverlayGroups);
			if (selectedOverlayGroups.length === 1) {
				updateParamUrl("links", selectedOverlayGroups);
			} else {
				updateParamUrl("links", selectedOverlayGroups.join());
			}
			// console.log('line 1097, selectedCategories', selectedCategories[0], activeOverlayCategories);
			if (paramsProcessed === false) {
				if (selectedCategories.length > 0) {
					if (activeOverlayCategories.length > 0){
						if (selectedCategories.length > 1) {
							selectedCategories.forEach(objID => {
								setHandleCategoryId(objID);
							});
						} else {
							setHandleCategoryId(parseInt(selectedCategories[0]));
						} 
					}
				}
			}

			setTimeout(() => {
				if (selectedOverlayGroups.length > 0) {
					selectedOverlayGroups.forEach(svgLayerID => {
					 var targetGroup = document.getElementById(svgLayerID);
						if (targetGroup) { 
							var svgParent = targetGroup.parentElement.parentElement;
							svgParent.classList.add(`${mapStyles.svgReady}`);
							targetGroup.classList.add(`${mapStyles.markerOn}`);
						}
						console.log('targetGroup', targetGroup, svgLayerID);
					});
				}

			}, 250);
		}
	},[selectedOverlayGroups]);


	useEffect(() => {

		if ((overlayGroupsLoaded === true)&&(selectedOverlayGroups.length > 0)&&(activeMap)) {
			// console.log('overlayGroupsLoaded, selectedOverlayGroups', overlayGroupsLoaded, selectedOverlayGroups);

			if (selectedOverlayGroups.length > 0) {
				updateParamUrl("links", selectedOverlayGroups.join());        
			} else {
				removeParamUrl('links');
			}
			// console.log('overlayGroupsLoaded, targetGroup, line 1048: ', selectedOverlayGroups);  
			setTimeout(() => {
				if (selectedOverlayGroups.length > 0) {
					selectedOverlayGroups.forEach(svgLayerID => {
					 var targetGroup = document.getElementById(svgLayerID);
						if (targetGroup) { 
							var svgParent = targetGroup.parentElement.parentElement;
							svgParent.classList.add(`${mapStyles.svgReady}`);
							targetGroup.classList.add(`${mapStyles.markerOn}`);
						}
					});
				}

			}, 250);
		} 

		// console.log('selectedOverlayGroups loaded', selectedOverlayGroups);

	}, [overlayGroupsLoaded, selectedOverlayGroups, activeMap])

   useEffect(() => {
	if ((targetLayerId)&&(targetLayerId.length > 0)&&(activeMap)) {
		let target;
		if (targetLayerId[2]) {
			
			// turnOffAllLayers();
			if (targetLayerId[1]) {
				target = document.getElementById(targetLayerId[1]);
				if (target) {
					var svgParent = target.parentElement.parentElement;
					svgParent.classList.add(`${mapStyles.svgReady}`);
					target.classList.add(`${mapStyles.markerOn}`);
				}
			} else {
				if (targetLayerId[0]) {
					target = document.getElementById(targetLayerId[0]);
					if (target) {
						target.classList.add(`${mapStyles.markerOn}`);
					}
				}
				
			}
		} else {

			if (targetLayerId[1]) {
				target = document.getElementById(targetLayerId[1]);
				if (target) {
					target.classList.remove(`${mapStyles.markerOn}`);
				}
				console.log('target', target, targetLayerId[1]);
			}
			if (targetLayerId[0]) {
				target = document.getElementById(targetLayerId[0]);
				if (target) {
					target.classList.remove(`${mapStyles.markerOn}`);
				}
			}
		}
	}
	// console.log('targetLayerId received', targetLayerId)
   }, [targetLayerId])

   useEffect(() => {
		if (targetPngGroupLayerIds) {
			
			var targets = document.getElementsByClassName("pngGroup-" + targetPngGroupLayerIds[0]);
			var targetArray = [].slice.call(targets);
			if (targetPngGroupLayerIds[1] === 'off') {
				for (let i = 0; i < targetArray.length; i++) {
					targetArray[i].classList.remove('markerOn');
				}
			} else {
				for (let i = 0; i < targetArray.length; i++) {
					targetArray[i].classList.add('markerOn');
				}
			}
			console.log('targetPngGroupLayerIds', targetPngGroupLayerIds);
			
		}
		
   }, [targetPngGroupLayerIds])

   useEffect(() => {
	if (runTurnOffAllLayers) {
		turnOffAllLayers(true);
		console.log('runTurnOffAllLayers state', runTurnOffAllLayers)
		
	}
   }, [runTurnOffAllLayers, turnOffAllLayers])

   useEffect(() => {
	if (runTurnOffOverlayParamLayers) {
		turnOffOverlayParamsFiles(runTurnOffOverlayParamLayers) 
		console.log("runTurnOffOverlayParamLayers", runTurnOffOverlayParamLayers)
		setTurnOffOverlayParamLayers(false);
	}

   }, [runTurnOffOverlayParamLayers, turnOffOverlayParamsFiles])


   useEffect(() => {
	if (runTurnOffLayerArray && (runTurnOffLayerArray[0].length > 0)) {
		
		setTimeout(() => {
			if (runTurnOffLayerArray[1] === 'id') {
				runTurnOffLayerArray[0].forEach((obj) => {
					var target = document.getElementById(obj);
					if (target) {
						target.classList.remove(`${mapStyles.markerOn}`);
					}
				});
				// console.log('runTurnOffLayerArray', runTurnOffLayerArray);
			}
	
			if (runTurnOffLayerArray[1] === 'class') {
				runTurnOffLayerArray[0].forEach((obj) => {
					var targets = document.getElementsByClassName(obj);
					var targetArray = [].slice.call(targets);
					for (let i = 0; i < targetArray.length; i++) {
						targetArray[i].classList.remove('markerOn');
					}
				});
				// console.log('runTurnOffLayerArray', runTurnOffLayerArray);
			}
		}, 250);
	}
	
	}, [runTurnOffLayerArray])

	useEffect(() => {
		if ((flyToPoint)&&(flyToZoom)&&(flyToAnim === true)) {
			 if (flyToFile) {
				 setActiveOverlayGroups([]);
				 setActiveFile(flyToFile);
			 }
			 setFlyToAnim(false);
		 }
	 }, [flyToAnim])
 
	 useEffect(() => {
		 if (flyToFile) {
			 setFlyToAnim(true);
		 }
	 }, [flyToFile])
 

    // useEffect(() => {
    //     // console.log("activeOverlayCategories", activeOverlayCategories, activeFile)
	// 	if (activeFile && (activeOverlayCategories.length > 0)) {
	// 		activeOverlayCategories.forEach((obj) => {
	// 			setTimeout(() => {
	// 				setHandleCategoryId(obj.id)
	// 			}, 300);
				
	// 		});
	// 		console.log(activeOverlayCategories)
	// 	} 
		
		
	// 	// activeFile && setSelectedOverlayGroups([8]);

    // }, [activeFile])

	return (
		<div className={`fullscreen fcenter ${mstyles.moduleHolder}` } ref={ref}>
			{(!loaded)?(<div className={`fullscreen fcenter`}>
			<div>
				<LoaderWheel status={status}></LoaderWheel>
			</div>
			 </div>):null}

			 <section id="mapContainer" className={`${mapStyles.mapContainer}`}>

                {(showLanding && files.length > 1) ? 
                    (<div className={`${mapStyles.landingContainer}`}>
						{( props.model.background_image && <img src={getContentPath(props.model.background_image)} alt="" className={`${mapStyles.backgroundImage}`}/>)}
                        <div className={`${mapStyles.letterbox}`}>
                            <div className={`${mapStyles.heading}`}>
                                <span style={(Globals.instance().getThemeStyle(['module-landing-primary'],props.model.theme))}>{props.model.title} Maps</span>
                            </div>
                            <div className={`${mapStyles.itemsContainer}`}>
                                {files.map((category, i) => (
                                <div 
                                    className={`${mapStyles.itemContainer}`}
                                    key={'gallery_thumb_' + i}
									onClick={()=> handleThumbnailClick(category)}
                                >
                                    <img src={getContentPath(category.thumbnail?category.thumbnail:category.basemap_img)} alt=""></img>
                                    <div className={`${mapStyles.caption}`}>
									<span style={(Globals.instance().getThemeStyle(['module-landing-primary'],props.model.theme))}>{category.title}</span></div>
                                </div>
                                ))}
                            </div>
							
                        </div>
			
                    </div>)
                    : null }
			
		

					{activeFile && <div className={`${mapStyles.frame}`} ref={refMap}>

					
						{files
							.filter((file) => file.id === activeFile)
							.map((file, i) => (
							<div 
								key={'map_basemap_' + i} 
								className={`${mapStyles.fullFile} ${(activeFile === file.id)?(mapStyles.fileOn):("")}`}
							>
								{(!Globals.instance().serverApp) && 
								<div className={`${mapStyles.mapControlsContainer}`}>
									<div className={`${mapStyles.mapControls}`}>
										<div onClick={() => handleZoomOutClick()} className={`${mapStyles.zoomOutBtn} ${(mapState === 'moving')?(mapStyles.disabled):''}`}>
											<ZoomOut></ZoomOut>
										</div>
										<div onClick={() => handlePinClick(activeFile)} className={`${mapStyles.pinBtn} ${(mapState === 'moving')?(mapStyles.disabled):''}`}>
											<Pin></Pin>
										</div>
										<div onClick={() => handleZoomInClick()} className={`${mapStyles.zoomInBtn} ${(mapState === 'moving')?(mapStyles.disabled):''}`}>
											<ZoomIn></ZoomIn>
										</div>
									</div>
								</div>}
								<object aria-label="mapbox" ref={refMapContainer}></object>
							</div>
							))} 

						</div>}
						
	
						{(!Globals.instance().serverApp) &&
						<MapBoxMenu 
							model={props.model} 
							files={files}
							history={props.history}
							
							menuOpen={menuOpen}
							setMenuOpen={setMenuOpen}
							navOpen={navOpen}
							activeFile={activeFile}
							activeFileHeading={activeFileHeading}
							activeOverlayCategories={activeOverlayCategories}
							activeOverlays={activeOverlays}
							pngGroups={pngGroups}
							paramsProcessed={paramsProcessed}
							radioObj={radioObj}

							defaultOn={props.model.default_on}
							selectedOverlays={selectedOverlays}
							selectedOverlayGroups={selectedOverlayGroups}
							selectedCategories={selectedCategories}
							selectedPngGroups={selectedPngGroups}
							handleCategoryId={handleCategoryId}
				
							setActiveOverlayCategories = {setActiveOverlayCategories}
							setActiveOverlays = {setActiveOverlays}
							setActiveFileHeading = {setActiveFileHeading}
							setActiveFile = {setActiveFile}
							setPngGroups = {setPngGroups}
							setHandleCategoryId = {setHandleCategoryId}
							setRadioObj = {setRadioObj}

							setSelectedOverlays={setSelectedOverlays}
							setSelectedOverlayGroups={setSelectedOverlayGroups}
							setSelectedCategories={setSelectedCategories}
							setSelectedPngGroups={setSelectedPngGroups}
							setParamsProcessed={setParamsProcessed}

							setTargetLayerId={setTargetLayerId}
							setTargetPngGroupLayerIds={setTargetPngGroupLayerIds}
							setTurnOffAllLayers={setTurnOffAllLayers}
							setTurnOffRadioLayers={setTurnOffRadioLayers}
							setTurnOffOverlayParamLayers={setTurnOffOverlayParamLayers}
							setTurnOffLayerArray={setTurnOffLayerArray}
						/>}

						{(!Globals.instance().serverApp) && 
						<div className={`${mapStyles.rightNavBtn2} ${(navOpen)?(mapStyles.navOpen):(null)}`}>
							<div className={`round-btn`} onClick={() => onClickHide()} style={Globals.instance().getThemeStyle(['nav-menu-btn'],props.theme)}>
								<TrayClose></TrayClose>
							</div>
						</div>
						}

						<div className={`${mstyles.closeBtnContainer} ${mapStyles.closeBtnContainer}`}>
						<ToolsMenu theme={props.theme} setShowDrawControls={props.setShowDrawControls} model={props.model}  setShowMarkup={props.setShowMarkup} location={props.history.location}
								></ToolsMenu>
						
							<div className={`round-btn`} onClick={onClickClose} style={Globals.instance().getThemeStyle(['nav-menu-btn'],props.theme)}>
								<CloseButton></CloseButton>
							</div>
					</div>
			
				
				</section>
		</div>
	)

})

export default MapBoxModule