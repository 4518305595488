import { createStore,applyMiddleware } from "redux";
import promise from "redux-promise-middleware"
import rootReducer from "./reducers/index";
import { createLogger } from 'redux-logger';

const store = createStore(
    rootReducer,applyMiddleware(promise,createLogger({collapsed:true}))
);


export default store;