import Default from './default'
import * as THREE from 'three'
import SixSixtyCamManager from './SixSixtyCamManager'

export default class SixSixtyProject extends Default{

    constructor(){ 
        super()
        console.log("Six Sixty")
        this.skybox_folder='skybox2'
    }


    getCamManager(){ return new SixSixtyCamManager(this)}


    traverseMesh(child,meshDefault){
        if (child.name.indexOf('Line018') === 0) {
            // child.visible = false;
            child.material.emissiveIntensity=10
            child.material.emissive = new THREE.Color(1,1,1)
        }
        else if (child.name.indexOf('Backpanel-lopoly') === 0) {
             child.material.emissive = new THREE.Color(1, 1, 1)
            child.material.emissiveIntensity = 0.0
             child.material.receiveShadow = true
        }
        else if (child.name.indexOf('highlight-signage') === 0) {
            child.visible=false
            this.signage=child
        }
        else if (child.name.indexOf('highlight-lobby') === 0) {
            child.visible=false            
            this.lobby=child
        }
        else
            super.traverseMesh(child,meshDefault)
    }
    setShadow(l){
        super.setShadow(l)
        l.shadow.bias=0.0001
    }

    cleanup(){
        super.cleanup()
        this.lobby=undefined
        this.signage=undefined

    }
}