
import React,{useState,useRef,memo,useCallback,useEffect} from 'react'
import {getContentPath} from  'system/AssetManager'
import {animateParallax,getAnimationModel,animateIntersect,setInitialAnimIntersectState,setFinalAnimIntersectState} from './CanvasAnimator'
import Globals from 'system/Globals'
import history from 'system/navigation/MainRouterHistory'
import {
    Link
} from "react-router-dom";

function PNGComponent(props){
    
    
    let bLoaded=false
    const [inline,setInline] = useState({})
    const [imgSrc,setImgSrc] = useState("")
    const refEle = useRef()
    const refFormat = useRef()
    const refOrigOffset = useRef(0)
    const refAnimationModel = useRef({})
    // let history = useHistory();

    useEffect(()=>{ 
        refOrigOffset.current=refEle.current.offsetLeft
        if(refFormat.current && refFormat.current.animation){
            refAnimationModel.current = getAnimationModel(refEle.current,refFormat.current.animation) 
            if(props.renderOnly===true){
                setFinalAnimIntersectState(refEle.current,refFormat.current.animation)
            }
        }
       
    },[inline]) // eslint-disable-line react-hooks/exhaustive-deps
    
    const onScroll = useCallback((scrollLeft,scale,pageWidth)=>{
        animateParallax(refEle.current,refAnimationModel.current,scrollLeft,pageWidth)
    },[props.model.animation,refEle])// eslint-disable-line react-hooks/exhaustive-deps

    const onIntersect=  useCallback((evt)=>{
        if(evt.detail.isIntersecting )
        {
            if(evt.detail.intersectionRatio >=0.5)
                animateIntersect(refEle.current,refAnimationModel.current)  
        }
        else if(evt.detail.intersectionRatio <=0.001)
            setInitialAnimIntersectState(refEle.current,refAnimationModel.current)
    },[refEle])

    useEffect(()=>{

        console.log("PNGButton props", props)
        
        refFormat.current = Globals.instance().getCanvasFormat(props.model.formats,props.format)
          
        let style ={position:'absolute',transform: 'translateZ(0), perspective: 1000px',willChange:'transform','cursor':'pointer'}
        if(refFormat.current.style){ style = Object.assign(style,refFormat.current.style) }

        
        setInline(style)

        let f =refFormat.current.file?refFormat.current.file:refFormat.current.image
        setImgSrc(getContentPath(f))
        
        if(refFormat.current.animation){
            if(refFormat.current.animation.typeName==='parallax'){
                props.setScrollCallback(onScroll,props.index)
            } 
            else if(refFormat.current.animation.typeName==='page'){
                props.onIntersect(onIntersect,props.index)
            }
        }
        return ()=>{}
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])


    const goToNarrativeLink = (link, module_id) => {

        // console.log("goToNarrativeLink", link)

        let moduleId = link.split("module=");
        let narrativeId = link.split("narrative=");
        if (narrativeId[1]) {
            let narrativeTarget = narrativeId[1];
            let narrativeParams = narrativeTarget.split('?');
            let narrativeIdTarget = narrativeParams[0];

            narrativeId && console.log("goToNarrativeLink", narrativeIdTarget, props, narrativeTarget);
            
            // props.setCurrentParams(null);
            // props.setCurrentModule(null);
            // props.setActivePage(null);
            // props.setActiveNarrativePageIndex(0);
            // props.setGoToNarrative(narrativeIdTarget);
          
            setTimeout(()=>{

                if (props.socketPrefs && props.socketPrefs.mode === "isBroadcast") {

                    props.socket.emit('cast', [props.socketPrefs.room, 'nav#path=' + narrativeIdTarget]);
        
                    console.log('nav#path=', narrativeIdTarget)
                }

                // ControllerServerComs.instance().sendUDPFromControllerToServer('navigate', '/narrative/' + narrativeTarget )
         
            },300)
        } else if (moduleId[1]) {
            // console.log('moduleId[1]', moduleId[1])
     
            // let moduleIdTarget = moduleId[1];
            // if (moduleParam) {
            //     moduleIdTarget = moduleParam[0];
            // }
            // console.log('moduleId', moduleIdTarget, moduleParam)
            // moduleId && console.log("goToNarrativeLink", moduleIdTarget, props);
            // let moduleIndex = props.modules.findIndex(md=>{return md.id===moduleIdTarget})
            // let m = props.modules.find(md=>{return md.id===moduleIdTarget})
            // if(m.typeName!=="controller-narrative") props.setCurrentModule(m);
            // if(m.typeName!=="controller-narrative") props.setAnimInCurrentModule(true);
            // props.setCurrentParams(link);
            // props.setActivePage(null);

            console.log('set current params' + link)
            
            setTimeout(()=>{

                // props.socket.emit('cast', [props.socketPrefs.room, 'select-module-id?module=' + moduleId[1]]);

                if (props.socketPrefs && props.socketPrefs.mode === "isBroadcast") {

                    props.socket.emit('cast', [props.socketPrefs.room, 'nav#path=' + link]);
                
                    props.socket.emit('cast', [props.socketPrefs.room, 'narrative-module-id#link=' + link]);
                    
                    console.log("broadcast button", 'narrative-module-id#path=' + link)
                    
        

                }

                // moduleId && console.log("cast", 'select-module-id?module=' + moduleId[1]);
                // ControllerServerComs.instance().sendUDPFromControllerToServer('navigate', '/narrative/' + props.model.id + link)
                // ControllerServerComs.instance().sendUDPFromControllerToServer('narrative-module-cta', moduleIndex-1);
            },300)
            // console.log("goToNarrativeLink result", moduleIndex-1, m)
            
        } else {
            console.log('no link found')
        }
   
    }


    let onClick=useCallback(()=>{
        
        console.log("onClick PNGButton", props)
        
        if(props.model.content.module){
            // let url  = props.model.content.module
            // let arrURL = url.split("?")
            // let search = ""
            // let moduleid=""
            // if(arrURL.length){
            //     let urlparams= new URLSearchParams(arrURL[1])
            //     if(urlparams.has("module"))
            //         moduleid=urlparams.get("module")
            //     search = urlparams.toString()
            // }
            let url = new URL(window.location.href)
            let urlparams= new URLSearchParams(props.model.content.module)
            let  moduleid=urlparams.get("module")

            let pathLink = props.model.content.module;
            let isExternal = pathLink.includes('http') ||  pathLink.includes('https') ||  pathLink.includes('mailto');

            console.log('Globals.instance().useHashRouter', Globals.instance().useHashRouter, isExternal)

            if((Globals.instance().useHashRouter)&&(!isExternal))
            {
                let path = url.hash.substring(1)
                let arr=path.split('?')
                let search=''
                if(path.indexOf('?')){
                    path=arr[0]
                    search=arr[1]
                    
                }
                 search=search?'&'+props.model.content.module:props.model.content.module +"&landing=false"

                console.log(search,url.hash.substring(1)+'&'+props.model.content.module)
                
                history.push({pathname:path,search:'?'+search})

                if (props.socketPrefs && props.socketPrefs.mode === "isBroadcast") {
                    goToNarrativeLink(path + search)
                }

            }else{

               
                if (isExternal) {
                    console.log('isExternal')
                    // history.push({pathname:url.pathname, search:'?'+props.model.content.module})  
                    if(Globals.instance().electron) {
                        window.api.send("toApp", {"event":"external-link","link":props.model.content.module});
                        console.log('electron', props.model.content.module)
                    } else if(Globals.instance().ios) {
                        console.log('ios', props.model.content.module)
                        window.webkit.messageHandlers.notification.postMessage({ Object: 'external-link', 'link': props.model.content.module})
                        
                    } else {
                        window.open(props.model.content.module, "_blank");
                    }


                } else {
                    console.log('history push props', props.model.content.module, props, url.pathname)
                    let search = props.model.content.module + "&back=" + props.pageId;
                    if (url.pathname.indexOf('C:/') > -1) {
                        url.pathname = url.pathname.replace('C:/', '');
                    }
                    history.push({pathname:url.pathname, search:'?'+ search})      
                    
                    if (props.socketPrefs && props.socketPrefs.mode === "isBroadcast") {
                        goToNarrativeLink(url.pathname + props.model.content.module, moduleid)
                    }
                }
                
                
            }
            
            
            
            if(moduleid){

                console.log(moduleid)
                refEle.current.dispatchEvent(new CustomEvent('narrative-pop-up', { detail:{typeName:"module",module:moduleid},bubbles:true}))
            }



            return
        }
        // 
    },[refEle,props.model])
    
    function onError(evt){
        
        if(!bLoaded && imgSrc!==""){
            console.error("PNG BTN ERROR",evt)
            Globals.instance().trackError('PNG Button Component '+imgSrc+' ',false)
            onLoad() //dont hold up the page 
            if(props.renderOnly===true){
                refAnimationModel.current = getAnimationModel(refEle.current,refFormat.current.animation) 
                setFinalAnimIntersectState(refEle.current, refAnimationModel.current)
            }

        }
    }
    

    function onLoad(){
        //down res the image
        if(!bLoaded){
            props.onLoad(props.index,refEle.current,props.model)
            bLoaded=true
        }
    }
    
    return ( 
        <img className={'force3d canvasImg' + (props.renderOnly === true ? " noAnim":"")} src={imgSrc} onError={onError} onClick={onClick} alt="" onLoad={onLoad} style={inline} ref={refEle}></img>
    )

}

const PNGButton = memo( PNGComponent ,(prev,next)=>{  return prev.imgSrc === next.imgSr })
export  {PNGButton}
