import React,{useRef} from 'react'
import {useComponentDidMount} from 'system/utils/lifecyclehooks'

const  NarrativeIndex =(props)=>{

    const refContainer =useRef()

    function onClickLink(evt){
        let link= evt.currentTarget.getAttribute('link')
        refContainer.current.dispatchEvent(new CustomEvent('scrollto',{detail:{link},bubbles: true}))
    }
useComponentDidMount(()=>{
    props.onLoad(props.index)
})
    
    
    return (
        <div className={`fullscreen`} ref={refContainer}>
             <h1>Label</h1>
            <ul>
           {
             props.model.links && props.model.links.map((val,index)=>{
                return (<li key={`link${index}`} link={val.link} onClick={onClickLink}>{val.title} </li>)
                })
            } 
            </ul>    
        </div>
    )

}

export {NarrativeIndex}