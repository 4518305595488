
import React from 'react'
import './LoaderWheel.css'
import {ReactComponent as Loader} from 'assets/icons/loader.svg'

const LoaderWheel = (props)=>{

    return (
        <div className="loader-container" >
           
            <Loader></Loader>
           { 
           props.status && <div className={'loader-status'}>{props.status?props.status:'&nbsp;'}</div>
           }
        </div>
    )
}

export default LoaderWheel

